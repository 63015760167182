import { UPDropdownItem } from 'components/dropdown';
import { IContractToEdit } from 'modules/contracts/types';
import { useForm } from 'react-hook-form';
import { IContractFormFields } from 'types/contract/IContractFormFields';

const ContractFormInitialState: IContractFormFields = {
  id: 0,
  employeeIds: [],
  workerclass: '',
  functionId: '',
  costCenterId: 0,
  locationId: '',
  wage: '',
  workingLocation: '',
};

const useContractForm = (
  functions: UPDropdownItem[],
  costCenters: UPDropdownItem[],
  contractToEdit?: IContractToEdit,
) => {
  const { setValue, clearErrors, ...formMethods } = useForm<IContractFormFields>({
    defaultValues: contractToEdit
      ? {
          ...ContractFormInitialState,
          id: contractToEdit.contractId,
          workerclass: contractToEdit?.contractType,
          employeeIds: contractToEdit?.employee?.id ? [contractToEdit.employee.id] : [],
          functionId: contractToEdit.functionId.toString(),
          wage: contractToEdit?.wage?.toString() || '',
          locationId: contractToEdit.locationId.toString(),
          costCenterId: contractToEdit.costCenterId,
          workingLocation: contractToEdit.workingLocation,
        }
      : {
          ...ContractFormInitialState,
          costCenterId: costCenters.length === 1 ? costCenters[0].value : undefined,
          functionId: functions.length === 1 ? functions[0].value : undefined,
        },
  });

  return {
    setValue,
    clearErrors,
    ...formMethods,
  };
};

export default useContractForm;
