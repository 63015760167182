import { UPButtonStyled } from './styles';
import { UPButtonProps } from './types';
import './styles.scss';

const UPButton = (props: UPButtonProps): JSX.Element => {
  const { text, onClick, disabled, outlined, className, ref, gtmEvent } = props;

  return (
    <UPButtonStyled
      variant={outlined ? 'outlined' : 'contained'}
      onClick={onClick}
      disabled={disabled}
      className={className}
      ref={ref}
      data-gtm-event={gtmEvent}
    >
      {text}
    </UPButtonStyled>
  );
};

UPButton.defaultProps = {
  disabled: false,
  outlined: false,
  className: '',
  value: '',
};

export default UPButton;
