import { Box, Checkbox, Tooltip } from '@mui/material';
import { getDocumentCategoriesRequest } from 'api/requests/documents-requests';
import { UPButtonStyled } from 'components/button/styles';
import UPDateRangePicker from 'components/datepicker/UPDateRangePicker';
import { UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import { UPInputLabel } from 'components/input/styles';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getAllUserCompaniesAsDropdownItemsAction,
  getCompaniesAsDropdownItemsAction,
} from 'store/actions/company-actions';
import {
  getDocumentsAction,
  getDocumentsPayloadAction,
  syncExpressoInvoicesAction,
} from 'store/actions/documents-action';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { companiesDropdownItemsSelector, userCompaniesDropdownItemsSelector } from 'store/selectors/company-selectors';
import { documentsSelector } from 'store/selectors/documents-selectors';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  COMPANY__COMPANY,
  DOCUMENTS__DOCUMENT_CATEGORY,
  DOCUMENTS__SYNC_WITH_EXPRESSO,
  DOCUMENTS__SYNC_WITH_EXPRESSO_INCLUDE_EMPLOYEE_DOCS,
  GENERAL__SELECT,
  MENU__DOCS,
  MENU__EMPLOYEES,
} from 'translations/constants';
import { treatAsUTC } from 'utils/helpers';
import { ReactComponent as AddDocumentIcon } from '../../assets/icons/AddDocumentIcon.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/Refresh.svg';
import AddNewDocumentModal from './AddNewDocumentModal';
import DocumentsGridView from './DocumentsGridView';
import { AddNewDocumentButton, FilterItem, FiltersContainer, InvoiceFilterItem } from './styles';
import { DocumentCategories } from './types';

const Documents = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const state = location.state as { category: number };

  const activeItem = useAppSelector(activeMenuItemSelector);
  const profile = useAppSelector(profileSelector);
  const isAdmin = profile.role === CompanyRole.ADMIN;
  const isEmployee = profile.role === CompanyRole.EMPLOYEE;
  const companiesDropdownItems = useAppSelector(companiesDropdownItemsSelector);
  const userCompaniesDropdownItems = useAppSelector(userCompaniesDropdownItemsSelector);
  const userCompanyId = useAppSelector(profileSelector).companyId;
  const documents = useAppSelector(documentsSelector);

  const [companyId, setCompanyId] = useState<string>(userCompanyId !== null ? userCompanyId.toString() : undefined);
  const [employeeId, setEmployeeId] = useState<string>(undefined);
  const [categorySelected, setCategorySelected] = useState<number>(null);
  const [openAddNewDocumentModal, setOpenAddNewDocumentModal] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date, Date]>([null, null]);
  const [includeEmployeeDocs, setIncludeEmployeeDocs] = useState<boolean>(false);
  const companyEmployees = useAppSelector(employeesWithWorkerclassSelector);
  const [employeeDropdownItems, setEmployeeDropdownItems] = useState<UPDropdownItem[]>([]);
  const [documentCategories, setDocumentCategories] = useState<UPDropdownItem[]>([]);
  const [displayDocumentCategories, setDisplayDocumentCategories] = useState<UPDropdownItem[]>([]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    getDocumentCategoriesRequest().then(r => {
      const uploadCategories = r.uploadCategories.map(item => {
        return {
          value: item.value,
          label: t(item.label),
        };
      });
      setDocumentCategories(uploadCategories);

      const displayCategories = r.readCategories.map(item => {
        return {
          value: item.value,
          label: t(item.label),
        };
      });
      setDisplayDocumentCategories(displayCategories);
    });
  }, [t]);

  useEffect(() => {
    if (!isEmployee && companyId) dispatch(getEmployeesWithWorkerclass(toNumber(companyId)));
  }, [companyId, dispatch, isEmployee]);

  useEffect(() => {
    if (companyEmployees) {
      const items: UPDropdownItem[] = [];
      companyEmployees.forEach(employee => {
        items.push({
          value: employee.id,
          label: `${employee.firstName} ${employee.lastName}`,
        });
      });
      setEmployeeDropdownItems(items);
    }
  }, [companyEmployees]);

  const onDownloadInvoices = () => {
    dispatch(
      syncExpressoInvoicesAction({
        companyId: toNumber(companyId),
        startDate: startDate ? treatAsUTC(startDate) : startDate,
        endDate: endDate ? treatAsUTC(endDate) : endDate,
        addEmployeeDocs: includeEmployeeDocs,
      }),
    ).then(() => {
      dispatch(
        getDocumentsAction({
          companyId: toNumber(companyId),
          category: categorySelected,
          employeeId: toNumber(employeeId),
          startDate: startDate ? treatAsUTC(startDate) : startDate,
          endDate: endDate ? treatAsUTC(endDate) : endDate,
        }),
      );
    });
  };

  const handleChange = () => {
    setIncludeEmployeeDocs(!includeEmployeeDocs);
  };

  useEffect(() => {
    dispatch(getDocumentsPayloadAction([]));
  }, [dispatch]);

  useEffect(() => {
    if (location.state) {
      setCategorySelected(state.category);
    }
  }, [location, state]);

  useEffect(() => {
    if (activeItem !== t(MENU__DOCS)) {
      dispatch(setMenuStatusAction(t(MENU__DOCS)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getCompaniesAsDropdownItemsAction());
    }

    if (isEmployee) {
      dispatch(getAllUserCompaniesAsDropdownItemsAction(profile.id));
    }
  }, [isAdmin, isEmployee, profile, dispatch]);

  useEffect(() => {
    if (companyId) {
      if ((!startDate && endDate) || (startDate && !endDate)) {
        return;
      }
      dispatch(
        getDocumentsAction({
          companyId: toNumber(companyId),
          category: categorySelected,
          employeeId: toNumber(employeeId),
          startDate: startDate ? treatAsUTC(startDate) : startDate,
          endDate: endDate ? treatAsUTC(endDate) : endDate,
        }),
      );
    }
  }, [categorySelected, companyId, dispatch, startDate, endDate, employeeId]);

  return (
    <Box>
      <FiltersContainer>
        <AddNewDocumentButton
          text={
            (
              <span style={{ marginTop: '2.5px', height: '25px' }}>
                <span style={{ verticalAlign: 'top' }}> + </span>
                <AddDocumentIcon />
              </span>
            ) as JSX.Element
          }
          onClick={() => setOpenAddNewDocumentModal(true)}
          disabled={isAdmin && !companyId}
        />
        {isAdmin && (
          <FilterItem>
            <UPDropdownWithSearch
              value={companyId}
              onChange={selectedItem => {
                setCompanyId(selectedItem);
                if (!selectedItem) {
                  dispatch(getDocumentsPayloadAction([]));
                }
              }}
              placeholder={t(GENERAL__SELECT)}
              items={companiesDropdownItems}
              label={t(COMPANY__COMPANY)}
              disableClearable={false}
            />
          </FilterItem>
        )}

        {isEmployee && (
          <FilterItem>
            <UPDropdownWithSearch
              value={companyId}
              onChange={selectedItem => setCompanyId(selectedItem)}
              placeholder={t(GENERAL__SELECT)}
              items={userCompaniesDropdownItems}
              label={t(COMPANY__COMPANY)}
              disableClearable={false}
            />
          </FilterItem>
        )}

        <FilterItem>
          <UPDropdownWithSearch
            value={categorySelected}
            hasDefaultValue
            onChange={selectedItem => setCategorySelected(toNumber(selectedItem))}
            placeholder={t(GENERAL__SELECT)}
            items={displayDocumentCategories}
            label={t(DOCUMENTS__DOCUMENT_CATEGORY)}
            disableClearable={false}
          />
        </FilterItem>
        {!isEmployee && (
          <FilterItem>
            <UPDropdownWithSearch
              value={employeeId}
              onChange={selectedItem => setEmployeeId(selectedItem)}
              placeholder={t(GENERAL__SELECT)}
              items={employeeDropdownItems}
              label={t(MENU__EMPLOYEES)}
              disableClearable={false}
            />
          </FilterItem>
        )}

        <FilterItem>
          <UPDateRangePicker
            label="Date Range Picker"
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
          />
        </FilterItem>
        {categorySelected === 2 && isAdmin && (
          <InvoiceFilterItem>
            <UPInputLabel>{t(DOCUMENTS__SYNC_WITH_EXPRESSO_INCLUDE_EMPLOYEE_DOCS)}</UPInputLabel>
            <Checkbox
              onChange={handleChange}
              sx={{ padding: '0px', width: '16px', height: '16px', position: 'relative', top: '8px', left: '8px' }}
            />
            <Tooltip title={t(DOCUMENTS__SYNC_WITH_EXPRESSO)}>
              <UPButtonStyled
                onClick={() => onDownloadInvoices()}
                sx={{ backgroundColor: '#008C15', cursor: 'pointer', left: '75px', top: '8px' }}
                disabled={isAdmin && companyId === undefined}
              >
                <RefreshIcon />
              </UPButtonStyled>
            </Tooltip>
          </InvoiceFilterItem>
        )}
      </FiltersContainer>
      {openAddNewDocumentModal && (
        <AddNewDocumentModal
          onClose={() => setOpenAddNewDocumentModal(false)}
          companyId={toNumber(companyId)}
          categories={documentCategories}
          category={categorySelected}
        />
      )}
      {documents.length > 0 && (
        <DocumentsGridView
          category={categorySelected}
          startDate={startDate}
          endDate={endDate}
          modalIsOpen={openAddNewDocumentModal}
        />
      )}
    </Box>
  );
};

export default Documents;
