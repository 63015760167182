import { createReducer } from '@reduxjs/toolkit';
import {
  addOneFunctionAction,
  removeAllFunctionsAction,
  removeManyFunctionsAction,
  setFunctionsAction,
  setFunctionsErrorAction,
  setFunctionsRequestsLoadingAction,
  updateOneFunctionAction,
  setFunctionsDropdownItemsAction,
  setFunctionsDropdownItemsErrorAction,
  setDeleteWarning,
} from 'store/actions/company-functions-actions';
import functionsAdapter from 'store/adapters/company-functions-adapter';
import IPayrollFunctionsState from 'types/company/payroll/IPayrollFunctionsState';

const initialState: IPayrollFunctionsState = {
  functions: [],
  loading: false,
  functionsError: false,
  functionsDropdownItems: [],
  functionDropdownItemsError: false,
  functionsDeleteWarning: false,
};

const functionsReducer = createReducer(
  functionsAdapter.getInitialState<IPayrollFunctionsState>(initialState),
  builder => {
    return builder
      .addCase(setFunctionsAction, functionsAdapter.setAll)
      .addCase(updateOneFunctionAction, functionsAdapter.updateOne)
      .addCase(addOneFunctionAction, functionsAdapter.addOne)
      .addCase(removeManyFunctionsAction, functionsAdapter.removeMany)
      .addCase(removeAllFunctionsAction, functionsAdapter.removeAll)
      .addCase(setFunctionsRequestsLoadingAction, (state, action) => ({
        ...state,
        loading: action.payload,
      }))
      .addCase(setFunctionsErrorAction, (state, action) => ({
        ...state,
        functionsError: action.payload,
      }))
      .addCase(setFunctionsDropdownItemsAction, (state, action) => ({
        ...state,
        functionsDropdownItems: action.payload,
      }))
      .addCase(setFunctionsDropdownItemsErrorAction, (state, action) => ({
        ...state,
        functionDropdownItemsError: action.payload,
      }))
      .addCase(setDeleteWarning, (state, action) => ({
        ...state,
        functionsDeleteWarning: action.payload,
      }));
  },
);

export default functionsReducer;
