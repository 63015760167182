import React, { useMemo } from 'react';
import { IContractToPaste } from 'modules/contracts/types';
import AddContractModal from './AddContractModal';
import { generateDatesForPasting } from './helpers';
import { EditBeforePasteWrapperProps } from '../types';

const EditBeforePasteWrapper = (props: EditBeforePasteWrapperProps) => {
  const { onClose, onSuccess, companyId, week, weekDate, contractToCopy, defaultBreakTime } = props;

  const contractToPaste: IContractToPaste = useMemo(
    () => ({
      employeeId: contractToCopy.employee.id,
      workerclass: contractToCopy.contractType,
      locationId: contractToCopy.locationId,
      costCenterId: contractToCopy.costCenterId,
      functionId: contractToCopy.functionId,
      workingLocation: contractToCopy.workingLocation,
      dateRows: generateDatesForPasting(contractToCopy.dates),
    }),
    [contractToCopy],
  );

  return (
    <AddContractModal
      onClose={onClose}
      onSuccess={onSuccess}
      companyId={companyId}
      week={week}
      weekDate={weekDate}
      contractToPaste={contractToPaste}
      defaultBreakTime={defaultBreakTime}
    />
  );
};

export default EditBeforePasteWrapper;
