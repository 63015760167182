import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { deleteLocationsAction, getLocationsAction } from 'store/actions/company-locations-actions';
import { locationsSelector } from 'store/selectors/company-locations-selectors';
import {
  GENERAL__DELETE_MESSAGE,
  COMPANY_INFORMATION__BUS_NUMBER,
  COMPANY_INFORMATION__CITY,
  COMPANY_INFORMATION__POSTAL_CODE,
  COMPANY_INFORMATION__STREET_NAME,
  COMPANY_INFORMATION__STREET_NUMBER,
  COMPANY_LOCATIONS__ADD_NEW_LOCATION,
  COMPANY_LOCATIONS__EDIT_LOCATION,
  GENERAL__DELETE_SELECTED,
  GENERAL__EDIT,
  GENERAL__NAME,
} from 'translations/constants';
import ILocation from 'types/company/locations/ILocation';
import { PayrollAddNewItemButton } from '../payroll/styles';
import { SectionContainer } from '../styles';
import EditLocations from './EditLocations';

const Locations = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const locations = useAppSelector(locationsSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [openAddEditLocation, setOpenAddEditLocation] = useState<boolean>(false);
  const [isAddNewButtonClicked, setIsAddNewButtonClicked] = useState<boolean>(false);
  const [rows, setRows] = useState<ILocation[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getLocationsAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (locations) {
      setRows(locations);
    }
  }, [locations]);

  const onOpenAddEditModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenAddEditLocation(true);
  }, []);

  const onCloseAddEditModal = useCallback(() => {
    setOpenAddEditLocation(false);
    setIsAddNewButtonClicked(false);
  }, []);

  const getLocationOnEdit = useCallback(() => {
    return locations.find(location => location.id === rowOnHover);
  }, [locations, rowOnHover]);

  const onOpenDeleteModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeleteModal(true);
  }, []);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'name', headerName: t(GENERAL__NAME), width: 200 },
    { field: 'streetName', headerName: t(COMPANY_INFORMATION__STREET_NAME), width: 200 },
    { field: 'streetNumber', headerName: t(COMPANY_INFORMATION__STREET_NUMBER), width: 150 },
    { field: 'busNumber', headerName: t(COMPANY_INFORMATION__BUS_NUMBER), width: 170 },
    { field: 'city', headerName: t(COMPANY_INFORMATION__CITY), width: 200 },
    { field: 'postalCode', headerName: t(COMPANY_INFORMATION__POSTAL_CODE), width: 150 },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return <UPButton onClick={onOpenAddEditModal} text={t(GENERAL__EDIT)} outlined />;
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(COMPANY_LOCATIONS__ADD_NEW_LOCATION)}`}
          onClick={event => {
            setRowOnHover(null);
            setIsAddNewButtonClicked(true);
            onOpenAddEditModal(event);
          }}
        />
        <Box sx={{ marginLeft: '15px' }}>
          <PayrollAddNewItemButton
            text={t(GENERAL__DELETE_SELECTED)}
            onClick={onOpenDeleteModal}
            outlined
            disabled={selectedRows.length <= 0}
          />
        </Box>
      </Box>
      <Box sx={{ height: '400px', width: '100%' }}>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          onSelectionModelChange={onSelectionChange}
        />

        {openAddEditLocation && (
          <EditLocations
            open={openAddEditLocation}
            onClose={onCloseAddEditModal}
            location={getLocationOnEdit()}
            title={isAddNewButtonClicked ? t(COMPANY_LOCATIONS__ADD_NEW_LOCATION) : t(COMPANY_LOCATIONS__EDIT_LOCATION)}
            isEdit={!isAddNewButtonClicked}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            content={t(GENERAL__DELETE_MESSAGE)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => {
              dispatch(deleteLocationsAction({ companyId, locationsIds: selectedRows }));
            }}
          />
        )}
      </Box>
    </SectionContainer>
  );
};

export default Locations;
