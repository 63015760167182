/* eslint-disable react/no-array-index-key */
import { Box, Popover } from '@mui/material';
import UPButton from 'components/button';
import { UPDropdown } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import { GENERAL__CLEAR_ALL, GENERAL__CONFIRM, GENERAL__SELECT } from 'translations/constants';
import { PageFilter, FiltersMenuProps } from '../../modules/contracts/types';
import { FiltersMenuButtonsContainer, FiltersMenuContainer } from './styles';

const FiltersMenu = (props: FiltersMenuProps): JSX.Element => {
  const { anchorEl, open, onClose, onApplyFilters, onClearFilters, filters } = props;
  const [t] = useTranslation();

  const onChangeDropdown = (event: React.ChangeEvent<{ value: string }>, filter: PageFilter) => {
    filter.setValue(event.target.value);
  };

  const onChangeDropdownWithSearch = (value: string, filter: PageFilter) => {
    filter.setValue(value || null);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FiltersMenuContainer>
        {filters.map((filter, index) => (
          <Box key={index} sx={{ width: 1, height: '80px' }}>
            <filter.component
              value={filter.value}
              onChange={
                filter.component === UPDropdown
                  ? event => onChangeDropdown(event, filter)
                  : value => onChangeDropdownWithSearch(value, filter)
              }
              placeholder={t(GENERAL__SELECT)}
              items={filter.items}
              label={filter.label}
              disableClearable={false}
              isFilter={filter.canBeEmpty}
              gtmEvent={filter.gtmEvent}
            />
          </Box>
        ))}
        <FiltersMenuButtonsContainer>
          <UPButton text={t(GENERAL__CONFIRM)} onClick={onApplyFilters} />
          <UPButton text={t(GENERAL__CLEAR_ALL)} onClick={onClearFilters} outlined />
        </FiltersMenuButtonsContainer>
      </FiltersMenuContainer>
    </Popover>
  );
};

export default FiltersMenu;
