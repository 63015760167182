import { useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import UPInput from 'components/input';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store';
import { countriesNames } from 'store/selectors/countries-selectors';
import {
  EMPLOYEES__CONTACT_INFO,
  EMPLOYEES__MOBILE_NUMBER,
  GENERAL__EMAIL,
  EMPLOYEES__ICE_MOBILE_NUMBER,
  COMPANY_INFORMATION__CITY,
  EMPLOYEES__BUS_NUMBER,
  COMPANY_INFORMATION__POSTAL_CODE,
  EMPLOYEES__STREET_NAME,
  EMPLOYEES__STREET_NUMBER,
  GENERAL__SELECT,
  EMPLOYEES__COUNTRY,
  COMPANY_INFORMATION__STREET_NAME,
  COMPANY_INFORMATION__STREET_NUMBER,
  COMPANY_INFORMATION__BUS_NUMBER,
  EMPLOYEES__STAY_ADDRESS,
  EMPLOYEES__STAY_CITY,
  EMPLOYEES__STAY_STREET,
  EMPLOYEES__STAY_NUMBER,
  EMPLOYEES__STAY_BUS,
  EMPLOYEES__POSTAL_CODE,
  EMPLOYEES__STAY_COUNTRY,
} from 'translations/constants';
import { allowOnlyPositiveNumber, getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import { profileSelector } from 'store/selectors/profile-selectors';
import Lang from 'types/Lang';
import { getCountriesNamesAction } from 'store/actions/countries-actions';
import { countriesWithBelgiumFirst } from 'modules/companies/helpers';
import { onboardingSelector } from 'store/selectors/employee-selector';
import UPCheckbox from 'components/app-container/checkbox-with-label';
import {
  ContactInfoSmallContainer,
  CountryBox,
  EmailBox,
  EmployeeModalInfoContainer,
  EmployeeInfoSmallBox,
  PostalCodeBox,
  RowContainer,
  SectionTitle,
  SmallBoxWithNoMargin,
  StayAddressCheckboxStyled,
} from './styles';
import { IContactInfoTab } from './types';

const ContactInfoTab = (props: IContactInfoTab) => {
  const { control, errors, employeeId, watch, clearErrors } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const countriesNamesOptions = useAppSelector(countriesNames);
  const userLanguageId = useAppSelector(profileSelector)?.languageId;
  const employeeOnboarding = useAppSelector(onboardingSelector);

  const profile = useAppSelector(profileSelector);
  const isManagerEdit =
    ['Company manager', 'Store/location manager', 'HR manager'].includes(profile?.role) && employeeId > 0;

  const [countries, setCountries] = useState<UPDropdownItem[]>([]);

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const watchHasStayAddress = watch('hasStayAddress');

  useEffect(() => {
    if (userLanguageId) {
      const userLanguage = languageOptions.find(l => l.value === userLanguageId).label;
      dispatch(getCountriesNamesAction(userLanguage));
    } else {
      dispatch(getCountriesNamesAction('en'));
    }
  }, [dispatch, languageOptions, userLanguageId]);

  useEffect(() => {
    if (countriesNamesOptions) {
      const mappedLocationNames = countriesNamesOptions.map(item => ({ value: item.value, label: item.label }));
      const auxCountries = countriesWithBelgiumFirst(mappedLocationNames);
      setCountries(auxCountries);
    }
  }, [countriesNamesOptions]);

  return (
    <Box>
      <RowContainer>
        <SectionTitle>{t(EMPLOYEES__CONTACT_INFO)}</SectionTitle>
      </RowContainer>
      <EmployeeModalInfoContainer>
        <EmployeeInfoSmallBox>
          <Controller
            name="mobileNumber"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__MOBILE_NUMBER)}
                placeholder={t(EMPLOYEES__MOBILE_NUMBER)}
                required
                error={!!errors.mobileNumber}
                helperText={getInputErrorText(errors, 'mobileNumber', undefined, 100)}
                inputClassName="white-background"
                disabled={isManagerEdit}
              />
            )}
          />
        </EmployeeInfoSmallBox>
        <EmailBox>
          <Controller
            name="emailAddress"
            control={control}
            rules={{ required: true, maxLength: 50, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={value => {
                  field.onChange(value);
                  clearErrors('invalidEmail');
                }}
                label={t(GENERAL__EMAIL)}
                placeholder={t(GENERAL__EMAIL)}
                required
                error={!!errors.emailAddress || !!errors.invalidEmail}
                helperText={getInputErrorText(
                  errors,
                  'emailAddress',
                  errors.invalidEmail ? errors.invalidEmail.type : undefined,
                  100,
                )}
                inputClassName="white-background"
                autoComplete="off"
                disabled={isManagerEdit}
              />
            )}
          />
        </EmailBox>
        <SmallBoxWithNoMargin>
          <Controller
            name="iceMobileNumber"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__ICE_MOBILE_NUMBER)}
                placeholder={t(EMPLOYEES__ICE_MOBILE_NUMBER)}
                required
                error={!!errors.iceMobileNumber}
                helperText={getInputErrorText(errors, 'iceMobileNumber', undefined, 100)}
                inputClassName="white-background"
                disabled={isManagerEdit}
              />
            )}
          />
        </SmallBoxWithNoMargin>
      </EmployeeModalInfoContainer>
      <EmployeeModalInfoContainer sx={{ flexWrap: 'wrap' }}>
        <EmployeeInfoSmallBox>
          <Controller
            name="address.streetName"
            control={control}
            rules={{ required: true, maxLength: 50 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__STREET_NAME)}
                placeholder={t(EMPLOYEES__STREET_NAME)}
                required
                error={!!errors.address?.streetName}
                helperText={getInputErrorText(errors, 'address', 'streetName', 50)}
                inputClassName="white-background"
                disabled={isManagerEdit}
              />
            )}
          />
        </EmployeeInfoSmallBox>
        <ContactInfoSmallContainer>
          <Box sx={{ marginRight: '16px' }} width={1 / 2}>
            <Controller
              name="address.streetNumber"
              control={control}
              rules={{ required: true, maxLength: 50, min: 0 }}
              render={({ field: { value, onChange } }) => (
                <UPInput
                  value={value}
                  onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                  label={t(EMPLOYEES__STREET_NUMBER)}
                  placeholder={t(EMPLOYEES__STREET_NUMBER)}
                  required
                  error={!!errors.address?.streetNumber}
                  helperText={getInputErrorText(errors, 'address', 'streetNumber', 50)}
                  inputClassName="white-background"
                  disabled={isManagerEdit}
                />
              )}
            />
          </Box>
          <Box width={1 / 2}>
            <Controller
              name="address.busNumber"
              control={control}
              rules={{ maxLength: 4 }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__BUS_NUMBER)}
                  placeholder={t(EMPLOYEES__BUS_NUMBER)}
                  error={!!errors.address?.busNumber}
                  helperText={getInputErrorText(errors, 'address', 'busNumber', 4)}
                  inputClassName="white-background"
                  disabled={isManagerEdit}
                />
              )}
            />
          </Box>
        </ContactInfoSmallContainer>
        <EmployeeInfoSmallBox>
          <Controller
            name="address.city"
            control={control}
            rules={{ required: true, maxLength: 50 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(COMPANY_INFORMATION__CITY)}
                placeholder={t(COMPANY_INFORMATION__CITY)}
                required
                type="text"
                error={!!errors.address?.city}
                helperText={getInputErrorText(errors, 'address', 'city', 50)}
                inputClassName="white-background"
                disabled={isManagerEdit}
              />
            )}
          />
        </EmployeeInfoSmallBox>
        <PostalCodeBox>
          <Controller
            name="address.postalCode"
            control={control}
            rules={{ required: true, maxLength: 50 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(COMPANY_INFORMATION__POSTAL_CODE)}
                placeholder={t(COMPANY_INFORMATION__POSTAL_CODE)}
                required
                type="number"
                error={!!errors.address?.postalCode}
                helperText={getInputErrorText(errors, 'address', 'postalCode', 50)}
                inputClassName="white-background"
                disabled={isManagerEdit}
              />
            )}
          />
        </PostalCodeBox>
        <CountryBox>
          <Controller
            name="address.countryId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UPDropdown
                value={field?.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__COUNTRY)}
                placeholder={t(GENERAL__SELECT)}
                required
                error={!!errors.address?.countryId}
                helperText={getInputErrorText(errors, 'address', 'countryId')}
                className="dropdown--white-background"
                items={countries || [{ value: null, label: '' }]}
                disabled={isManagerEdit}
              />
            )}
          />
        </CountryBox>
        {!employeeOnboarding && (
          <StayAddressCheckboxStyled>
            <Controller
              name="hasStayAddress"
              control={control}
              render={({ field }) => (
                <UPCheckbox label={t(EMPLOYEES__STAY_ADDRESS)} value={field.value} handleChange={field.onChange} />
              )}
            />
          </StayAddressCheckboxStyled>
        )}
      </EmployeeModalInfoContainer>

      {watchHasStayAddress && (
        <EmployeeModalInfoContainer sx={{ flexWrap: 'wrap' }}>
          <EmployeeInfoSmallBox>
            <Controller
              name="stayAddress.streetName"
              control={control}
              rules={{ required: true, maxLength: 50 }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__STAY_STREET)}
                  placeholder={t(COMPANY_INFORMATION__STREET_NAME)}
                  required
                  error={!!errors.stayAddress?.streetName}
                  helperText={getInputErrorText(errors, 'stayAddress', 'streetName', 50)}
                  inputClassName="white-background"
                  disabled={isManagerEdit}
                />
              )}
            />
          </EmployeeInfoSmallBox>
          <ContactInfoSmallContainer>
            <Box sx={{ marginRight: '16px' }} width={1 / 2}>
              <Controller
                name="stayAddress.streetNumber"
                control={control}
                rules={{ required: true, maxLength: 50, min: 0 }}
                render={({ field: { value, onChange } }) => (
                  <UPInput
                    value={value}
                    onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                    label={t(EMPLOYEES__STAY_NUMBER)}
                    placeholder={t(COMPANY_INFORMATION__STREET_NUMBER)}
                    required
                    error={!!errors.stayAddress?.streetNumber}
                    helperText={getInputErrorText(errors, 'stayAddress', 'streetNumber', 50)}
                    inputClassName="white-background"
                    disabled={isManagerEdit}
                  />
                )}
              />
            </Box>
            <Box width={1 / 2}>
              <Controller
                name="stayAddress.busNumber"
                control={control}
                rules={{ maxLength: 4 }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t(EMPLOYEES__STAY_BUS)}
                    placeholder={t(COMPANY_INFORMATION__BUS_NUMBER)}
                    error={!!errors.stayAddress?.busNumber}
                    helperText={getInputErrorText(errors, 'stayAddress', 'busNumber', 4)}
                    inputClassName="white-background"
                    disabled={isManagerEdit}
                  />
                )}
              />
            </Box>
          </ContactInfoSmallContainer>
          <EmployeeInfoSmallBox>
            <Controller
              name="stayAddress.city"
              control={control}
              rules={{ required: true, maxLength: 50 }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__STAY_CITY)}
                  placeholder={t(COMPANY_INFORMATION__CITY)}
                  required
                  type="text"
                  error={!!errors.stayAddress?.city}
                  helperText={getInputErrorText(errors, 'stayAddress', 'city', 50)}
                  inputClassName="white-background"
                  disabled={isManagerEdit}
                />
              )}
            />
          </EmployeeInfoSmallBox>
          <PostalCodeBox>
            <Controller
              name="stayAddress.postalCode"
              control={control}
              rules={{ required: true, maxLength: 50 }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__POSTAL_CODE)}
                  placeholder={t(COMPANY_INFORMATION__POSTAL_CODE)}
                  required
                  type="number"
                  error={!!errors.stayAddress?.postalCode}
                  helperText={getInputErrorText(errors, 'stayAddress', 'postalCode', 50)}
                  inputClassName="white-background"
                  disabled={isManagerEdit}
                />
              )}
            />
          </PostalCodeBox>
          <CountryBox>
            <Controller
              name="stayAddress.countryId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDropdown
                  value={field?.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__STAY_COUNTRY)}
                  placeholder={t(GENERAL__SELECT)}
                  required
                  error={!!errors.stayAddress?.countryId}
                  helperText={getInputErrorText(errors, 'stayAddress', 'countryId')}
                  className="dropdown--white-background"
                  items={countries || [{ value: null, label: '' }]}
                  disabled={isManagerEdit}
                />
              )}
            />
          </CountryBox>
        </EmployeeModalInfoContainer>
      )}
    </Box>
  );
};

export default ContactInfoTab;
