import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import UPDatepicker from 'components/datepicker';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import CATEGORIES from 'constants/Categories';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import {
  addMinimumWageAction,
  getMinimumWagesAction,
  updateMinimumWageAction,
} from 'store/actions/minimum-wages-actions';
import { minimumWagesSelector } from 'store/selectors/minimum-wages-selectors';
import {
  GENERAL__EXT,
  GENERAL__FLEX,
  GENERAL__STUD,
  PARITAIR_COMITE__ADD_NEW_ROW,
  PARITAIR_COMITE__END_DATE,
  PARITAIR_COMITE__EXPERIENCE_OR_AGE,
  PARITAIR_COMITE__FILTER_BY_DATE,
  PARITAIR_COMITE__START_DATE,
  PARITAIR_COMITE__WORKER_CLASS,
} from 'translations/constants';
import { IMinimumWage } from 'types/minimum-wage';
import {
  ButtonContainer,
  MinimumWageGridContainer,
  TopSectionContainer,
  TopSectionContainerWithMargins,
} from '../styles';
import { MinimumWagePanelProps } from './types';

const MinimumWagePanel = (props: MinimumWagePanelProps): JSX.Element => {
  const { type } = props;

  const [t] = useTranslation();

  const WORKERCLASS_OPTIONS: UPDropdownItem[] = [
    { value: 'OTH', label: 'General (OTH)' },
    { value: 'STUD', label: `Student (${t(GENERAL__STUD)})` },
    { value: 'FLEX', label: `Flexi (${t(GENERAL__FLEX)})` },
    { value: 'EXT', label: `Extra (${t(GENERAL__EXT)})` },
  ];

  const dispatch = useDispatch();
  const { paritairComiteId } = useParams();

  const minimumWagesList = useAppSelector(minimumWagesSelector);

  const [, setRowOnHover] = useState<number>();
  const [selectedWorkerclass, setSelectedWorkerclass] = useState<string>(WORKERCLASS_OPTIONS[0].value);
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [minimumWages, setMinimumWages] = useState<IMinimumWage[]>([]);

  const categories = useMemo((): UPDropdownItem[] => CATEGORIES, []);

  const requestSearch = (searchValue: Date) => {
    setDateFilter(searchValue);
  };

  const onAddNewRow = useCallback(() => {
    setMinimumWages(prevState => [
      ...prevState,
      {
        id: Math.random(),
        paritairComiteId: toNumber(paritairComiteId),
        type,
        workerclass: selectedWorkerclass,
        yearsOfExperience: null,
        category1: null,
        category2: null,
        category3: null,
        category4: null,
        category5: null,
        category6: null,
        category7: null,
        category8: null,
        category9: null,
        category10: null,
        category11: null,
        category12: null,
        category13: null,
        category14: null,
        category15: null,
        startDate: null,
        endDate: null,
      },
    ]);
  }, [paritairComiteId, selectedWorkerclass, type]);

  const addOrUpdateEntity = useCallback(
    (entity: IMinimumWage, field: string, value: any): IMinimumWage => {
      const updatedEntity: IMinimumWage = {
        ...entity,
        [field]: value,
      };
      if (updatedEntity.id >= 0 && updatedEntity.id < 1) {
        if (updatedEntity.startDate != null && updatedEntity.endDate != null) {
          dispatch(addMinimumWageAction(updatedEntity));
        }
      } else {
        dispatch(updateMinimumWageAction(updatedEntity));
      }
      return updatedEntity;
    },
    [dispatch],
  );

  const padTo2Digits = useCallback(num => {
    return num.toString().padStart(2, '0');
  }, []);

  const formatDate = useCallback(
    date => {
      const auxDate = new Date(date);
      return [auxDate.getFullYear(), padTo2Digits(auxDate.getMonth() + 1), padTo2Digits(auxDate.getDate())].join('-');
    },
    [padTo2Digits],
  );

  const getDataGridColumns = useCallback((): GridColDef[] => {
    return categories.map(
      (_, index: number): GridColDef => ({
        field: `category${index + 1}`,
        headerName: `Cat ${index + 1}`,
        width: 90,
        editable: true,
        sortable: false,
        valueSetter: params => {
          if (Number.isNaN(toNumber(params.value)) || !params.value) {
            return params.row;
          }
          return addOrUpdateEntity(params.row as IMinimumWage, `category${index + 1}`, toNumber(params.value));
        },
        preProcessEditCellProps: (params: any) => {
          const hasError = Number.isNaN(toNumber(params.props.value));
          return { ...params.props, error: hasError };
        },
      }),
    );
  }, [categories, addOrUpdateEntity]);

  useEffect(() => {
    if (minimumWagesList) {
      setMinimumWages(minimumWagesList);
    }
  }, [minimumWagesList]);

  useEffect(() => {
    dispatch(
      getMinimumWagesAction({
        paritairComiteId: toNumber(paritairComiteId),
        type,
        workerclass: selectedWorkerclass,
        dateFilter,
      }),
    );
  }, [paritairComiteId, type, selectedWorkerclass, dateFilter, dispatch]);

  const workerclassColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'yearsOfExperience',
      headerName: t(PARITAIR_COMITE__EXPERIENCE_OR_AGE),
      width: 160,
      editable: true,
      sortable: false,
      sortingOrder: ['asc'],
      valueSetter: params => {
        if (Number.isNaN(toNumber(params.value))) {
          return params.row;
        }
        return addOrUpdateEntity(params.row as IMinimumWage, `yearsOfExperience`, toNumber(params.value));
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = Number.isNaN(toNumber(params.props.value));
        return { ...params.props, error: hasError };
      },
    },
    ...getDataGridColumns(),
    {
      field: 'startDate',
      headerName: t(PARITAIR_COMITE__START_DATE),
      width: 150,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Date>) => {
        if (params.value !== null) {
          const dateValue = new Date(params.value);
          return (
            <Box>
              {dateValue.getDate()}-{dateValue.getMonth() + 1}-{dateValue.getFullYear()}
            </Box>
          );
        }
        return <Box />;
      },
      type: 'date',
      valueSetter: params => {
        const hasError = params.row.endDate !== null && params.value > new Date(params.row.endDate);
        if (!hasError) {
          return addOrUpdateEntity(params.row as IMinimumWage, `startDate`, formatDate(params.value as Date));
        }
        return params.row;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.row.endDate !== null && params.props.value > new Date(params.row.endDate);
        return { ...params.props, error: hasError };
      },
    },
    {
      field: 'endDate',
      headerName: t(PARITAIR_COMITE__END_DATE),
      width: 150,
      editable: true,
      sortable: false,
      type: 'date',
      renderCell: (params: GridRenderCellParams<Date>) => {
        if (params.value !== null) {
          const dateValue = new Date(params.value);
          return (
            <Box>
              {dateValue.getDate()}-{dateValue.getMonth() + 1}-{dateValue.getFullYear()}
            </Box>
          );
        }
        return <Box />;
      },
      valueSetter: params => {
        const hasError = params.row.startDate !== null && params.value < new Date(params.row.startDate);
        if (!hasError) {
          return addOrUpdateEntity(params.row as IMinimumWage, `endDate`, formatDate(params.value as Date));
        }
        return params.row;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.row.startDate !== null && params.props.value < new Date(params.row.startDate);
        return { ...params.props, error: hasError };
      },
    },
  ];

  return (
    <Box>
      <TopSectionContainerWithMargins>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px' }}>
          <UPDropdown
            items={WORKERCLASS_OPTIONS}
            onChange={event => setSelectedWorkerclass(event.target.value)}
            value={selectedWorkerclass}
            label={t(PARITAIR_COMITE__WORKER_CLASS)}
          />
        </Box>
        {selectedWorkerclass !== '' && (
          <TopSectionContainer sx={{ marginTop: '16px' }}>
            <ButtonContainer>
              <UPButton text={`+ ${t(PARITAIR_COMITE__ADD_NEW_ROW)}`} onClick={onAddNewRow} />
            </ButtonContainer>
            <UPDatepicker
              label={t(PARITAIR_COMITE__FILTER_BY_DATE)}
              value={dateFilter}
              onChange={event => requestSearch(event)}
            />
          </TopSectionContainer>
        )}
      </TopSectionContainerWithMargins>
      <MinimumWageGridContainer>
        <UPDataGrid
          rows={minimumWages}
          columns={workerclassColumns}
          setRowOnHover={setRowOnHover}
          withCheckboxes={false}
        />
      </MinimumWageGridContainer>
    </Box>
  );
};

export default MinimumWagePanel;
