import { CompanyRole } from 'constants/CompanyRole';

export const isDateInFuture = (date: string, startHour: string): boolean => {
  return new Date(`${date.split('T')[0]} ${startHour}`) > new Date();
};

export const isDisabledForModification = (role: CompanyRole, isWeekly: boolean, isFuture: boolean) => {
  return role !== CompanyRole.ADMIN && role !== CompanyRole.EMPLOYEE && isWeekly && !isFuture;
};

export const generateCardFunction = (functionName: string) => {
  const auxArray = functionName.split('');
  if (auxArray.length > 13) {
    auxArray.splice(11, auxArray.length);
    auxArray.push('.', '.', '.');
  }

  const auxFinal = auxArray.join('');
  return auxFinal;
};
