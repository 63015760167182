import { styled } from '@mui/material';

export const DropdownPlaceholder = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '14px',
}));

const ITEM_HEIGHT = 49;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
