import { Backdrop, Box } from '@mui/material';
import UPButton from 'components/button';
import { ModalContainer } from 'components/delete-modal/styles';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPInput from 'components/input';
import { CoefficientsPerWorkerClass } from 'constants/CoefficientsPerWorkerclass';
import { toNumber } from 'lodash';
import { ModalFooter, ModalTitle } from 'modules/employees/add-edit-employee-modal/styles';
import { UPDropdownStyled } from 'modules/employees/styles';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getCostCentersAction } from 'store/actions/company-cost-centers-actions';
import { getFunctionsAsDropdownItemsAction } from 'store/actions/company-functions-actions';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import { getVendorsAction } from 'store/actions/company-vendors-actions';
import { validateAndSaveContractSlotHoursAction } from 'store/actions/contract-actions';
import { costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import { functionsDropdownItemsSelector } from 'store/selectors/company-functions-selectors';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { vendorsSelector } from 'store/selectors/company-vendors-selectors';
import theme from 'theme';
import {
  COMPANY_PAYROLL__COST_CENTERS,
  CONTRACTS__CANCEL,
  CONTRACTS__COMPANY_LOCATION,
  CONTRACTS__FUNCTION,
  CONTRACTS__NEW_CONTRACT_SLOT,
  CONTRACTS__VENDOR,
  GENERAL__CREATE,
  GENERAL__QUANTITY,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  HEADER__WEEK,
} from 'translations/constants';
import { ContractSlotFieldNames, IAddContractSlotFields } from 'types/contract/IAddContractSlotFields';
import ISaveContract from 'types/contract/ISaveContract';
import IWeekDays from 'types/contract/IWeekDays';
import IWeekDaysRow from 'types/contract/IWeekDaysRow';
import { getInputErrorText, getUpDropdownItemsFromEnum, getWeekDaysAbbreviations } from 'utils/helpers';
import { WorkerclassInputStyled } from './styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import AddEditDayPickerRow from '../AddEditDayPickerRow';
import { getWeekDaysByWeekNumber } from '../helpers';
import { ContractDateContainer, ModalCloseIconStyled, ModalTitleContainer } from '../styles';
import { AddContractSlotModalProps } from '../types';
import {
  ModalHeaderContract,
  ModalWrapperContract,
  NameInputStyled,
  ModalContentContainer,
  RowContainerForNameAndContractType,
} from './add/styles';

const ModalDefaultValues: IAddContractSlotFields = {
  id: 0,
  companyId: '',
  vendorId: '',
  functionId: '',
  contractType: '',
  locationId: '',
  costCenterId: '',
};

const AddContractSlotModal = (props: AddContractSlotModalProps) => {
  const { onClose, week, weekDate, companyId, defaultBreakTime } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const vendors = useAppSelector(vendorsSelector);
  const functionsDropdownItems = useAppSelector(functionsDropdownItemsSelector);
  const companyCostCenters = useAppSelector(costCentersSelector);
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const locations = useMemo(() => locationsDropdownItems, [locationsDropdownItems]);

  const weekDaysNames = getWeekDaysAbbreviations();

  const weekDays = getWeekDaysByWeekNumber(week, weekDaysNames, weekDate);
  const generateDate = (): IWeekDaysRow[] => [
    {
      id: 0,
      days: weekDays,
      hours: {
        startWork: '',
        startBreak: '',
        endBreak: '',
        endWork: '',
      },
    },
  ];

  const datesErrorsObject: any = useMemo(() => {
    return {
      0: {
        found: false,
        foundHourInPresent: false,
      },
    };
  }, []);

  const [weekRowRequiredError, setWeekRowRequiredError] = useState<boolean>(false);
  const [currentStartWorkError, setCurrentStartWorkError] = useState<boolean>(false);
  const [currentEndWorkError, setCurrentEndWorkError] = useState<boolean>(false);
  const [contractSlotDate, setContractSlotDate] = useState<IWeekDaysRow[]>(generateDate());
  const [formatError, setFormatError] = useState<number>(-1);
  const [quantity, setQuantity] = useState<number>(1);

  const workerclassList = useMemo(() => getUpDropdownItemsFromEnum(CoefficientsPerWorkerClass), []);

  const functions = useMemo(() => functionsDropdownItems, [functionsDropdownItems]);

  const vendorItems = useMemo((): UPDropdownItem[] => {
    return vendors.map(
      (vendor): UPDropdownItem => ({
        value: vendor.id,
        label: vendor.name,
      }),
    );
  }, [vendors]);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IAddContractSlotFields>({
    defaultValues: ModalDefaultValues,
  });

  const watchLocationId: string = watch<ContractSlotFieldNames>(ContractSlotFieldNames.LOCATION_ID);

  const costCentersItems = useMemo((): UPDropdownItem[] => {
    let items: UPDropdownItem[] = [];
    if (!watchLocationId) {
      items = companyCostCenters.map(
        (costCenter): UPDropdownItem => ({
          value: costCenter.id,
          label: costCenter.name,
        }),
      );
    } else {
      const filteredCostCenters = companyCostCenters.filter(
        costCenter => costCenter.locationId === watchLocationId || costCenter.locationId == null,
      );
      items = filteredCostCenters.map(
        (costCenter): UPDropdownItem => ({
          value: costCenter.id,
          label: costCenter.name,
        }),
      );
    }
    return items;
  }, [companyCostCenters, watchLocationId]);

  const onChangeHoursEdit = useCallback(
    (value: string, type: string) => {
      const newDate = contractSlotDate.map((r: IWeekDaysRow) => {
        return {
          ...r,
          hours: {
            ...r.hours,
            [type]: value,
          },
        };
      });

      setContractSlotDate(newDate);
    },
    [contractSlotDate],
  );

  const checkIfThereIsARowDateInPast = useCallback((date: IWeekDays) => {
    const currentDate = new Date(Date.now());
    const auxErr = {
      found: false,
      foundDateInPresent: false,
    };
    let itemDate;
    if (date.checked) {
      itemDate = new Date(date.nativeDate);
      if (itemDate.getMonth() < currentDate.getMonth() && itemDate.getFullYear() === currentDate.getFullYear()) {
        auxErr.found = true;
      }
      if (itemDate.getMonth() === currentDate.getMonth() && itemDate.getDate() < currentDate.getDate()) {
        auxErr.found = true;
      }
      if (itemDate.getMonth() === currentDate.getMonth() && itemDate.getDate() === currentDate.getDate()) {
        auxErr.foundDateInPresent = true;
      }
    } else {
      auxErr.found = false;
    }
    return auxErr;
  }, []);

  const saveContractSlot: SubmitHandler<IAddContractSlotFields> = useCallback(() => {
    const values = getValues();
    const saveContractSlotData: ISaveContract = {
      contract: {
        ...values,
        employeeId: '0',
      },
      companyId: +companyId,
      weekDaysRows: contractSlotDate,
      week,
      year: toNumber(moment(weekDate).format('Y')),
      wage: '0',
      workingLocation: '',
    };

    (async () => {
      const response = await dispatch(
        validateAndSaveContractSlotHoursAction({ saveContractSlot: saveContractSlotData, quantity }),
      );
      if (response.payload) {
        onClose();
      }
    })();
  }, [getValues, companyId, contractSlotDate, week, weekDate, dispatch, quantity, onClose]);

  const onSubmit = useCallback(() => {
    const validTimeSpanRegex = /^(?:\d{2}:\d{2})?$/;
    let weekRowRequiredErrorAux = false;
    let canSave = true;
    const date = contractSlotDate[0];
    if (date.days.find(day => day.checked) === undefined) {
      weekRowRequiredErrorAux = true;
      canSave = false;
    }

    if (date.hours.startWork === '') {
      setCurrentStartWorkError(true);
      canSave = false;
    }

    if (date.hours.endWork === '') {
      setCurrentEndWorkError(true);
      canSave = false;
    }

    if (date.hours.startWork !== '' && !validTimeSpanRegex.test(date.hours.startWork)) {
      setFormatError(0);
      canSave = false;
    }

    if (date.hours.endWork !== '' && !validTimeSpanRegex.test(date.hours.endWork)) {
      setFormatError(0);
      canSave = false;
    }

    if (date.hours.startBreak !== '' && !validTimeSpanRegex.test(date.hours.startBreak)) {
      setFormatError(0);
      canSave = false;
    }

    if (date.hours.endBreak !== '' && !validTimeSpanRegex.test(date.hours.endBreak)) {
      setFormatError(0);
      canSave = false;
    }

    if (quantity < 1) {
      canSave = false;
    }

    setWeekRowRequiredError(weekRowRequiredErrorAux);
    const dateErrKeys = Object.keys(datesErrorsObject);
    let foundDateErr = false;
    dateErrKeys.forEach((key: any) => {
      if (datesErrorsObject[key].found) foundDateErr = true;
    });

    if (!weekRowRequiredErrorAux && !foundDateErr && canSave) {
      handleSubmit(saveContractSlot, err => console.log('error', err))();
    }
  }, [contractSlotDate, quantity, datesErrorsObject, handleSubmit, saveContractSlot]);

  const checkIfARowHasAnyErrors = useCallback(
    (modifItem: IWeekDays, modifItemIndex: number, indexRow: number, row: IWeekDaysRow) => {
      const errObjForRow = { found: false, foundDateInPresent: false };
      const errorsForRow = row.days.map((day: IWeekDays) => {
        const errorForDay = checkIfThereIsARowDateInPast(day);

        return errorForDay;
      });
      const foundErr = errorsForRow.find((err: any) => err.found);
      const foundDateInPresentErr = errorsForRow.find((err: any) => err.foundDateInPresent);
      if (foundErr) errObjForRow.found = true;
      if (foundDateInPresentErr) errObjForRow.foundDateInPresent = true;

      datesErrorsObject[indexRow] = errObjForRow;
    },
    [checkIfThereIsARowDateInPast, datesErrorsObject],
  );

  const onCheckDay = useCallback(
    (item: IWeekDays) => {
      const slotDate = contractSlotDate[0];
      const itemIndex = slotDate.days.findIndex(e => e.date === item.date);
      const modifiedItem = { ...slotDate.days[itemIndex] };
      slotDate.days = slotDate.days.map((day: IWeekDays) => {
        return { ...day, checked: false };
      });
      modifiedItem.checked = !item.checked;
      slotDate.days[itemIndex] = modifiedItem;
      setContractSlotDate([...contractSlotDate]);
      checkIfARowHasAnyErrors(modifiedItem, itemIndex, 0, slotDate);
    },
    [contractSlotDate, checkIfARowHasAnyErrors],
  );

  useEffect(() => {
    dispatch(getFunctionsAsDropdownItemsAction(toNumber(companyId)));
    dispatch(getCostCentersAction(toNumber(companyId)));
    dispatch(getVendorsAction(companyId));
    dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));
  }, [companyId, dispatch]);

  return (
    <Backdrop open sx={{ zIndex: theme.zIndex.drawer + 1 }}>
      <ModalWrapperContract open onClose={onClose}>
        <ModalContainer sx={{ padding: '5px' }}>
          <ModalHeaderContract>
            <ModalTitleContainer>
              <ModalTitle>
                {t(CONTRACTS__NEW_CONTRACT_SLOT)} | {`${t(HEADER__WEEK)} ${week}`}
              </ModalTitle>
              <ModalCloseIconStyled onClick={onClose}>
                <CloseIcon fill={theme.palette.secondary.contrastText} />
              </ModalCloseIconStyled>
            </ModalTitleContainer>

            <RowContainerForNameAndContractType>
              <NameInputStyled sx={{ marginRight: '8px' }}>
                <Controller
                  name={ContractSlotFieldNames.VENDOR_ID}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={vendorItems}
                      value={field.value}
                      onChange={field.onChange}
                      label={t(CONTRACTS__VENDOR)}
                      required
                      error={!!errors.vendorId}
                      helperText={getInputErrorText(errors, ContractSlotFieldNames.VENDOR_ID)}
                    />
                  )}
                />
              </NameInputStyled>
              <WorkerclassInputStyled>
                <Controller
                  name={ContractSlotFieldNames.FUNCTION_ID}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={functions}
                      value={field.value}
                      onChange={field.onChange}
                      label={t(CONTRACTS__FUNCTION)}
                      required
                      error={!!errors.functionId}
                      helperText={getInputErrorText(errors, ContractSlotFieldNames.FUNCTION_ID)}
                    />
                  )}
                />
              </WorkerclassInputStyled>
              <Controller
                name={ContractSlotFieldNames.CONTRACT_TYPE}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdownStyled
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t(GENERAL__SELECT)}
                    items={workerclassList}
                    label={t(GENERAL__WORKERCLASS)}
                    isFilter
                    required
                    error={!!errors.contractType}
                    helperText={getInputErrorText(errors, ContractSlotFieldNames.CONTRACT_TYPE)}
                  />
                )}
              />
            </RowContainerForNameAndContractType>
          </ModalHeaderContract>
          <ModalContentContainer>
            <ContractDateContainer>
              <AddEditDayPickerRow
                week={week}
                weekDate={weekDate}
                weekDays={contractSlotDate[0].days}
                onCheckDay={(selectedDate: IWeekDays) => onCheckDay(selectedDate)}
                onChangeHours={onChangeHoursEdit}
                error={weekRowRequiredError}
                currentDateError={datesErrorsObject}
                currentStartWorkError={currentStartWorkError}
                currentEndWorkError={currentEndWorkError}
                formatError={formatError}
                row={0}
                hours={contractSlotDate[0].hours}
                defaultBreakTime={defaultBreakTime}
              />
            </ContractDateContainer>
            <RowContainerForNameAndContractType>
              <NameInputStyled sx={{ marginRight: '8px' }}>
                <Controller
                  name={ContractSlotFieldNames.LOCATION_ID}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={locations}
                      value={field.value}
                      onChange={field.onChange}
                      label={t(CONTRACTS__COMPANY_LOCATION)}
                      required
                      error={!!errors.locationId}
                      helperText={getInputErrorText(errors, ContractSlotFieldNames.LOCATION_ID)}
                    />
                  )}
                />
              </NameInputStyled>
              <Box sx={{ width: 1 / 5, mr: theme.spacing(1) }}>
                <UPInput
                  label={t(GENERAL__QUANTITY)}
                  onChange={event => {
                    if (event.target.value >= 0) setQuantity(event.target.value);
                  }}
                  error={quantity < 1}
                  value={quantity}
                  type="number"
                  placeholder=""
                />
              </Box>
              <NameInputStyled>
                <Controller
                  name={ContractSlotFieldNames.COST_CENTER_ID}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={costCentersItems}
                      value={field.value}
                      onChange={field.onChange}
                      label={t(COMPANY_PAYROLL__COST_CENTERS)}
                      required
                      error={!!errors.costCenterId}
                      helperText={getInputErrorText(errors, ContractSlotFieldNames.COST_CENTER_ID)}
                    />
                  )}
                />
              </NameInputStyled>
            </RowContainerForNameAndContractType>
          </ModalContentContainer>
          <ModalFooter>
            <UPButton text={t(GENERAL__CREATE)} onClick={onSubmit} />
            <Box sx={{ marginLeft: '12px' }}>
              <UPButton text={t(CONTRACTS__CANCEL)} onClick={onClose} outlined />
            </Box>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapperContract>
    </Backdrop>
  );
};

export default AddContractSlotModal;
