import { useEffect, useState } from 'react';
import { UPDropdownItem } from 'components/dropdown/types';
import { UPInputLabel } from 'components/input/styles';
import { Box, TextField } from '@mui/material';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import { DROPDOWN_SEARCH__NO_OPTIONS } from 'translations/constants';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { UPSearchDropdownStyled } from './styles';
import { UPDropdownWithSearchProps } from './types';

const UPDropdownWithSearch = (props: UPDropdownWithSearchProps): JSX.Element => {
  const {
    items,
    value,
    onChange,
    placeholder,
    label,
    required,
    disabled,
    error,
    helperText,
    disableClearable,
    hasDefaultValue,
    gtmEvent,
  } = props;
  const [t] = useTranslation();

  const [selectedItem, setSelectedItem] = useState<UPDropdownItem>(null);

  useEffect(() => {
    if (hasDefaultValue && items.length === 1) {
      setSelectedItem(items[0]);
      return;
    }
    if (!value || items.length === 0) {
      setSelectedItem(null);
      return;
    }
    const option = items.find(item => item.value.toString() === value.toString());
    if (option !== undefined || option !== null) {
      setSelectedItem(option);
    }
  }, [value, items, hasDefaultValue]);

  const getOptionLabel = (option: any): string => {
    if (option instanceof Array && option.length > 0) {
      const first = option[0] as UPDropdownItem;

      return first.label;
    }

    if (option && !(option instanceof Array)) return option.label;
    return '';
  };

  return (
    <Box sx={{ width: '100%' }}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <UPSearchDropdownStyled
        freeSolo
        key={selectedItem ? `${value}${Math.random()}` : `${Math.random()}`}
        options={items}
        onChange={(_, option: unknown) => {
          if (option) {
            setSelectedItem(option as UPDropdownItem);
            onChange((option as UPDropdownItem)?.value);
          } else {
            setSelectedItem(null);
            onChange(null);
          }
        }}
        value={selectedItem ? [selectedItem] : []}
        isOptionEqualToValue={(option, value2) => option.value === value2.value}
        getOptionLabel={option => getOptionLabel(option)}
        forcePopupIcon
        disabled={disabled}
        noOptionsText={t(DROPDOWN_SEARCH__NO_OPTIONS)}
        popupIcon={<ArrowDropDownIcon />}
        disableClearable={disableClearable}
        // below to avoid duplication of options
        renderOption={(p, o: UPDropdownItem) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...p} key={o.value}>
            {o.label}
          </li>
        )}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={placeholder}
            variant="outlined"
            error={error}
            onChange={e => {
              if (!e.target.value) {
                onChange(null);
                setSelectedItem(null);
              }
            }}
          />
        )}
        data-gtm-event={gtmEvent}
      />
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

UPDropdownWithSearch.defaultProps = {
  placeholder: 'Select',
  required: false,
  disabled: false,
  error: false,
  disableClearable: true,
};
export default UPDropdownWithSearch;
