import { AddDayPickerRowProps } from 'modules/contracts/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import {
  validateContractErrorMessageRowSelector,
  validateContractErrorMessageSelector,
  validateContractExtraMessageSelector,
} from 'store/selectors/contract-selectors';
import { Box } from '@mui/material';
import UPDayPicker from 'components/day-picker';
import {
  CONTRACTS__DATES,
  CONTRACTS__START_WORK,
  CONTRACTS__START_BREAK,
  CONTRACTS__PRESENT_START_HOUR_ERROR,
  CONTRACTS__END_HOUR_ERROR,
  CONTRACTS__INVALID_HOUR_ERROR,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_WORK,
} from 'translations/constants';
import UPDropdownWithInput from 'components/dropdown-with-input';
import { filterByHour } from 'modules/contracts/helpers';
import HOUR_OPTIONS from 'modules/contracts/HourDropdownOptions';
import {
  DateDropdownContainer,
  DayPickerEndHourError,
  DayPickerHoursError,
  HourDropdownContainer,
  RowContainerForContractDate,
} from '../styles';
import { addTimespans } from './helpers';

const AddDayPickerRow = (props: AddDayPickerRowProps) => {
  const {
    week,
    weekDays,
    onCheckDay,
    hours,
    onChangeHours,
    error,
    row,
    dateErrors,
    currentStartWorkError,
    currentEndWorkError,
    formatError,
    weekDate,
    defaultBreakTime,
  } = props;

  const [t] = useTranslation();

  const invalidRowNumber = useAppSelector(validateContractErrorMessageRowSelector);
  const invalidContractError = useAppSelector(validateContractErrorMessageSelector);
  const contractValidationExtraInfo = useAppSelector(validateContractExtraMessageSelector);

  const translatedError = useMemo(() => {
    if (invalidContractError) {
      return `${t(invalidContractError)} ${contractValidationExtraInfo ?? ''}`;
    }
    return '';
  }, [contractValidationExtraInfo, invalidContractError, t]);

  const getEndBreakTime = () => {
    if (defaultBreakTime && hours.startBreak) {
      hours.endBreak = addTimespans(hours.startBreak, defaultBreakTime);
    }
  };

  return (
    <Box>
      <RowContainerForContractDate>
        <DateDropdownContainer>
          <UPDayPicker
            label={t(CONTRACTS__DATES)}
            week={week}
            weekDays={weekDays}
            handleDaySelect={onCheckDay}
            error={error || dateErrors[row].found}
            currentDateError={dateErrors[row].found}
            weekForYear={weekDate}
          />
        </DateDropdownContainer>
        <HourDropdownContainer>
          <UPDropdownWithInput
            withSearchIcon={false}
            value={hours.startWork}
            handleChange={onChangeHours}
            filterOptions={filterByHour}
            placeholder=""
            items={HOUR_OPTIONS}
            label={t(CONTRACTS__START_WORK)}
            required
            error={currentStartWorkError}
            type="startWork"
          />
        </HourDropdownContainer>
        <HourDropdownContainer>
          <UPDropdownWithInput
            value={hours.startBreak}
            handleChange={e => {
              onChangeHours(e, 'startBreak');
              getEndBreakTime();
            }}
            filterOptions={filterByHour}
            placeholder=""
            items={HOUR_OPTIONS}
            label={t(CONTRACTS__START_BREAK)}
            type="startBreak"
          />
        </HourDropdownContainer>
        <HourDropdownContainer>
          <UPDropdownWithInput
            value={hours.endBreak}
            handleChange={onChangeHours}
            filterOptions={filterByHour}
            placeholder=""
            items={HOUR_OPTIONS}
            label={t(CONTRACTS__END_BREAK)}
            type="endBreak"
          />
        </HourDropdownContainer>
        <HourDropdownContainer>
          <UPDropdownWithInput
            value={hours.endWork}
            handleChange={onChangeHours}
            filterOptions={filterByHour}
            placeholder=""
            items={HOUR_OPTIONS}
            label={t(CONTRACTS__END_WORK)}
            required
            error={currentEndWorkError}
            type="endWork"
          />
        </HourDropdownContainer>
      </RowContainerForContractDate>
      {row === invalidRowNumber && <DayPickerHoursError>{translatedError}</DayPickerHoursError>}
      {currentStartWorkError && <DayPickerHoursError>{t(CONTRACTS__PRESENT_START_HOUR_ERROR)}</DayPickerHoursError>}
      {currentEndWorkError && <DayPickerEndHourError>{t(CONTRACTS__END_HOUR_ERROR)}</DayPickerEndHourError>}
      {row === formatError && <DayPickerHoursError>{t(CONTRACTS__INVALID_HOUR_ERROR)}</DayPickerHoursError>}
    </Box>
  );
};

export default AddDayPickerRow;
