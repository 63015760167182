import Tooltip from '@mui/material/Tooltip';
import { IconWithMargin } from 'modules/contracts/styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { contractDateToCopySelector, isCopyModeEnabledSelector } from 'store/selectors/contracts-selector';
import {
  CONTRACTS__CLEAR_ALL,
  CONTRACTS__CONFIRM_MULTIPLE_ERROR,
  CONTRACTS__CONFIRM_SELECTED,
  CONTRACTS__DELETE_SELECTED,
  CONTRACTS__SELECTED,
} from 'translations/constants';
import { AlertStyled } from './styles';
import './styles.scss';
import { BottomGreenBarActionButtonProps, BottomGreenBarProps } from './types';

const ActionButton = (props: BottomGreenBarActionButtonProps) => {
  const { action, error, onDelete, onCopy, total, onCancelCopyMode } = props;
  const { t } = useTranslation();
  const isDeleteAction = action.content === t(CONTRACTS__DELETE_SELECTED);
  const isCopyModeEnabled = useAppSelector(isCopyModeEnabledSelector);
  const contractDateToCopy = useAppSelector(contractDateToCopySelector);
  let actionHandler = isDeleteAction ? onDelete : onCopy;
  if (isCopyModeEnabled && !!contractDateToCopy) {
    actionHandler = onCancelCopyMode;
  }

  if (error && action.content === t(CONTRACTS__CONFIRM_SELECTED)) {
    return (
      <Tooltip title={t(CONTRACTS__CONFIRM_MULTIPLE_ERROR)} arrow key={`${action.content} ${total}`}>
        <button className="icon-container" type="button" onClick={actionHandler}>
          <IconWithMargin>{action.icon}</IconWithMargin>
          <div className="icon-content">{action.content}</div>
        </button>
      </Tooltip>
    );
  }

  return (
    <button
      className="icon-container"
      type="button"
      onClick={actionHandler}
      key={action.content}
      data-gtm-event={action.gtmEvent}
    >
      <IconWithMargin>{action.icon}</IconWithMargin>
      <div className="icon-content">{action.content}</div>
    </button>
  );
};

const BottomGreenBar = (props: BottomGreenBarProps): JSX.Element => {
  const { numberOfItems, actions, onClearAll, onDelete, onCopy, total, error, isDeletionPossible, onCancelCopyMode } =
    props;
  const [t] = useTranslation();
  const isCopyModeEnabled = useAppSelector(isCopyModeEnabledSelector);

  const renderActionButtons = () => {
    return actions.map(action => {
      if (action.content === t(CONTRACTS__DELETE_SELECTED) && !isDeletionPossible) {
        return null;
      }
      return (
        <ActionButton
          key={action.content}
          action={action}
          error={error}
          onDelete={onDelete}
          onCopy={onCopy}
          onCancelCopyMode={onCancelCopyMode}
          total={total}
          gtmEvent={action.gtmEvent}
        />
      );
    });
  };

  return (
    <AlertStyled severity="success" icon={false}>
      <div className="contracts-number">
        {numberOfItems} {t(CONTRACTS__SELECTED).toLocaleLowerCase()} |
      </div>

      {total && <div className="contracts-number">{total} |</div>}

      {!isCopyModeEnabled && (
        <button type="button" className="clear-all" onClick={onClearAll}>
          {t(CONTRACTS__CLEAR_ALL)}
        </button>
      )}

      <div className="icons-container">{renderActionButtons()}</div>
    </AlertStyled>
  );
};

BottomGreenBar.defaultProps = {
  numberOfItems: 0,
  actions: [],
};

export default BottomGreenBar;
