import { Avatar, Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import BottomGreenBar from 'components/bottom-green-bar';
import { UPButtonStyledWithNoPadding } from 'components/button/styles';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { getContractsListViewSelector } from 'store/selectors/contracts-selector';
import { getWeekDaysAbbreviations } from 'utils/helpers';
import { getContractsInListViewAction } from 'store/actions/contract-actions';
import { ContractListViewTypes } from 'constants/ContractListViewTypes';
import { ContractPageSize } from 'constants/ContractPageSize';
import {
  COMPANY_PAYROLL__EMPLOYEE,
  CONTRACTS__ARE_YOU_SURE,
  CONTRACTS__COST_CENTER,
  CONTRACTS__DATE,
  CONTRACTS__DELETE_SELECTED,
  CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_WORK,
  CONTRACTS__FUNCTION,
  CONTRACTS__NO_SHOW,
  CONTRACTS__START_BREAK,
  CONTRACTS__START_WORK,
  GENERAL__TOTAL,
} from 'translations/constants';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/DeleteFull.svg';
import { ReactComponent as DeleteIconGreen } from '../../../assets/icons/DeleteGreen.svg';
import { ReactComponent as EditEmployeeAvatar } from '../../../assets/icons/EditEmployeeAvatar.svg';
import { ReactComponent as NoShow } from '../../../assets/icons/NoShow.svg';
import {
  CompareContractDateSorting,
  EmployeeNameComparator,
  calculateTotalTime,
  generateCostCenterName,
  generateFormatedDate,
  generateListViewContractChecked,
  generateTotalHours,
  isAnyContractWeekly,
  totalHoursComparator,
} from '../helpers';
import { IContractData, IContractsListViewProps } from '../types';

const ArchivedContractsView = (props: IContractsListViewProps): JSX.Element => {
  const { makeDeleteRequest, functionsList, costCentersList, setSelectedContractsId, companyId, filters } = props;

  const dispatch = useAppDispatch();

  const weekDaysNames = useMemo(() => getWeekDaysAbbreviations(), []);
  const [t] = useTranslation();
  const isAdmin = useAppSelector(profileSelector).role === CompanyRole.ADMIN;
  const contractData = useAppSelector(getContractsListViewSelector);
  const [contracts, setContracts] = useState<IContractData[]>(contractData);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [totalTime, setTotalTime] = useState<string>('');
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [openConfirmDeleteWeeklyModal, setOpenConfirmDeleteWeeklyModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<number[]>([]);
  const [pageSize, setPageSize] = useState<number>(ContractPageSize.DefaultListView);

  filters.listViewType = ContractListViewTypes.Archived;

  const bottomGreenBarActions = useMemo(() => {
    return [
      {
        content: t(CONTRACTS__DELETE_SELECTED),
        icon: <DeleteIcon />,
      },
    ];
  }, [t]);

  const onDeleteContracts = (ids: number[]) => {
    const newContracts = contracts.filter((c: IContractData) => ids.includes(c.id));
    if (isAnyContractWeekly(newContracts)) setOpenConfirmDeleteWeeklyModal(true);
    else {
      setOpenConfirmDeleteModal(true);
    }
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      const idList = selectedRows.map(i => {
        const id = i.toString().split('_')[0];
        return toNumber(id);
      });
      const auxTotal = calculateTotalTime(generateListViewContractChecked(contracts, idList));
      setTotalTime(auxTotal);
    }
  }, [contracts, selectedRows]);

  useEffect(() => {
    const idList = selectedRows.map(i => {
      const id = i.toString().split('_')[0];
      return toNumber(id);
    });
    setSelectedContractsId(idList);
  }, [selectedRows, setSelectedContractsId]);

  useEffect(() => {
    if (contractData.length > 0) {
      setContracts(contractData);
    } else {
      setContracts([]);
    }
  }, [contractData]);

  const contractsColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'contractId', headerName: '', hide: true },
    { field: 'companyId', headerName: '', hide: true },
    {
      field: 'employeeAvatar',
      headerName: '',
      width: 65,
      sortable: false,
      renderCell: params => {
        if (params.value === null) {
          return <EditEmployeeAvatar />;
        }
        return (
          <div>
            <Avatar src={params.value} />
          </div>
        );
      },
    },
    {
      field: 'employeeFullName',
      headerName: t(COMPANY_PAYROLL__EMPLOYEE),
      width: 200,
      sortable: true,
      renderCell: params => {
        return `${params.row.employee.firstName} ${params.row.employee.lastName}`;
      },
      sortComparator: (v1, v2, param1, param2) => EmployeeNameComparator(v1, v2, param1, param2),
    },
    {
      field: 'formatedDate',
      headerName: t(CONTRACTS__DATE),
      width: 90,
      editable: false,
      align: 'center',
      sortable: true,
      renderCell: params => {
        return generateFormatedDate(weekDaysNames, params.row.date);
      },
      sortComparator: (v1, v2, param1, param2) => {
        return CompareContractDateSorting(param1, param2);
      },
    },
    { field: 'startHour', headerName: t(CONTRACTS__START_WORK), width: 120, sortable: true, align: 'center' },
    { field: 'startBreak', headerName: t(CONTRACTS__START_BREAK), width: 120, sortable: true, align: 'center' },
    { field: 'endBreak', headerName: t(CONTRACTS__END_BREAK), width: 120, sortable: true, align: 'center' },
    { field: 'endHour', headerName: t(CONTRACTS__END_WORK), width: 120, sortable: true, align: 'center' },
    {
      field: 'totalHours',
      headerName: t(GENERAL__TOTAL),
      width: 100,
      renderCell: params => {
        const totals = generateTotalHours(
          params.row.startHour,
          params.row.endHour,
          params.row.startBreak,
          params.row.endBreak,
        );
        return totals.value;
      },
      sortComparator: totalHoursComparator,
      align: 'center',
    },
    {
      field: 'functionId',
      headerName: t(CONTRACTS__FUNCTION),
      width: 166,
      sortable: true,
      editable: false,
      renderCell: params => {
        return generateCostCenterName(params.row.functionId, functionsList);
      },
    },
    {
      field: 'costCenterId',
      headerName: t(CONTRACTS__COST_CENTER),
      width: 166,
      sortable: true,
      editable: false,
      renderCell: params => {
        return generateCostCenterName(params.row.costCenterId, costCentersList);
      },
    },
    {
      field: 'noShow',
      headerName: t(CONTRACTS__NO_SHOW),
      headerAlign: 'center',
      width: 166,
      sortable: true,
      editable: false,
      align: 'center',
      renderCell: params => {
        if (params.row.noShow) {
          return <NoShow />;
        }
        return '';
      },
    },
    isAdmin && {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <UPButtonStyledWithNoPadding
              onClick={() => {
                setRowToDelete([toNumber(params.id)]);
                onDeleteContracts([toNumber(params.id)]);
              }}
            >
              <DeleteIconGreen />
            </UPButtonStyledWithNoPadding>
          );
        }
        return null;
      },
    },
  ];

  const setPage = (newPage: number) => {
    filters.pageNumber = newPage;
    filters.pageSize = pageSize;
    filters.listViewType = ContractListViewTypes.Archived;
    dispatch(
      getContractsInListViewAction({
        companyId: toNumber(companyId),
        filter: filters,
      }),
    );
  };

  return (
    <Box>
      <UPDataGridContainer sx={{ height: '100vh' }}>
        <UPDataGrid
          rows={contracts}
          columns={contractsColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selectedItem: any) => {
            setSelectedRows(selectedItem);
            setClearAll(false);
          }}
          withCheckboxes
          getRowId={r => r.identifier}
          selectionModel={clearAll ? [] : selectedRows}
          disableSelectionOnClick
          onPageSizeChange={newSize => setPageSize(newSize)}
          onPageChange={newPage => setPage(newPage)}
        />
      </UPDataGridContainer>
      {selectedRows?.length > 0 && isAdmin && (
        <BottomGreenBar
          numberOfItems={selectedRows.length}
          actions={bottomGreenBarActions}
          onClearAll={() => {
            setSelectedRows([]);
            setClearAll(true);
          }}
          onDelete={() => onDeleteContracts(selectedRows)}
          onCopy={null}
          total={totalTime}
        />
      )}
      {openConfirmDeleteWeeklyModal && (
        <DeleteModal
          open={openConfirmDeleteWeeklyModal}
          content={t(CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING)}
          onClose={() => {
            setOpenConfirmDeleteWeeklyModal(false);
          }}
          onDelete={() => {
            setOpenConfirmDeleteModal(true);
          }}
        />
      )}
      {openConfirmDeleteModal && (
        <DeleteModal
          open={openConfirmDeleteModal}
          content={t(CONTRACTS__ARE_YOU_SURE)}
          onClose={() => {
            setOpenConfirmDeleteModal(false);
          }}
          onDelete={() => {
            makeDeleteRequest(rowToDelete.length === 1 ? rowToDelete : selectedRows, ContractListViewTypes.Archived);
            setRowToDelete([]);
          }}
        />
      )}
    </Box>
  );
};

export default ArchivedContractsView;
