import { Box } from '@mui/material';
import UPDayPickerDisplay from 'components/day-picker-display';
import UPDropdownWithInput from 'components/dropdown-with-input';
import { CompanyRole } from 'constants/CompanyRole';
import { filterByHour } from 'modules/contracts/helpers';
import HOUR_OPTIONS from 'modules/contracts/HourDropdownOptions';
import { EndWorkContainer } from 'modules/contracts/styles';
import { EditDayPickerRowProps } from 'modules/contracts/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { validateContractExtraMessageSelector } from 'store/selectors/contract-selectors';
import {
  validateEditContractErrorMessageRowSelector,
  validateEditContractErrorMessageSelector,
} from 'store/selectors/contracts-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  CONTRACTS__DATE,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_HOUR_ERROR,
  CONTRACTS__END_WORK,
  CONTRACTS__INVALID_HOUR_ERROR,
  CONTRACTS__PRESENT_START_HOUR_ERROR,
  CONTRACTS__START_BREAK,
  CONTRACTS__START_WORK,
} from 'translations/constants';
import { addTimespans } from '../add/helpers';
import {
  DateDropdownContainer,
  DayPickerHoursError,
  DayPickerHoursSecondError,
  HourDropdownContainer,
  RowContainerForContractDate,
} from '../styles';

const EditDayPickerRow = (props: EditDayPickerRowProps) => {
  const { currentStartWorkError, currentEndWorkError, formatError, onChangeHours, weekDays, onChangeStartBreak } =
    props;

  const [t] = useTranslation();
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;
  const invalidEditRow = useAppSelector(validateEditContractErrorMessageRowSelector);
  const editContractErrorMesage = useAppSelector(validateEditContractErrorMessageSelector);
  const contractValidationExtraInfo = useAppSelector(validateContractExtraMessageSelector);

  const translatedError = useMemo(() => {
    if (editContractErrorMesage) {
      return `${t(editContractErrorMesage)} ${contractValidationExtraInfo ?? ''}`;
    }
    return '';
  }, [contractValidationExtraInfo, editContractErrorMesage, t]);

  return (
    <Box>
      {weekDays.map((day, index) => (
        <Box key={day.id}>
          <RowContainerForContractDate key={day.date.toString()}>
            <DateDropdownContainer>
              <UPDayPickerDisplay label={t(CONTRACTS__DATE)} day={new Date(day.date)} />
            </DateDropdownContainer>
            <HourDropdownContainer>
              <UPDropdownWithInput
                withSearchIcon={false}
                value={day.startHour}
                handleChange={(value: string) => onChangeHours(value, 'startHour', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__START_WORK)}
                required
                type="startWork"
                error={currentStartWorkError}
                disabled={isEmployee}
              />
            </HourDropdownContainer>
            <HourDropdownContainer>
              <UPDropdownWithInput
                value={day.startBreak}
                handleChange={(value: string) => onChangeStartBreak(value, index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__START_BREAK)}
                type="startBreak"
                disabled={isEmployee}
              />
            </HourDropdownContainer>
            <HourDropdownContainer>
              <UPDropdownWithInput
                value={day.endBreak}
                handleChange={(value: string) => onChangeHours(value, 'endBreak', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__END_BREAK)}
                type="endBreak"
                disabled={isEmployee}
              />
            </HourDropdownContainer>
            <EndWorkContainer>
              <UPDropdownWithInput
                value={day.endHour}
                handleChange={(value: string) => onChangeHours(value, 'endHour', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__END_WORK)}
                required
                type="endWork"
                disabled={isEmployee}
                error={currentEndWorkError}
              />
            </EndWorkContainer>
          </RowContainerForContractDate>
          {index === invalidEditRow && <DayPickerHoursSecondError>{translatedError}</DayPickerHoursSecondError>}
          {currentStartWorkError && <DayPickerHoursError>{t(CONTRACTS__PRESENT_START_HOUR_ERROR)}</DayPickerHoursError>}
          {currentEndWorkError && <DayPickerHoursError>{t(CONTRACTS__END_HOUR_ERROR)}</DayPickerHoursError>}
          {index === formatError && <DayPickerHoursError>{t(CONTRACTS__INVALID_HOUR_ERROR)}</DayPickerHoursError>}
        </Box>
      ))}
    </Box>
  );
};

export default EditDayPickerRow;
