import { create, toNumber } from 'lodash';
import ContractFilters from 'types/contract/IContractFilters';
import IAddEditContract from 'types/contract/IAddEditContract';
import IConfirmContract from 'types/contract/IConfirmContract';
import IConfirmHours from 'types/contract/IConfirmHours';
import IContractDates from 'types/contract/IContractDates';
import IContractRequest from 'types/contract/IContractRequest';
import IValidateHours from 'types/contract/IValidateHours';
import IWeekDaysRow from 'types/contract/IWeekDaysRow';
import { ContractPageSize } from 'constants/ContractPageSize';
import IValidateResponse from 'types/contract/IValidateResponse';
import { IAddContract, IMultipleEmployeeContractRequest } from 'types/contract';

export const fromContractModelToContractRequest = (
  contract: IAddEditContract,
  weekDaysHours: IWeekDaysRow[],
  week: number,
  year: number,
  wage: string,
  workingLocation: string,
): IContractRequest => {
  const contractDates: IContractDates[] = [];
  weekDaysHours.forEach((u, index) => {
    const contractDays: string[] = [];
    u.days.forEach(t => {
      if (t.checked) contractDays.push(t.date3);
    });
    if (contractDays.length > 0) {
      contractDates.push({
        days: contractDays,
        startWork: u.hours.startWork,
        startBreak: u.hours.startBreak,
        endBreak: u.hours.endBreak,
        endWork: u.hours.endWork,
        rowNumber: index,
      });
    }
  });
  const contractRequest: IContractRequest = {
    id: contract.id,
    companyId: toNumber(contract.companyId),
    employeeId: toNumber(contract.employeeId),
    contractType: contract.contractType,
    functionId: toNumber(contract.functionId),
    costCenterId: toNumber(contract.costCenterId),
    locationId: toNumber(contract.locationId),
    week,
    year,
    contractDates,
    wage: toNumber(wage),
    workingLocation,
    permanent: contract.permanent,
    vendorId: contract.vendorId ? toNumber(contract.vendorId) : 0,
  };
  return contractRequest;
};

const createContractDate = (days: string[], hours: IWeekDaysRow, rowNumber: number): IContractDates => {
  return {
    days,
    startWork: hours.hours.startWork,
    startBreak: hours.hours.startBreak,
    endBreak: hours.hours.endBreak,
    endWork: hours.hours.endWork,
    rowNumber,
  };
};

export const fromMultipleEmployeeContractToMultipleEmployeeContractRequest = (
  contract: IAddContract,
  dates: IWeekDaysRow[],
  week: number,
  year: number,
): IMultipleEmployeeContractRequest => {
  const contractDates: IContractDates[] = [];
  dates.forEach((date, index) => {
    const contractDays: string[] = [];
    date.days.forEach(t => {
      if (t.checked) contractDays.push(t.date3);
    });
    if (contractDays.length > 0) {
      contractDates.push(createContractDate(contractDays, date, index));
    }
  });

  const request: IMultipleEmployeeContractRequest = {
    employeePairs: contract.employees,
    contractDates,
    functionId: toNumber(contract.functionId),
    costCenterId: toNumber(contract.costCenterId),
    locationId: toNumber(contract.locationId),
    workingLocation: contract.workingLocation,
    week,
    year,
  };
  return request;
};

export const fromConfirmContractToConfirmHours = (contract: IConfirmContract): IConfirmHours => {
  const contractRequest: IConfirmHours = {
    contractDateId: contract.contractDateId,
    day: contract.day,
    startWork: contract.startWork,
    startBreak: contract.startBreak,
    endBreak: contract.endBreak,
    endWork: contract.endWork,
    noShow: false,
    costDeclarations: false,
    reasonForNoShow: '',
    costCenterId: contract.costCenterId,
    locationId: contract.locationId,
  };
  return contractRequest;
};

export const fromConfirmHoursToValidateConfirmHours = (
  hours: IConfirmHours,
  employeeId: number,
  companyId: string,
  functionId: number,
): IValidateHours => {
  const contractRequest: IValidateHours = {
    employeeId,
    companyId: toNumber(companyId),
    functionId,
    contractHourConfirmation: hours,
  };
  return contractRequest;
};

export const createDefaultContractFilters = (
  year: number,
  week: number,
  contractType?: string,
  locationId?: number,
  pageSize?: number,
) => {
  const request: ContractFilters = {
    year,
    week,
    pageNumber: 1,
    pageSize: pageSize ?? ContractPageSize.Default,
    confirmHours: null,
    employeeId: null,
    workerClass: contractType,
    locationId,
  };

  return request;
};

export const fromValidateResponseToValidateReadyForDisplay = (
  validateResponse: IValidateResponse,
): IValidateResponse => {
  return {
    ...validateResponse,
    errorMessageDetails: validateResponse.errorMessageDetails === null ? '' : validateResponse.errorMessageDetails,
  };
};
