import { Box, Grid } from '@mui/material';
import UPInput from 'components/input';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { clearCompanyAction, getCompaniesAction } from 'store/actions/company-actions';
import { clearCompanyContactAction } from 'store/actions/company-contact-actions';
import { removeAllCostCentersAction } from 'store/actions/company-cost-centers-actions';
import { clearCompanyFinancialAction } from 'store/actions/company-financial-actions';
import { removeAllFunctionsAction } from 'store/actions/company-functions-actions';
import { removeAllLocationsAction } from 'store/actions/company-locations-actions';
import { removeAllManagersAction } from 'store/actions/company-managers-actions';
import { clearCompanyWageExemptionsAction } from 'store/actions/company-wage-exemptions-actions';
import { clearBicAction } from 'store/actions/financial-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { clearCompanyPayrollAction } from 'store/actions/payroll-actions';
import { companiesSelector } from 'store/selectors/company-selectors';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import {
  COMPANIES__ADD_NEW_COMPANY,
  COMPANY__CLIENTS_TOGGLE_OPTION,
  COMPANY__NO_COMPANIES_MATCH_SEARCH,
  COMPANY__NO_COMPANIES_YET,
  COMPANY__ONBOARDING_TOGGLE_OPTION,
  GENERAL__SEARCH,
  MENU__COMPANIES,
} from 'translations/constants';
import UPToggle from 'components/toggle/UPToggle';
import { ICompanyGridDetails } from 'types/company';
import { escapeRegExp, getFormattedCompanyAddress } from 'utils/helpers';
import UPCard from 'components/card';
import { toNumber } from 'lodash';
import UPPagination from 'components/pagination';
import { generateNumberOfPages } from 'modules/employees/helpers';
import { ReactComponent as CompanyLogo } from '../../assets/icons/CompanyLogo.svg';
import { CompanyAddNewItemButton, ToggleContainer } from './styles';

const Companies = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState('');
  const [searchMatchCompanies, setSearchMatchCompanies] = useState<ICompanyGridDetails[]>([]);
  const [currentPageCompanies, setCurrentPageCompanies] = useState<ICompanyGridDetails[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [selectedOption, setSelectedOption] = useState<number>(1);

  const companies = useAppSelector(companiesSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);

  useEffect(() => {
    if (activeItem !== t(MENU__COMPANIES)) {
      dispatch(setMenuStatusAction(t(MENU__COMPANIES)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    dispatch(getCompaniesAction());
    dispatch(clearCompanyAction());
    dispatch(clearCompanyContactAction());
    dispatch(removeAllLocationsAction());
    dispatch(removeAllManagersAction());
    dispatch(clearCompanyPayrollAction());
    dispatch(removeAllCostCentersAction());
    dispatch(removeAllFunctionsAction());
    dispatch(clearCompanyWageExemptionsAction());
    dispatch(clearCompanyFinancialAction());
    dispatch(clearBicAction());
  }, [dispatch]);

  useEffect(() => {
    setSearchMatchCompanies(selectedOption === 1 ? companies : []);
    setSearchText('');
  }, [selectedOption, companies]);

  useEffect(() => {
    if (searchMatchCompanies) {
      const auxNumber = generateNumberOfPages(itemsPerPage, searchMatchCompanies.length);
      setNumberOfPages(auxNumber);
      const start = (currentPage - 1) * itemsPerPage;
      setCurrentPageCompanies(searchMatchCompanies.slice(start, start + itemsPerPage));
    }
  }, [searchMatchCompanies, currentPage, itemsPerPage]);

  const displayCompanies = useCallback(() => {
    if (currentPageCompanies.length > 0) {
      return currentPageCompanies.map(c => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={c.Id}>
          <UPCard
            title={c.Name}
            subTitle={c.CommercialName}
            description={getFormattedCompanyAddress(c.Address)}
            onClick={() => navigate(`/company/${c.Id}/general`)}
            logo={c.Logo}
            defaultLogo={<CompanyLogo />}
          />
        </Grid>
      ));
    }
    if (selectedOption === 1 && companies.length === 0) {
      return <Box padding="15px">{t(COMPANY__NO_COMPANIES_YET)}</Box>;
    }
    if (selectedOption === 1 && companies.length !== 0) {
      return <Box padding="15px">{t(COMPANY__NO_COMPANIES_MATCH_SEARCH)}</Box>;
    }
    return <Box />;
  }, [companies, currentPageCompanies, navigate, t, selectedOption]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    if (searchValue) {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      let filteredEntries = [];
      if (selectedOption === 1) {
        filteredEntries = companies.filter((companyEntry: ICompanyGridDetails) => {
          return Object.keys(companyEntry).some((field: string) => {
            return (
              searchRegex.test(companyEntry[field]) ||
              searchRegex.test(getFormattedCompanyAddress(companyEntry.Address))
            );
          });
        });
      }
      setSearchMatchCompanies(filteredEntries);
    } else {
      setSearchMatchCompanies(selectedOption === 1 ? companies : []);
    }
  };

  const handlePageChange = (e: any, value: number) => {
    setCurrentPage(value);
  };

  const handleOptionChange = (value: number) => {
    setSelectedOption(value);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', marginBottom: '14px' }}>
        <CompanyAddNewItemButton
          text={`+ ${t(COMPANIES__ADD_NEW_COMPANY)}`}
          onClick={() => navigate('/company/new/general')}
        />
        <Box
          sx={{
            width: '300px',
            alignContent: 'center',
          }}
        >
          <UPInput
            value={searchText}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              requestSearch(event.target.value);
            }}
            label=""
            placeholder={`${t(GENERAL__SEARCH)}...`}
            icon="search"
          />
        </Box>
        <ToggleContainer>
          <UPToggle
            optionOne={t(COMPANY__CLIENTS_TOGGLE_OPTION)}
            optionTwo={t(COMPANY__ONBOARDING_TOGGLE_OPTION)}
            selectedOption={selectedOption}
            onChange={handleOptionChange}
          />
        </ToggleContainer>
      </Box>
      <Grid container spacing="10px" width="100%" marginTop="1px">
        {displayCompanies()}
      </Grid>
      {searchMatchCompanies.length > 0 && (
        <UPPagination
          itemsPerPage={itemsPerPage}
          onChange={e => {
            setItemsPerPage(toNumber(e.target.value));
            setCurrentPage(1);
          }}
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default Companies;
