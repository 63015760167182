import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { UPDropdownItem } from 'components/dropdown';
import { CoefficientsPerWorkerClassOptions } from 'constants/CoefficientsPerWorkerclass';
import { CompanyRole } from 'constants/CompanyRole';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getParitairComiteSelectItemsByCompanyAction } from 'store/actions/paritair-comite-actions';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { paritairComiteByCompanySelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import {
  COMPANY_PAYROLL__ADD_NEW_WORKCLASS_EXEMPTION,
  COMPANY_PAYROLL__DELETE_WORKERCLASS_EXEMPTION,
  COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  COMPANY_PAYROLL__WAGE_EXEMPTION,
  COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO,
  COMPANY_PAYROLL__WORKERCLASS,
  GENERAL__SELECT,
} from 'translations/constants';
import { WorkerclassExemptionsGridProps } from '../types';
import DataGridEditInputCell from './DataGridEditInputCell';
import { UPDataGridContainer } from './styles';
import { arrayToString, findDuplicates } from './helpers';

const WorkerclassExemptionsGrid = (props: WorkerclassExemptionsGridProps): JSX.Element => {
  const { workerclassExemptions, setWorkerclassExemptions, setErrorMessage } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const userIsCompanyManager = useAppSelector(profileSelector).role === CompanyRole.COMPANY_MANAGER;
  const paritairComites = useAppSelector(paritairComiteByCompanySelectItemsSelector);
  const { companyId } = useParams();
  const [, setRowOnHover] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();
  const [rowIsUpdated, setRowIsUpdated] = useState<boolean>(true);
  const workerclassOptions = CoefficientsPerWorkerClassOptions();

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsByCompanyAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (rowIsUpdated) {
      const items = workerclassExemptions.map(w => ({ keyId: w.workerclass, jointCommissionId: w.paritairComiteId }));

      const duplicates = findDuplicates(items);

      if (duplicates.length > 0) {
        setErrorMessage(
          `${t(COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION)} ${t(COMPANY_PAYROLL__WORKERCLASS)}: ${arrayToString(
            duplicates.map(w => ({
              keyId: w.keyId,
              jointCommissionId: paritairComites?.find(item => item.value === w.jointCommissionId)?.label,
            })),
          )}`,
        );
      } else if (workerclassExemptions.some(e => toNumber(e.wageExemption) <= 0)) {
        setErrorMessage(t(COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO));
      } else {
        setErrorMessage('');
      }

      setRowIsUpdated(false);
    }
  }, [workerclassExemptions, workerclassOptions, rowIsUpdated, paritairComites, setErrorMessage, t]);

  const getWorkerclass = useCallback(
    workerclassId => {
      const coefficient = workerclassOptions.find(item => item.value === workerclassId);
      return coefficient ? coefficient.label : <span>{t(GENERAL__SELECT)}</span>;
    },
    [t, workerclassOptions],
  );

  const onAddNewWorkerclassWageExemption = useCallback(() => {
    setWorkerclassExemptions([
      ...workerclassExemptions,
      { id: Math.random(), functionId: '', workerclass: '', wageExemption: 0, paritairComiteId: '' },
    ]);
  }, [workerclassExemptions, setWorkerclassExemptions]);

  const updateWorkerclassExemption = useCallback(
    (id, field, value) => {
      let workerclassExemption = workerclassExemptions.find(w => w.id === id);
      const index = workerclassExemptions.indexOf(workerclassExemption);
      workerclassExemption = { ...workerclassExemption, [field]: value };
      const updatedList = [
        ...workerclassExemptions.slice(0, index),
        workerclassExemption,
        ...workerclassExemptions.slice(index + 1, workerclassExemptions.length),
      ];
      setWorkerclassExemptions(updatedList);
      setRowIsUpdated(true);
      return workerclassExemption;
    },
    [workerclassExemptions, setWorkerclassExemptions],
  );

  const renderWorkerclassEditInputCell = useCallback(
    (params: any) => {
      /* eslint-disable react/jsx-props-no-spreading */
      return <DataGridEditInputCell {...params} dropdownOptions={workerclassOptions} />;
    },
    [workerclassOptions],
  );

  const onDeleteWageExemption = useCallback(() => {
    setWorkerclassExemptions([...workerclassExemptions.filter(c => c.id !== selectedRow)]);
    setSelectedRow(null);
    setRowIsUpdated(true);
  }, [selectedRow, setWorkerclassExemptions, workerclassExemptions]);

  const workerclassColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'workerclass',
      headerName: t(COMPANY_PAYROLL__WORKERCLASS),
      width: 250,
      editable: !userIsCompanyManager,
      renderCell: params => {
        return getWorkerclass(params.row.workerclass);
      },
      renderEditCell: renderWorkerclassEditInputCell,
      valueSetter: params => {
        const updatedWorkerclassExemption = updateWorkerclassExemption(params.row.id, 'workerclass', params.value);
        return updatedWorkerclassExemption;
      },
      sortable: false,
    },
    {
      field: 'paritairComiteId',
      headerName: t(COMPANY_PAYROLL__PARITAIR_COMITE),
      width: 200,
      renderCell: params => {
        return paritairComites?.find(item => item.value === params.row.paritairComiteId)?.label;
      },
      renderEditCell: params => {
        const found = paritairComites?.find((item: UPDropdownItem) => item.value === params.row.paritairComiteId);
        return (
          <DataGridEditInputCell
            {...params}
            value={found?.value}
            dropdownOptions={paritairComites}
            category="workerclassExemptions"
          />
        );
      },
      valueSetter: params => {
        const updatedWorkerclassExemption = updateWorkerclassExemption(params.row.id, 'paritairComiteId', params.value);
        return updatedWorkerclassExemption;
      },
      editable: !userIsCompanyManager,
      sortable: false,
    },
    {
      field: 'wageExemption',
      headerName: t(COMPANY_PAYROLL__WAGE_EXEMPTION),
      width: 200,
      type: 'number',
      editable: !userIsCompanyManager,
      valueSetter: params => {
        const updatedWorkerclassExemption = updateWorkerclassExemption(params.row.id, 'wageExemption', params.value);
        return updatedWorkerclassExemption;
      },
      valueFormatter: params => {
        const value = params.value as number;
        const decimals = value.toString().split('.')[1];
        if (decimals?.length > 4) {
          return value.toFixed(4);
        }
        return value;
      },
      sortable: false,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          marginTop: '32px',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ marginBottom: '16px', marginRight: '16px' }}>
          <UPButton
            text={`+ ${t(COMPANY_PAYROLL__ADD_NEW_WORKCLASS_EXEMPTION)}`}
            onClick={onAddNewWorkerclassWageExemption}
            disabled={userIsCompanyManager}
          />
        </Box>
        <Box sx={{ marginBottom: '16px' }}>
          <UPButton
            text={t(COMPANY_PAYROLL__DELETE_WORKERCLASS_EXEMPTION)}
            onClick={onDeleteWageExemption}
            disabled={userIsCompanyManager}
            outlined
          />
        </Box>
      </Box>
      <UPDataGridContainer>
        <UPDataGrid
          rows={workerclassExemptions}
          columns={workerclassColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selection: any) => setSelectedRow(selection[0])}
          withCheckboxes={false}
          hideFooter
        />
      </UPDataGridContainer>
    </Box>
  );
};
export default WorkerclassExemptionsGrid;
