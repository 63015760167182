import { getWeekDaysByWeekNumber } from 'modules/contracts/helpers';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getCostCentersAction, getCostCentersDropdownItemsAction } from 'store/actions/company-cost-centers-actions';
import { getFunctionsAsDropdownItemsAction } from 'store/actions/company-functions-actions';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { costCentersDropdownItemsSelector, costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import { functionsDropdownItemsSelector } from 'store/selectors/company-functions-selectors';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';
import { getWeekDaysAbbreviations } from 'utils/helpers';

const useContractModalDropdownData = (
  companyId: number,
  isEmployee: boolean,
  week: number,
  weekDate: Date,
  prefilledDates?: number[],
) => {
  const dispatch = useAppDispatch();

  const functions = useAppSelector(functionsDropdownItemsSelector);
  const costCenters = useAppSelector(costCentersDropdownItemsSelector);
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const companyEmployees = useAppSelector(employeesWithWorkerclassSelector);
  const costCentersWithDetails = useAppSelector(costCentersSelector);
  const weekDaysNames = getWeekDaysAbbreviations();
  const weekDays = getWeekDaysByWeekNumber(week, weekDaysNames, weekDate, prefilledDates);

  useEffect(() => {
    if (!isEmployee) {
      dispatch(getEmployeesWithWorkerclass(companyId));
      return;
    }
    dispatch(getLocationsDropdownItemsAction({ companyId: +companyId }));
    dispatch(getCostCentersDropdownItemsAction({ companyId: +companyId }));
    dispatch(getCostCentersAction(companyId));
    dispatch(getFunctionsAsDropdownItemsAction(companyId));
  }, [companyId, dispatch, isEmployee]);

  return {
    functions,
    costCenters,
    companyEmployees,
    locationsDropdownItems,
    costCentersWithDetails,
    weekDays,
  };
};

export default useContractModalDropdownData;
