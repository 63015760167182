import { UPDropdownItem } from 'components/dropdown';

const BREAK_INTERVAL_HOUR_OPTIONS: UPDropdownItem[] = [
  { value: '2', label: '00:15' },
  { value: '3', label: '00:30' },
  { value: '4', label: '00:45' },
  { value: '5', label: '01:00' },
  { value: '6', label: '01:15' },
  { value: '7', label: '01:30' },
  { value: '8', label: '01:45' },
  { value: '9', label: '02:00' },
  { value: '10', label: '02:15' },
  { value: '11', label: '02:30' },
  { value: '12', label: '02:45' },
  { value: '13', label: '03:00' },
  { value: '14', label: '03:15' },
  { value: '15', label: '03:30' },
  { value: '16', label: '03:45' },
  { value: '17', label: '04:00' },
  { value: '18', label: '04:15' },
  { value: '19', label: '04:30' },
  { value: '20', label: '04:45' },
  { value: '21', label: '05:00' },
  { value: '22', label: '05:15' },
  { value: '23', label: '05:30' },
  { value: '24', label: '05:45' },
];

export default BREAK_INTERVAL_HOUR_OPTIONS;
