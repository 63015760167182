import { Checkbox } from '@mui/material';
import { CompanyRole } from 'constants/CompanyRole';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getContractsAction,
  getDimonaNumberAction,
  reopenConfirmationAction,
  setContractDateToCopyAction,
  toggleCopyModeAction,
} from 'store/actions/contract-actions';
import {
  contractDateToCopySelector,
  getDimonaNumberErrorSelector,
  getDimonaNumberSuccessSelector,
} from 'store/selectors/contracts-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import { ReactComponent as RoundedWarningCircle } from '../../../../assets/icons/RoundedWarningCircle.svg';
import ContractCardDetails from './components/ContractCardDetails';
import ContractCardHeader from './components/ContractCardHeader';
import ContractCardActions from './components/actions/ContractCardActions';
import { isDateInFuture, isDisabledForModification } from './helpers';
import {
  ContractCardContainer,
  ContractCardDimonaContainer,
  ContractCardItem,
  ContractCardOptions,
  DimonaNumber,
} from './styles';
import { ContractCardProps } from './types';

const ContractCard: React.FC<ContractCardProps> = ({
  card,
  onClickConfirm,
  isCopyEnabled,
  onDelete,
  onEdit,
  year,
  week,
  companyId,
  onChangeCardCheck,
  onFillSlot,
  filters,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { role } = useAppSelector(profileSelector);
  const dimonaError = useAppSelector(getDimonaNumberErrorSelector);
  const dimonaSuccess = useAppSelector(getDimonaNumberSuccessSelector);
  const contractDateToCopy = useAppSelector(contractDateToCopySelector);

  const [hoveredCardId, setHoveredCardId] = useState<number>(null);

  const dimonaNumber = useMemo(() => {
    if (card.dimonaId) {
      return card.dimonaId.toString();
    }
    if (dimonaSuccess && card.id === dimonaSuccess.contractId) {
      return dimonaSuccess.dimonaId.toString();
    }
    if (dimonaError && card.id === dimonaError.contractId) {
      return 'Error...';
    }
    return null;
  }, [card.dimonaId, card.id, dimonaSuccess, dimonaError]);

  const [isAdmin, isEmployee] = [role === CompanyRole.ADMIN, role === CompanyRole.EMPLOYEE];
  const isWeekly = !!card.weeklyId;
  const isDashboard = location.pathname === '/dashboard';
  const cannotReopenContract = !isAdmin && card.confirmHours;
  const isContractSlot = !card.employee;
  const isConfirmed = card.confirmHours;
  const isContractCardActive = card.editable && (!isEmployee || isAdmin);
  const areContractCardActionsShown =
    hoveredCardId === card.id || (isCopyEnabled && !!contractDateToCopy && contractDateToCopy.id === card.id);

  const onClickEdit = () => {
    const isDaily = !isWeekly;
    if (isDaily) {
      onEdit(card.id, isDaily);
      return;
    }
    onEdit(card.id, isDaily, card.weeklyId);
  };

  const enableCopyMode = () => {
    dispatch(toggleCopyModeAction());
    dispatch(setContractDateToCopyAction(card));
  };

  const disableCopyMode = () => {
    dispatch(toggleCopyModeAction());
    dispatch(setContractDateToCopyAction(null));
  };

  const onClickCopy = () => {
    if (!isCopyEnabled) {
      enableCopyMode();
      return;
    }
    disableCopyMode();
  };

  const onClickDelete = () => {
    onDelete([card.id]);
  };

  const onClickReopen = () => {
    dispatch(
      reopenConfirmationAction({
        companyId,
        contractDateId: card.id,
        year,
        weekNumber: week,
      }),
    ).then(() => {
      dispatch(
        getContractsAction({
          companyId,
          filter: filters,
        }),
      );
    });
  };

  const onMouseEnterEvent = () => {
    if (!isCopyEnabled) {
      setHoveredCardId(card.id);
    }
  };

  const onMouseLeaveEvent = () => {
    setHoveredCardId(null);
  };

  const fetchDimonaNumber = () => {
    dispatch(
      getDimonaNumberAction({
        companyId,
        contractId: card.id,
      }),
    );
  };

  return (
    <ContractCardContainer onMouseEnter={onMouseEnterEvent} onMouseLeave={onMouseLeaveEvent}>
      <ContractCardItem active={isContractCardActive} hover={hoveredCardId === card.id} confirmed={card.confirmHours}>
        <ContractCardHeader card={card} onChangeCardCheck={onChangeCardCheck} isEmployee={isEmployee} />
        <ContractCardDetails card={card} isWeekly={isWeekly} />
        {dimonaNumber && isAdmin && (
          <ContractCardDimonaContainer>
            <RoundedWarningCircle color="warning" fontSize="small" />
            <DimonaNumber>{dimonaNumber}</DimonaNumber>
          </ContractCardDimonaContainer>
        )}
      </ContractCardItem>
      {areContractCardActionsShown && (
        <ContractCardOptions>
          {!isEmployee && !isDashboard && (
            <Checkbox
              checked={card.checked}
              disabled={!isContractSlot && card.employee.inactive}
              onChange={() => {
                onMouseLeaveEvent();
                onChangeCardCheck(card.contractId);
              }}
              sx={{ position: 'absolute', top: -3, right: -3 }}
            />
          )}
          <ContractCardActions
            cardId={card.id}
            isCopyEnabled={isCopyEnabled}
            isConfirmed={isConfirmed}
            isContractSlot={isContractSlot}
            isDashboard={isDashboard}
            isDisabled={isDisabledForModification(role, isWeekly, isDateInFuture(card.date.toString(), card.startHour))}
            isEditable={card.editable}
            isEmployee={isEmployee}
            cannotReopenContract={cannotReopenContract}
            dimonaNumber={dimonaNumber}
            fetchDimonaNumber={fetchDimonaNumber}
            onClickConfirm={onClickConfirm}
            onClickCopy={onClickCopy}
            onClickDelete={onClickDelete}
            onClickEdit={onClickEdit}
            onClickFill={() => onFillSlot(card.id)}
            onClickReopen={onClickReopen}
          />
        </ContractCardOptions>
      )}
    </ContractCardContainer>
  );
};

export default ContractCard;
