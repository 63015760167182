export interface IContractFormFields {
  id: number;
  employeeIds: number[];
  workerclass: string;
  locationId: string;
  functionId: string;
  costCenterId: number;
  wage: string;
  workingLocation: string;
}

export enum ContractFormFields {
  EMPLOYEE_IDS = 'employeeIds',
  WORKERCLASS = 'workerclass',
  FUNCTION_ID = 'functionId',
  COST_CENTER_ID = 'costCenterId',
  LOCATION_ID = 'locationId',
  WAGE = 'wage',
  WORKING_LOCATION = 'workingLocation',
}
