import { Box, styled } from '@mui/material';

export const ContractCardContainer = styled(Box)(({ theme }) => ({
  width: '210px',
  padding: theme.spacing(1),
  position: 'relative',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
}));

export const ContractCardItem = styled(Box, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'hover' && prop !== 'confirmed',
})<{ active?: boolean; hover?: boolean; confirmed?: boolean }>(({ theme, active, hover, confirmed }) => ({
  background: (() => {
    if (active) {
      return 'white';
    }
    if (confirmed) {
      return theme.palette.primary.dark;
    }
    return theme.palette.info.main;
  })(),
  ...(active ? { border: '1px solid #DBEDE3' } : { border: '1px solid #FAFAFA' }),
  opacity: hover ? 0.5 : 1,
  transition: 'opacity 0.3s',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
}));

export const ContractCardDimonaContainer = styled(Box)(() => ({
  marginLeft: '30px',
  marginRight: '10px',
  position: 'absolute',
  marginTop: '-15px',
  display: 'flex',
}));

export const DimonaNumber = styled(Box)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.secondary.main,
}));

export const ContractCardOptions = styled(Box)(({ theme }) => ({
  background: 'rgba(219, 237, 227, 0.5)',
  position: 'absolute',
  borderRadius: theme.spacing(1),
  top: theme.spacing(1),
  left: theme.spacing(1),
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
}));
