import { UPDropdownItem } from 'components/dropdown';
import { RootState } from 'store';
import { ICompany, ICompanyGridDetails } from 'types/company';
import { ICompanyExternalReferences } from 'types/company/ICompanyExternalReferences';

export const companiesSelector = (state: RootState): ICompanyGridDetails[] => state.company.companies;
export const companiesErrorSelector = (state: RootState): boolean => state.company.companiesError;

export const companySelector = (state: RootState): ICompany => state.company.company;
export const companyErrorSelector = (state: RootState): boolean => state.company.companyError;

export const updateCompanySuccessSelector = (state: RootState): boolean => state.company.updateCompanySuccess;
export const updateCompanyErrorSelector = (state: RootState): boolean => state.company.updateCompanyError;

export const saveCompanySuccessSelector = (state: RootState): boolean => state.company.saveCompanySuccess;
export const saveCompanyErrorSelector = (state: RootState): boolean => state.company.saveCompanyError;
export const companyIdSelector = (state: RootState): number => state.company.companyId;

export const getParentCompaniesSelector = (state: RootState): UPDropdownItem[] => state.company.parentCompanies;

export const childCompaniesSelector = (state: RootState): ICompanyGridDetails[] => state.company.childCompanies;
export const childCompaniesErrorSelector = (state: RootState): boolean => state.company.childCompaniesError;

export const isParentCompanySelector = (state: RootState): boolean => state.company.isParentCompany;

export const salaryEngineReferenceIdSelector = (state: RootState): ICompanyExternalReferences =>
  state.company.externalReferences;

export const companiesDropdownItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.company.companiesDropdownItems;

export const userCompaniesDropdownItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.company.userCompaniesDropdownItems;

export const companyErrorMessageSelector = (state: RootState): string => state.company.companyErrorMessage;

export const companyLoadingSelector = (state: RootState): boolean => state.company.loading;

export const companyExternalReferenceLoadingSelector = (state: RootState): boolean =>
  state.company.externalReferenceLoading;

export const defaultCompanyBreakSelector = (state: RootState): string => state.company.defaultCompanyBreakTime;
