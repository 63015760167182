/* eslint-disable react/jsx-props-no-spreading */
import { Avatar, Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef } from '@mui/x-data-grid';
import UPCheckbox from 'components/app-container/checkbox-with-label';
import BottomGreenBar from 'components/bottom-green-bar';
import { UPButtonStyledWithNoPadding } from 'components/button/styles';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { includes, isEqual, toNumber } from 'lodash';
import DataGridEditInputCell from 'modules/companies/payroll/wage-exemptions/DataGridEditInputCell';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  confirmContract,
  getContractsInListViewAction,
  multipleConfirmContractsAction,
  setContractsMultipleConfirmationSucces,
  validateContractErrorMessageAction,
  validateContractErrorMessageExtraInfoAction,
  validateContractSuccessAction,
  validateHoursOnConfirmContractAction,
  validateHoursOnMultipleConfirmContractAction,
} from 'store/actions/contract-actions';
import { costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import {
  validateContractErrorMessageSelector,
  validateContractExtraMessageSelector,
  validateContractSuccessSelector,
} from 'store/selectors/contract-selectors';
import {
  contractsMultipleConfirmationResponseSelector,
  contractsMultipleConfirmationSuccessSelector,
  getContractsListViewSelector,
} from 'store/selectors/contracts-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import { getWeekDaysAbbreviations } from 'utils/helpers';
import { ContractListViewTypes } from 'constants/ContractListViewTypes';
import { ContractPageSize } from 'constants/ContractPageSize';
import {
  COMPANY_PAYROLL__EMPLOYEE,
  CONTRACTS__ARE_YOU_SURE,
  CONTRACTS__CONFIRM_SELECTED,
  CONTRACTS__COST_CENTER,
  CONTRACTS__DATE,
  CONTRACTS__DELETE_SELECTED,
  CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_WORK,
  CONTRACTS__FUNCTION,
  CONTRACTS__NO_SHOW,
  CONTRACTS__REASON_FOR_NO_SHOW,
  CONTRACTS__START_BREAK,
  CONTRACTS__START_END_WORK_ERROR,
  CONTRACTS__START_WORK,
  GENERAL__TOTAL,
} from 'translations/constants';
import { ReactComponent as ConfirmIcon } from '../../../assets/icons/ConfirmContract.svg';
import { ReactComponent as ConfirmContractGreenIcon } from '../../../assets/icons/ConfirmContractGreen.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/DeleteFull.svg';
import { ReactComponent as DeleteIconGreen } from '../../../assets/icons/DeleteGreen.svg';
import { ReactComponent as EditEmployeeAvatar } from '../../../assets/icons/EditEmployeeAvatar.svg';
import HOUR_OPTIONS from '../HourDropdownOptions';
import {
  CompareContractDateSorting,
  EmployeeNameComparator,
  calculateTotalTime,
  checkIfThereIsAnyError,
  filterCostCentersByLocation,
  generateContractDate,
  generateContractDateForMultiple,
  generateCostCenterName,
  generateErrorsObject,
  generateFormatedDate,
  generateListViewContractChecked,
  generateTotalHours,
  isAnyContractWeekly,
  totalHoursComparator,
} from '../helpers';
import { IContractData, IContractsListViewProps } from '../types';
import DataEditCellHours from './DataEditCellHours';
import NoShowNodal from './NoShowModal';

const ToBeConfirmedContractsView = (props: IContractsListViewProps): JSX.Element => {
  const {
    makeDeleteRequest,
    functionsList,
    costCentersList,
    currentWeek,
    currentYear,
    setSelectedContractsId,
    filters,
    companyId,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(profileSelector).role === CompanyRole.ADMIN;
  const contractIsValid = useAppSelector(validateContractSuccessSelector);
  const invalidContractError = useAppSelector(validateContractErrorMessageSelector);
  const contractValidationExtraInfo = useAppSelector(validateContractExtraMessageSelector);
  const multipleConfirmationResponse = useAppSelector(contractsMultipleConfirmationResponseSelector);
  const multipleConfirmationSuccess = useAppSelector(contractsMultipleConfirmationSuccessSelector);
  const companyCostCenters = useAppSelector(costCentersSelector);
  const contractData = useAppSelector(getContractsListViewSelector);
  const [contracts, setContracts] = useState<IContractData[]>(contractData);

  const weekDaysNames = useMemo(() => getWeekDaysAbbreviations(), []);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [totalTime, setTotalTime] = useState<string>('');
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [openConfirmDeleteWeeklyModal, setOpenConfirmDeleteWeeklyModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [openNoShowModal, setOpenNoShowModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<number[]>([]);
  const [errors, setErrors] = useState({});
  const [uiErrors, setUiErrors] = useState({});
  const [errorOnMultipleConfirmation, setErrorOnMultipleConfirmation] = useState(false);
  const [uiErrorOnMultipleConfirmation, setUiErrorOnMultipleConfirmation] = useState(false);
  const [contractToBeConfirmedId, setContractToBeConfirmedId] = useState<number>(null);
  const [contractToSendToBE, setContractToSendToBE] = useState<any>(null);
  const [contractsToSendToBE, setContractsToSendToBE] = useState<any[]>(null);
  const [hasClickedConfirm, setHasClickedConfirm] = useState(false);
  const [hasClickedMultipleConfirm, setHasClickedMultipleConfirm] = useState(false);
  const [canMultipleConfirm, setCanMultipleConfirm] = useState(false);
  const [changedContractsId, setChangedContractsIds] = useState<number[]>([]);
  const [checkedContractId, setCheckedContractId] = useState<number>(null);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [pageSize, setPageSize] = useState<number>(ContractPageSize.DefaultListView);

  filters.listViewType = ContractListViewTypes.ToBeConfirmed;

  const bottomGreenBarActions = useMemo(() => {
    if (isAdmin) {
      return [
        {
          content: t(CONTRACTS__CONFIRM_SELECTED),
          icon: <ConfirmIcon />,
          gtmEvent: 'contracts-list-view-confirm-selection',
        },

        {
          content: t(CONTRACTS__DELETE_SELECTED),
          icon: <DeleteIcon />,
        },
      ];
    }
    return [
      {
        content: t(CONTRACTS__CONFIRM_SELECTED),
        icon: <ConfirmIcon />,
        gtmEvent: 'contracts-list-view-confirm-selection',
      },
    ];
  }, [isAdmin, t]);

  const setPage = (newPage: number) => {
    filters.pageNumber = newPage;
    filters.pageSize = pageSize;
    filters.listViewType = ContractListViewTypes.ToBeConfirmed;
    dispatch(
      getContractsInListViewAction({
        companyId: toNumber(companyId),
        filter: filters,
      }),
    );
  };

  const onConfirmMultipleContracts = (ids: number[]) => {
    const auxContracts = contracts.filter((c: any) => includes(ids, c.id));
    const contractsForValidation = [];
    auxContracts.forEach((c: any) => {
      contractsForValidation.push(generateContractDateForMultiple(c));
    });
    setContractsToSendToBE(contractsForValidation);
    dispatch(
      validateHoursOnMultipleConfirmContractAction({
        contractsData: contractsForValidation,
        companyId: contractsForValidation[0].companyId,
      }),
    );

    setHasClickedMultipleConfirm(true);
  };

  const onCheckNoShow = (id: number) => {
    if (contracts.find((c: any) => c.id === id).noShow) {
      const auxContracts = contracts.map((c: any) => {
        if (c.id === id) {
          return {
            ...c,
            noShow: false,
            reasonForNoShow: '',
          };
        }
        return c;
      });
      setContracts(auxContracts);
    } else {
      setCheckedContractId(id);
      setOpenNoShowModal(true);
    }
  };

  const onDeleteContracts = (ids: number[]) => {
    const newContracts = contracts.filter((c: IContractData) => ids.includes(c.id));
    if (isAnyContractWeekly(newContracts)) setOpenConfirmDeleteWeeklyModal(true);
    else {
      setOpenConfirmDeleteModal(true);
    }
  };

  const onSaveReasonForNowShowing = (id: number, reason: string) => {
    const auxContracts = contracts.map((c: any) => {
      if (c.id === id) {
        return {
          ...c,
          noShow: true,
          reasonForNoShow: reason,
        };
      }
      return c;
    });

    setContracts(auxContracts);
    setCheckedContractId(null);
  };

  const onConfirmSingleContract = (id: number) => {
    const contract = contracts.find((c: any) => c.id === id);
    const contractDateObj = generateContractDate(contract);
    setContractToSendToBE(contractDateObj);
    if (!contract.noShow) {
      dispatch(validateHoursOnConfirmContractAction(contractDateObj)).then(() => {
        filters.listViewType = ContractListViewTypes.ToBeConfirmed;
        dispatch(
          getContractsInListViewAction({
            companyId: toNumber(contractDateObj.companyId),
            filter: filters,
          }),
        );
      });
    } else {
      dispatch(validateContractSuccessAction(true));
      dispatch(validateContractErrorMessageAction(''));
      dispatch(validateContractErrorMessageExtraInfoAction(''));
    }
  };

  const makeCallForMultipleConfirmation = useCallback(() => {
    if (!multipleConfirmationSuccess) {
      return;
    }
    if (multipleConfirmationResponse.length === 0) {
      if (!errorOnMultipleConfirmation && !uiErrorOnMultipleConfirmation) {
        if (hasClickedMultipleConfirm) {
          const contractsForBE = [];
          contractsToSendToBE.forEach(c => {
            contractsForBE.push(c.contractHourConfirmation);
          });
          dispatch(
            multipleConfirmContractsAction({
              contractsData: contractsForBE,
              companyId: contracts[0].companyId,
              year: currentYear,
              weekNumber: currentWeek,
            }),
          ).then(() => {
            dispatch(
              getContractsInListViewAction({
                companyId: toNumber(contracts[0].companyId),
                filter: filters,
              }),
            );
          });
          dispatch(setContractsMultipleConfirmationSucces(false));
          setContractsToSendToBE(null);
          setSelectedRows([]);
          setChangedContractsIds([]);
        }
        setHasClickedMultipleConfirm(false);
      }
    } else if (contractsToSendToBE) {
      setContractsToSendToBE([]);
      dispatch(setContractsMultipleConfirmationSucces(false));
    }
  }, [
    contracts,
    contractsToSendToBE,
    currentWeek,
    currentYear,
    dispatch,
    errorOnMultipleConfirmation,
    hasClickedMultipleConfirm,
    multipleConfirmationResponse.length,
    multipleConfirmationSuccess,
    uiErrorOnMultipleConfirmation,
    filters,
  ]);

  const updateHoursField = useCallback(
    (id, field, value) => {
      let auxContract = contracts.find((c: any) => c.id === id);

      if (!value) {
        if (field === 'startHour' || field === 'endHour') {
          setUiErrors(generateErrorsObject(uiErrors, id, true, t(CONTRACTS__START_END_WORK_ERROR)));
        } else if (auxContract.startHour && auxContract.endHour) {
          setUiErrors(generateErrorsObject(uiErrors, id, false, ''));
        }
      } else if (field === 'startHour' || field === 'endHour') {
        setUiErrors(generateErrorsObject(uiErrors, id, false, ''));
      }

      auxContract = {
        ...auxContract,
        [field]: value,
      };

      if (field === 'startHour') {
        const auxTime = generateTotalHours(value, auxContract.endHour, auxContract.startBreak, auxContract.endBreak);
        setChangedContractsIds([...changedContractsId, id]);
        auxContract = {
          ...auxContract,
          totalHours: auxTime.value,
          hours: auxTime.hours,
          minutes: auxTime.minutes,
        };
      }

      if (field === 'endHour') {
        const auxTime = generateTotalHours(auxContract.startHour, value, auxContract.startBreak, auxContract.endBreak);
        setChangedContractsIds([...changedContractsId, id]);
        auxContract = {
          ...auxContract,
          totalHours: auxTime.value,
          hours: auxTime.hours,
          minutes: auxTime.minutes,
        };
      }

      if (field === 'startBreak') {
        const auxTime = generateTotalHours(auxContract.startHour, auxContract.endHour, value, auxContract.endBreak);
        setChangedContractsIds([...changedContractsId, id]);
        auxContract = {
          ...auxContract,
          totalHours: auxTime.value,
          hours: auxTime.hours,
          minutes: auxTime.minutes,
        };
      }

      if (field === 'endBreak') {
        const auxTime = generateTotalHours(auxContract.startHour, auxContract.endHour, auxContract.startBreak, value);
        setChangedContractsIds([...changedContractsId, id]);
        auxContract = {
          ...auxContract,
          totalHours: auxTime.value,
          hours: auxTime.hours,
          minutes: auxTime.minutes,
        };
      }

      const updatedContracts = contracts.map((c: any) => {
        if (c.id === id) return auxContract;
        return c;
      });
      setContracts(updatedContracts);
      if (auxContract.startHour && auxContract.startBreak && auxContract.endBreak && auxContract.endHour) {
        setUiErrors(generateErrorsObject(uiErrors, id, false, ''));

        if (uiErrors[id] && uiErrors[id].message !== t(CONTRACTS__START_END_WORK_ERROR)) {
          setUiErrors(generateErrorsObject(uiErrors, id, false, ''));
        }
      }
      return auxContract;
    },

    [contracts, uiErrors, setContracts, changedContractsId, t],
  );

  const updateNormalField = useCallback(
    (id, field, value) => {
      let auxContract = contracts.find((c: IContractData) => c.id === id);
      if (field === 'functionId') {
        auxContract = {
          ...auxContract,
          [field]: value,
          employee: {
            ...auxContract.employee,
            functionName: generateCostCenterName(value, functionsList),
          },
        };
      } else {
        auxContract = {
          ...auxContract,
          [field]: value,
        };
      }
      const updatedContracts = contracts.map((c: IContractData) => {
        if (c.id === id) return auxContract;
        return c;
      });
      setContracts(updatedContracts);
      return auxContract;
    },
    [contracts, functionsList, setContracts],
  );

  useEffect(() => {
    if (rowOnHover !== contractToBeConfirmedId) setHasClickedConfirm(false);
  }, [contractToBeConfirmedId, rowOnHover]);

  useEffect(() => {
    if (contractData.length > 0) {
      setContracts(contractData);
    } else {
      setContracts([]);
    }
  }, [contractData]);

  useEffect(() => {
    const auxErr = { ...errors };
    selectedRows.forEach(row => {
      delete auxErr[row];
    });
    multipleConfirmationResponse.forEach((r: any) => {
      auxErr[r.rowId] = {
        value: true,
        message: t(r.messageTranslationKey),
      };
    });

    if (!isEqual(auxErr, errors)) setErrors(auxErr);
  }, [multipleConfirmationResponse, errors, t, selectedRows]);

  useEffect(() => {
    if (canMultipleConfirm) {
      makeCallForMultipleConfirmation();
      setCanMultipleConfirm(false);
    }
  }, [makeCallForMultipleConfirmation, canMultipleConfirm]);

  useEffect(() => {
    if (hasClickedMultipleConfirm) {
      setTimeout(() => {
        setCanMultipleConfirm(true);
      }, 2000);
    }
  }, [
    multipleConfirmationResponse,
    hasClickedMultipleConfirm,
    contracts,
    dispatch,
    multipleConfirmationSuccess,
    errorOnMultipleConfirmation,
    uiErrorOnMultipleConfirmation,
    contractsToSendToBE,
    makeCallForMultipleConfirmation,
    currentWeek,
  ]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const auxTotal = calculateTotalTime(generateListViewContractChecked(contracts, selectedRows));
      setTotalTime(auxTotal);
    }
  }, [contracts, selectedRows]);

  useEffect(() => {
    if (!hasClickedConfirm) return;

    if (!contractIsValid) {
      const auxErr = generateErrorsObject(
        errors,
        contractToBeConfirmedId,
        true,
        `${t(invalidContractError)} ${contractValidationExtraInfo}`,
      );
      if (!isEqual(auxErr, errors) && invalidContractError) setErrors(auxErr);
    } else {
      const auxErr = generateErrorsObject(errors, contractToBeConfirmedId, false, '');
      if (
        isEqual(auxErr, errors) === false ||
        (!auxErr[contractToBeConfirmedId].value && !errors[contractToBeConfirmedId].value)
      ) {
        setErrors(auxErr);
        setContractToBeConfirmedId(null);
        if (contractToSendToBE) {
          dispatch(
            confirmContract({
              confirmHours: contractToSendToBE.confirmHours,
              companyId: contractToSendToBE.companyId,
              confirmCostDeclarations: [],
            }),
          ).then(() => {
            dispatch(
              getContractsInListViewAction({
                companyId: toNumber(contractToSendToBE.companyId),
                filter: filters,
              }),
            );
          });
          dispatch(validateContractSuccessAction(false));
          setContractToSendToBE(null);
          setErrors({});
          setChangedContractsIds([]);
        }
      }
    }
  }, [
    contractIsValid,
    contractToBeConfirmedId,
    contractToSendToBE,
    currentWeek,
    currentYear,
    dispatch,
    errors,
    hasClickedConfirm,
    invalidContractError,
    contractValidationExtraInfo,
    t,
    filters,
  ]);

  useEffect(() => {
    setSelectedContractsId(selectedRows);
  }, [selectedRows, setSelectedContractsId]);

  useEffect(() => {
    setUiErrorOnMultipleConfirmation(checkIfThereIsAnyError(contracts, uiErrors));
  }, [contracts, uiErrorOnMultipleConfirmation, uiErrors]);

  useEffect(() => {
    setErrorOnMultipleConfirmation(checkIfThereIsAnyError(contracts, errors));
  }, [contracts, errorOnMultipleConfirmation, errors]);

  const contractsColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'contractId', headerName: '', hide: true },
    { field: 'companyId', headerName: '', hide: true },
    {
      field: 'employeeAvatar',
      headerName: '',
      width: 65,
      sortable: false,
      renderCell: params => {
        if (params.value === null) {
          return <EditEmployeeAvatar />;
        }
        return (
          <div>
            <Avatar src={params.value} />
          </div>
        );
      },
    },
    {
      field: 'employeeFullName',
      headerName: t(COMPANY_PAYROLL__EMPLOYEE),
      width: 200,
      sortable: true,
      renderCell: params => {
        return `${params.row.employee.firstName} ${params.row.employee.lastName}`;
      },
      sortComparator: (v1, v2, param1, param2) => EmployeeNameComparator(v1, v2, param1, param2),
    },
    {
      field: 'formatedDate',
      headerName: t(CONTRACTS__DATE),
      width: 90,
      editable: false,
      align: 'center',
      sortable: true,
      renderCell: params => {
        return generateFormatedDate(weekDaysNames, params.row.date);
      },
      sortComparator: (v1, v2, param1, param2) => {
        return CompareContractDateSorting(param1, param2);
      },
    },
    {
      field: 'startHour',
      headerName: t(CONTRACTS__START_WORK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: true,
      renderCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="startHour"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      renderEditCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="startHour"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'startHour', params.value);
        return updatedContract;
      },
    },
    {
      field: 'startBreak',
      headerName: t(CONTRACTS__START_BREAK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: true,
      renderCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="startBreak"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      renderEditCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="startBreak"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'startBreak', params.value);
        return updatedContract;
      },
    },
    {
      field: 'endBreak',
      headerName: t(CONTRACTS__END_BREAK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: true,
      renderCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="endBreak"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      renderEditCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="endBreak"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'endBreak', params.value);
        return updatedContract;
      },
    },
    {
      field: 'endHour',
      headerName: t(CONTRACTS__END_WORK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: true,
      renderCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="endHour"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      renderEditCell: params => {
        return (
          <DataEditCellHours
            {...params}
            value={params.value}
            dropdownOptions={HOUR_OPTIONS}
            type="endHour"
            errorMessage={uiErrors[params.id]?.value || errors[params.id]?.value || false}
          />
        );
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'endHour', params.value);
        return updatedContract;
      },
    },
    {
      field: 'totalHours',
      headerName: t(GENERAL__TOTAL),
      width: 100,
      sortComparator: totalHoursComparator,
      align: 'center',
      renderCell: params => {
        const totals = generateTotalHours(
          params.row.startHour,
          params.row.endHour,
          params.row.startBreak,
          params.row.endBreak,
        );

        if (includes(changedContractsId, toNumber(params.id))) {
          return <div style={{ fontWeight: 'bold' }}>{totals.value}</div>;
        }
        return <div>{totals.value}</div>;
      },
    },
    {
      field: 'functionId',
      headerName: t(CONTRACTS__FUNCTION),
      width: 166,
      sortable: true,
      editable: false,
      renderCell: params => {
        return generateCostCenterName(params.row.functionId, functionsList);
      },
    },
    {
      field: 'costCenterId',
      headerName: t(CONTRACTS__COST_CENTER),
      width: 166,
      sortable: true,
      editable: true,

      renderCell: params => {
        return generateCostCenterName(params.row.costCenterId, costCentersList);
      },
      renderEditCell: params => {
        let items = costCentersList;
        if (params.row.locationId !== 0) {
          items = filterCostCentersByLocation(companyCostCenters, params.row.locationId);
        }

        const found = items.find((costCenter: UPDropdownItem) => params.value === costCenter?.value);
        return <DataGridEditInputCell {...params} value={found?.value} dropdownOptions={items} category="contracts" />;
      },
      valueSetter: params => {
        const updatedContract = updateNormalField(params.row.id, 'costCenterId', params.value);
        return updatedContract;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 260,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UPCheckbox
                label={`${t(CONTRACTS__NO_SHOW)}`}
                value={contracts.find((c: any) => c.id === toNumber(params.id)).noShow}
                handleChange={() => onCheckNoShow(toNumber(params.id))}
              />
              <Tooltip title={uiErrors[params.id]?.message || errors[params.id]?.message || ''} arrow>
                <span>
                  <UPButtonStyledWithNoPadding
                    disabled={
                      // eslint-disable-next-line no-unneeded-ternary
                      (errors[params.id] && errors[params.id].message === t(CONTRACTS__START_END_WORK_ERROR)) ||
                      (uiErrors[params.id] && uiErrors[params.id].message === t(CONTRACTS__START_END_WORK_ERROR))
                        ? true
                        : false
                    }
                    onClick={() => {
                      onConfirmSingleContract(toNumber(params.id));
                      setContractToBeConfirmedId(toNumber(params.id));
                      setHasClickedConfirm(true);
                    }}
                    data-gtm-event="contracts-list-view-confirm-individual"
                  >
                    <ConfirmContractGreenIcon />
                  </UPButtonStyledWithNoPadding>
                </span>
              </Tooltip>
              {isAdmin && (
                <UPButtonStyledWithNoPadding
                  onClick={() => {
                    setRowToDelete([toNumber(params.id)]);
                    onDeleteContracts([toNumber(params.id)]);
                  }}
                >
                  <DeleteIconGreen />
                </UPButtonStyledWithNoPadding>
              )}
            </div>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box>
      <UPDataGridContainer sx={{ height: '100vh' }}>
        <UPDataGrid
          rows={contracts}
          columns={contractsColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selectedItem: any) => {
            setSelectedRows(selectedItem);
            setClearAll(false);
          }}
          withCheckboxes
          getRowId={r => r.id}
          selectionModel={clearAll ? [] : selectedRows}
          disableSelectionOnClick
          onPageSizeChange={newSize => setPageSize(newSize)}
          onPageChange={newPage => setPage(newPage)}
        />
      </UPDataGridContainer>
      {selectedRows?.length > 0 && (
        <BottomGreenBar
          numberOfItems={selectedRows.length}
          actions={bottomGreenBarActions}
          onClearAll={() => {
            setSelectedRows([]);
            setClearAll(true);
          }}
          onDelete={() => onDeleteContracts(selectedRows)}
          onCopy={() => onConfirmMultipleContracts(selectedRows)}
          error={errorOnMultipleConfirmation || uiErrorOnMultipleConfirmation}
          total={totalTime}
        />
      )}
      {openConfirmDeleteWeeklyModal && (
        <DeleteModal
          open={openConfirmDeleteWeeklyModal}
          content={t(CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING)}
          onClose={() => {
            setOpenConfirmDeleteWeeklyModal(false);
          }}
          onDelete={() => {
            setOpenConfirmDeleteModal(true);
          }}
        />
      )}
      {openConfirmDeleteModal && (
        <DeleteModal
          open={openConfirmDeleteModal}
          content={t(CONTRACTS__ARE_YOU_SURE)}
          onClose={() => {
            setOpenConfirmDeleteModal(false);
          }}
          onDelete={() => {
            makeDeleteRequest(
              rowToDelete.length === 1 ? rowToDelete : selectedRows,
              ContractListViewTypes.ToBeConfirmed,
            );
            setRowToDelete([]);
          }}
        />
      )}

      {openNoShowModal && (
        <NoShowNodal
          id={checkedContractId}
          open={openNoShowModal}
          onClose={() => setOpenNoShowModal(false)}
          content={t(CONTRACTS__REASON_FOR_NO_SHOW)}
          onSave={onSaveReasonForNowShowing}
        />
      )}
    </Box>
  );
};

export default ToBeConfirmedContractsView;
