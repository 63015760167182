import UPSearchInput from 'components/input-with-search';
import { CompanyAvailabilityAvailabilitySearchProps } from 'modules/availabilities/company-availability/types';
import { useTranslation } from 'react-i18next';
import { EMPLOYEES__SEARCH_EMPLOYEE_NAME } from 'translations/constants';
import { EmployeeSearchContainer } from './styles';

const CompanyAvailabilityEmployeeSearch = (props: CompanyAvailabilityAvailabilitySearchProps): JSX.Element => {
  const { searchQuery, onChangeSearchQuery, onSearch } = props;

  const [t] = useTranslation();

  return (
    <EmployeeSearchContainer>
      <UPSearchInput
        value={searchQuery}
        onChange={onChangeSearchQuery}
        onSearch={onSearch}
        label=""
        placeholder={t(EMPLOYEES__SEARCH_EMPLOYEE_NAME)}
      />
    </EmployeeSearchContainer>
  );
};

export default CompanyAvailabilityEmployeeSearch;
