import { Button, styled } from '@mui/material';

export const LinkButton = styled(Button)(() => ({
  textTransform: 'none',
  color: 'inherit',
  padding: 0,
  minWidth: 'auto',
  '&:hover': {
    color: '#003F09',
    textDecoration: 'underline',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
  },
}));
