import { Box, Modal, Typography, styled } from '@mui/material';

export const AddContractModalContainer = styled(Box)(() => ({
  width: '100%',
  background: 'white',
  padding: '5px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
  maxHeight: '650px',
  overflowX: 'auto',
}));

export const ModalWrapperContract = styled(Modal)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '850px',
  maxHeight: '650px',
  height: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.between(768, 'lg')]: {
    width: '720px',
  },
  [theme.breakpoints.down(768)]: {
    width: '96%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    maxHeight: '100%',
  },
}));

export const ModalHeaderContract = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  padding: '20px 32px',
  [theme.breakpoints.down(768)]: {
    padding: '5px',
    position: 'unset',
  },
}));

export const ModalTitleContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
}));

export const ModalFooter = styled(Box)(() => ({
  background: 'white',
  padding: '12px 0px',
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',
  position: 'relative',
  borderRadius: '0px 0px 8px 8px',
}));

export const ModalCloseIconStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const RowContainerForNameAndContractType = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '24px',
  [theme.breakpoints.down(768)]: {
    flexDirection: 'column',
  },
}));

export const NameInputStyled = styled(Box)<{ expanded?: boolean }>(({ theme, expanded }) => ({
  minWidth: '280px',
  display: 'inline-block',
  marginRight: '20px',
  width: expanded ? '100%' : '50%',
  [theme.breakpoints.down(768)]: {
    minWidth: 'unset',
    width: '100%',
    paddingBottom: '10px',
    marginRight: 'unset',
  },
}));

export const ModalContentContainer = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  background: 'rgb(250, 250, 250)',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  paddingTop: '0px',
  paddingRight: '0px',
  height: '380px',
  [theme.breakpoints.down(768)]: {
    padding: '5px',
    overflowY: 'auto',
  },
}));

export const ModalButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down(768)]: {
    flexDirection: 'column',
  },
}));
