import { styled, Box } from '@mui/system';

export const UPPaginationStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '10px',
  [theme.breakpoints.down(415)]: {
    flexDirection: 'column',
  },
}));

export const CustomizeSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '10px',
  [theme.breakpoints.down(415)]: {
    padding: '0px',
  },
}));
