import { Box, styled } from '@mui/material';

export const ToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
}));

export const ToggleOption = styled(Box, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ isSelected, theme }) => ({
  ...(isSelected ? { color: theme.palette.primary.main, fontWeight: 'bold' } : { color: theme.palette.secondary.main }),
  lineHeight: '24px',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export const ToggleSeparator = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'default',
  padding: '0px 16px',
}));
