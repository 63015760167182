import { styled, Box, InputLabel, Modal, Typography } from '@mui/material';
import UPButton from 'components/button';

export const PayrollInputLabel = styled(InputLabel)(() => ({
  fontWeight: 600,
}));

export const PayrollAddNewItemButton = styled(UPButton)(() => ({
  margin: '16px 0px',
}));

export const PayrollEditFunctionModal = styled(Modal)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  widht: '400px',
  maxHeight: '500px',
  height: 'auto',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
}));

export const PayrollEditFunctionModalContainer = styled(Box)(() => ({
  width: '100%',
  background: 'white',
  padding: '32px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
}));

export const PayrollEditFunctionModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
}));

export const PayrollEditFunctionModalInputBox = styled(Box)(() => ({
  marginBottom: '26px',
  reactColorful: {
    height: '100px',
  },
}));

export const PayrollEditFunctionModalCloseIcon = styled(Box)(() => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const PayrollEditFunctionModalInputContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const PayrollEditFunctionModalColumn = styled(Box)(() => ({
  flex: '1 1 100%',
}));

export const PayrollEditFunctionModalActionContainer = styled(Box)(() => ({
  marginTop: '32px',
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
}));

export const PayrollEditFunctionModalActionButton = styled(UPButton)(() => ({
  width: '100px',
}));

export const CoefficientPerWorkerClassGridContainer = styled(Box)(() => ({
  height: '300px',
  width: '100%',
}));

export const WageComponentsModalStyled = styled(Modal)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  zIndex: '2',
  maxHeight: '600px',
  height: 'auto',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.up('sm')]: {
    width: '550px',
  },

  [theme.breakpoints.down(611)]: {
    width: '90%',
  },
}));

export const ModalContainer = styled(Box)(() => ({
  width: '100%',
  background: 'white',
  position: 'relative',
  padding: '20px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
  maxHeight: '650px',
  overflowX: 'auto',
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  background: 'white',
  padding: '5px 5px 0px 5px',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
}));

export const ModalCloseIcon = styled(Box)(() => ({
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const WageSetupDataGridContainer = styled(Box)(() => ({
  height: '60vh',
  width: '70vw',
}));

export const AddWageComponentButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '16px',
}));

export const ErrorText = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));

export const PayrollContainer = styled(Box)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down(1620)]: {
    width: '50%',
  },
  [theme.breakpoints.down(1360)]: {
    width: '60%',
  },
  [theme.breakpoints.down(1200)]: {
    width: '80%',
  },
  [theme.breakpoints.down(915)]: {
    width: '100%',
  },
}));
