import moment from 'moment';
import {
  IAvailability,
  ICalendarDay,
  IEmployeePanelAvailabilityDetails,
  IEmployeePanelGroupedAvailability,
} from 'types/availabilities';

const getDaysFromInterval = (startDate: moment.Moment, endDate: moment.Moment): ICalendarDay[] => {
  let day = startDate.clone();
  const days: ICalendarDay[] = [];
  while (day.isSameOrBefore(endDate)) {
    days.push({
      date: day.clone(),
      dayNumber: day.date(),
      dayAbbr: day.format('ddd'),
      shortDate: day.format('DD MMM'),
      checked: false,
    });
    day = day.add(1, 'day');
  }
  return days;
};

export const generateFullMonthDays = (month: number, year: number): ICalendarDay[] => {
  const firstDayOfMonth = moment().month(month).year(year).startOf('month');
  const lastDayOfMonth = moment().month(month).year(year).endOf('month');
  return getDaysFromInterval(firstDayOfMonth, lastDayOfMonth);
};

const groupAvailabilities = (
  availabilities: IEmployeePanelAvailabilityDetails[],
): IEmployeePanelGroupedAvailability[] => {
  const groupedAvailabilities: IEmployeePanelGroupedAvailability[] = [];

  availabilities.forEach(availability => {
    const availabilityGroup = groupedAvailabilities.find(
      groupedAvailability =>
        groupedAvailability.employeeId === availability.employeeId &&
        groupedAvailability.isAvailableMorning === availability.isAvailableMorning &&
        groupedAvailability.isAvailableAfternoon === availability.isAvailableAfternoon &&
        groupedAvailability.isAvailableEvening === availability.isAvailableEvening,
    );
    if (availabilityGroup) {
      availabilityGroup.dates.push(availability.date);
    } else {
      groupedAvailabilities.push({
        employeeId: availability.employeeId,
        employee: availability.employee,
        isAvailableMorning: availability.isAvailableMorning,
        isAvailableAfternoon: availability.isAvailableAfternoon,
        isAvailableEvening: availability.isAvailableEvening,
        isSubmitted: availability.isSubmitted,
        dates: [availability.date],
      });
    }
  });

  return groupedAvailabilities;
};

const sortAvailabilities = (
  availabilities: IEmployeePanelGroupedAvailability[],
): IEmployeePanelGroupedAvailability[] => {
  return availabilities.sort((availabilityA, availabilityB) => {
    if (availabilityA.isAvailableMorning && availabilityA.isAvailableAfternoon && availabilityA.isAvailableEvening) {
      return -1;
    }
    if (availabilityB.isAvailableMorning && availabilityB.isAvailableAfternoon && availabilityB.isAvailableEvening) {
      return 1;
    }
    if (availabilityA.isAvailableMorning && availabilityA.isAvailableAfternoon) {
      return -1;
    }
    if (availabilityB.isAvailableMorning && availabilityB.isAvailableAfternoon) {
      return 1;
    }
    if (availabilityA.isAvailableAfternoon && availabilityA.isAvailableEvening) {
      return -1;
    }
    if (availabilityB.isAvailableAfternoon && availabilityB.isAvailableEvening) {
      return 1;
    }
    if (availabilityA.isAvailableMorning && availabilityA.isAvailableEvening) {
      return -1;
    }
    if (availabilityB.isAvailableMorning && availabilityB.isAvailableEvening) {
      return 1;
    }
    if (availabilityA.isAvailableMorning) {
      return -1;
    }
    if (availabilityB.isAvailableMorning) {
      return 1;
    }
    if (availabilityA.isAvailableAfternoon) {
      return -1;
    }
    if (availabilityB.isAvailableAfternoon) {
      return 1;
    }
    if (availabilityA.isAvailableEvening) {
      return -1;
    }
    if (availabilityB.isAvailableEvening) {
      return 1;
    }
    return 0;
  });
};

export const sortAndGroupAvailabilitiesByInterval = (
  availabilities: IEmployeePanelAvailabilityDetails[],
): IEmployeePanelGroupedAvailability[] => {
  const groupedAvailabilities: IEmployeePanelGroupedAvailability[] = groupAvailabilities(availabilities);
  const sortedAvailabilities = sortAvailabilities(groupedAvailabilities);
  return sortedAvailabilities;
};

export const getNumberOfNewChanges = (
  storedAvailability: IAvailability,
  currentAvailability: IAvailability,
): number => {
  return storedAvailability.days.reduce((acc, day, index) => {
    let changes = 0;
    if (day?.isAvailableMorning !== currentAvailability?.days[index]?.isAvailableMorning) {
      changes++;
    }
    if (day?.isAvailableAfternoon !== currentAvailability?.days[index]?.isAvailableAfternoon) {
      changes++;
    }
    if (day?.isAvailableEvening !== currentAvailability?.days[index]?.isAvailableEvening) {
      changes++;
    }
    return acc + changes;
  }, 0);
};
