import { Box, Tooltip } from '@mui/material';
import { IconWithMargin } from 'modules/contracts/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTRACTS__ACTIONS_DISABLED } from 'store/constants';
import { ReactComponent as CloseAction } from '../../../../../../assets/icons/CloseContractAction.svg';
import { ReactComponent as ConfirmAction } from '../../../../../../assets/icons/ConfirmContract.svg';
import { ReactComponent as CopyAction } from '../../../../../../assets/icons/CopyContract.svg';
import { ReactComponent as DeleteAction } from '../../../../../../assets/icons/Delete.svg';
import { ReactComponent as DisabledInfoIcon } from '../../../../../../assets/icons/DisabledInfoIcon.svg';
import { ReactComponent as EditAction } from '../../../../../../assets/icons/EditContract.svg';
import { ReactComponent as FillContractSlotAction } from '../../../../../../assets/icons/FillContractSlotIcon.svg';
import { ReactComponent as RefreshAction } from '../../../../../../assets/icons/SmallRefreshIcon.svg';
import { ReactComponent as ReopenAction } from '../../../../../../assets/icons/ReopenServiceIcon.svg';
import { ContractCardActionsContainer, DimonaRefreshContainer } from './styles';
import { ContractCardActionsProps } from './types';

const ContractCardActions: React.FC<ContractCardActionsProps> = ({
  cardId,
  isCopyEnabled,
  isConfirmed,
  isContractSlot,
  isDashboard,
  isDisabled,
  isEditable,
  isEmployee,
  cannotReopenContract,
  dimonaNumber,
  fetchDimonaNumber,
  onClickConfirm,
  onClickCopy,
  onClickDelete,
  onClickEdit,
  onClickFill,
  onClickReopen,
}) => {
  const [t] = useTranslation();

  const renderConfirmAction = () =>
    !isContractSlot &&
    (isConfirmed ? (
      <ReopenAction onClick={onClickReopen} />
    ) : (
      <ConfirmAction data-gtm-event="contracts-calendar-view-confirm-contract" onClick={() => onClickConfirm(cardId)} />
    ));

  const renderDeleteAction = () => (
    <Box sx={{ cursor: 'pointer' }}>
      <DeleteAction onClick={onClickDelete} />
    </Box>
  );

  const renderDimonaRefresh = () => (
    <DimonaRefreshContainer>
      <RefreshAction onClick={fetchDimonaNumber} />
    </DimonaRefreshContainer>
  );

  const renderDisabledTooltip = () => (
    <Tooltip title={t(CONTRACTS__ACTIONS_DISABLED)}>
      <DisabledInfoIcon />
    </Tooltip>
  );

  const renderCopyAction = () => (
    <IconWithMargin onClick={onClickCopy} data-gtm-event="contracts-copy-mode">
      {isCopyEnabled ? <CloseAction /> : <CopyAction />}
    </IconWithMargin>
  );

  const renderEditAction = () => (
    <IconWithMargin>
      {!isContractSlot ? (
        <EditAction onClick={onClickEdit} data-gtm-event="contracts-calendar-view-edit-contract" />
      ) : (
        <FillContractSlotAction onClick={onClickFill} />
      )}
    </IconWithMargin>
  );

  const renderCardActions = () => {
    if (isEmployee) {
      return renderEditAction();
    }
    if (isEditable) {
      if (isDisabled) {
        return renderDisabledTooltip();
      }
      if (!isCopyEnabled) {
        return (
          <>
            {renderDeleteAction()}
            {renderEditAction()}
          </>
        );
      }
    }
    return !isCopyEnabled ? (
      <Box sx={{ cursor: 'pointer', display: cannotReopenContract ? 'none' : 'block' }}>{renderConfirmAction()}</Box>
    ) : null;
  };

  return (
    <ContractCardActionsContainer>
      {renderCardActions()}
      {!isEmployee && !isDashboard && renderCopyAction()}
      {!dimonaNumber && renderDimonaRefresh()}
    </ContractCardActionsContainer>
  );
};

export default ContractCardActions;
