import React from 'react';
import { LinkButton } from './styles';
import { UPLinkButtonProps } from './types';

const UPLinkButton: React.FC<UPLinkButtonProps> = ({ onClick, children, text, sx = {} }) => {
  return (
    <LinkButton onClick={onClick} sx={sx}>
      {text}
      {children}
    </LinkButton>
  );
};

export default UPLinkButton;
