import store from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';

declare const window: any;

const pushProfileToDataLayer = async (): Promise<void> => {
  const user = profileSelector(store.getState());
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'login',
    userRole: user?.role,
    company: user?.companyId,
  });
};

export default pushProfileToDataLayer;
