import { Backdrop } from '@mui/material';
import UPInput from 'components/input';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { useEffect, useMemo } from 'react';
import IPayrollCostCenter from 'types/company/payroll/IPayrollCostCenter';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { UPDropdown } from 'components/dropdown';
import { locationsDropdownItemsSelector, locationsSelector } from 'store/selectors/company-locations-selectors';
import { putCostCenterAction, postCostCenterAction } from 'store/actions/company-cost-center-actions';
import { allowOnlyPositiveNumber, getInputErrorText } from 'utils/helpers';
import { getLocationsAction, getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import toNumber from 'lodash/toNumber';

import {
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModal,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalTitle,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalActionButton,
} from './styles';
import {
  GENERAL__SAVE_BUTTON,
  COMPANY_PAYROLL__COST_CENTER_DESCRIPTION,
  COMPANY_INFORMATION__STREET_NAME,
  COMPANY_INFORMATION__STREET_NUMBER,
  COMPANY_INFORMATION__BUS_NUMBER,
  COMPANY_INFORMATION__CITY,
  COMPANY_INFORMATION__POSTAL_CODE,
  GENERAL__CANCEL_BUTTON,
  COMPANY_MANAGERS__LOCATIONS,
  GENERAL__SELECT,
} from '../../../translations/constants';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { EditPayrollCostCenterProps } from './types';

const CostCenterInitialState: IPayrollCostCenter = {
  id: 0,
  name: '',
  streetName: '',
  streetNumber: '',
  busNumber: '',
  city: '',
  postalCode: '',
  locationId: '',
};

const EditPayrollCostCenter = (props: EditPayrollCostCenterProps): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  let { companyId } = useParams();

  const { open, onClose, costCenter, title, isEdit, paramCompanyId, customDescription, locationId } = props;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IPayrollCostCenter>({
    defaultValues: costCenter || CostCenterInitialState,
  });

  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const locationDetails = useAppSelector(locationsSelector);

  const locations = useMemo(() => locationsDropdownItems, [locationsDropdownItems]);

  if (!companyId) {
    companyId = paramCompanyId;
  }

  const setLocationDetails = (value: number) => {
    const selectedLocation = locationDetails.find(i => {
      return i.id === value;
    });

    setValue('streetName', selectedLocation?.streetName);
    setValue('streetNumber', selectedLocation?.streetNumber);
    setValue('busNumber', selectedLocation?.busNumber);
    setValue('city', selectedLocation?.city);
    setValue('postalCode', selectedLocation?.postalCode);
  };

  if (locationId) {
    setValue('locationId', locationId);
    setLocationDetails(toNumber(locationId));
  }

  if (customDescription) {
    setValue('name', customDescription);
  }

  useEffect(() => {
    dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(getLocationsAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  const resetForm = () => {
    setValue('streetName', null);
    setValue('streetNumber', null);
    setValue('busNumber', null);
    setValue('city', null);
    setValue('postalCode', null);
    setValue('locationId', null);
    setValue('name', null);
  };

  const onSaveCostCenter = handleSubmit((data, event) => {
    if (!companyId) {
      companyId = paramCompanyId;
    }

    if (isEdit) {
      dispatch(putCostCenterAction({ companyId, costCenter: data }));
    } else {
      dispatch(postCostCenterAction({ companyId, costCenter: data }));
    }

    resetForm();

    onClose(event);
  });

  const onLocationChange = (e, onChange) => {
    const {
      target: { value },
    } = e;

    onChange(value);

    setLocationDetails(toNumber(value));
  };

  return (
    <Backdrop open={open}>
      <PayrollEditFunctionModal open={open} onClose={onClose} aria-labelledby="modal-edit-payroll-function">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>{title}</PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn sx={{ marginRight: '16px' }}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="locationId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <UPDropdown
                      items={locations}
                      value={value}
                      onChange={newValue => onLocationChange(newValue, onChange)}
                      placeholder={t(GENERAL__SELECT)}
                      label={t(COMPANY_MANAGERS__LOCATIONS)}
                      error={!!errors.locationId}
                      required
                      helperText={getInputErrorText(errors, 'locationId')}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_PAYROLL__COST_CENTER_DESCRIPTION)}
                      label={t(COMPANY_PAYROLL__COST_CENTER_DESCRIPTION)}
                      required
                      error={!!errors.name}
                      helperText={getInputErrorText(errors, 'name', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="streetName"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value}
                      onChange={onChange}
                      placeholder={t(COMPANY_INFORMATION__STREET_NAME)}
                      label={t(COMPANY_INFORMATION__STREET_NAME)}
                      required
                      error={Boolean(errors.streetName)}
                      helperText={getInputErrorText(errors, 'streetName', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="streetNumber"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value}
                      onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                      placeholder={t(COMPANY_INFORMATION__STREET_NUMBER)}
                      label={t(COMPANY_INFORMATION__STREET_NUMBER)}
                      required
                      error={!!errors.streetNumber}
                      helperText={getInputErrorText(errors, 'streetNumber', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
            <PayrollEditFunctionModalColumn>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="busNumber"
                  control={control}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__BUS_NUMBER)}
                      label={t(COMPANY_INFORMATION__BUS_NUMBER)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__CITY)}
                      label={t(COMPANY_INFORMATION__CITY)}
                      required
                      error={!!errors.city}
                      helperText={getInputErrorText(errors, 'city', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="postalCode"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__POSTAL_CODE)}
                      label={t(COMPANY_INFORMATION__POSTAL_CODE)}
                      required
                      error={!!errors.postalCode}
                      helperText={getInputErrorText(errors, 'postalCode', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            <PayrollEditFunctionModalActionButton text={t(GENERAL__SAVE_BUTTON)} onClick={onSaveCostCenter} />
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

EditPayrollCostCenter.defaultProps = {
  costCenter: {
    id: 0,
    name: '',
    streetName: '',
    streetNumber: '',
    busNumber: '',
    city: '',
    postalCode: '',
  },
  isEdit: true,
};
export default EditPayrollCostCenter;
