import { createReducer, current } from '@reduxjs/toolkit';
import { isContractEditable } from 'modules/contracts/helpers';
import {
  ErrorsInterface,
  IContractData,
  IContractDate,
  IContractToEdit,
  IContractsCountPerWeek,
  ICostEstimation,
} from 'modules/contracts/types';
import {
  contractCountAction,
  deleteContractsSuccessAction,
  getContractsInListViewPayloadAction,
  getContractsPayloadAction,
  getCostEstimationPayloadAction,
  getDimonaNumberErrorAction,
  getDimonaNumberSuccessAction,
  setContractsCount,
  setContractsCountFromPreviousWeeksAction,
  setContractsMultipleConfimrationPayload,
  setContractsMultipleConfirmationSucces,
  setContractsRequestsLoadingAction,
  setDirectlyCardToCopy,
  setContractDateToCopyAction,
  setErrors,
  setFilter,
  setSelectedContracts,
  setShouldCopyContracts,
  tooManyContractsAction,
  validateContractErrorMessageAction,
  validateContractErrorMessageRowAction,
  validateContractSuccessAction,
  toggleCopyModeAction,
} from 'store/actions/contract-actions';
import { DimonaContractKeyPair } from 'types/contract/DimonaDetails';
import IContractTotals from 'types/contract/IContractTotals';

export interface ActiveFiltersData {
  costCenterId: number | null;
  functionId: number | null;
  contractType: string | null;
  locationId: number | null;
  confirmHours: string | boolean | null;
  date: string | null;
  selectedEmployee: number | null;
}

export interface ContractPageState {
  pageSize: number;
  pageIndex: number;
  numberOfResults: number;
}

export interface IContractsState {
  contracts: IContractToEdit[];
  listViewContracts: IContractData[];
  selectedContracts: IContractData[];
  mappedContracts: IContractData[];
  filteredContracts: IContractData[];
  activeFilters: ActiveFiltersData;
  validateEditContractSuccess: boolean;
  validateContractErrorMessage: string;
  validateContractErrorMessageRow: number;
  errors: ErrorsInterface[];
  shouldCopy: boolean;
  directlyCardToCopy: number | null;
  isCopyModeEnabled: boolean;
  contractDateToCopy: IContractData;
  deleteContractsSuccess: boolean;
  contractsCount: IContractTotals;
  loading: boolean;
  costEstimation: ICostEstimation[];
  contractsFromPreviousWeeks: IContractsCountPerWeek[];
  multipleConfirmationResponse: any;
  multipleConfirmationSuccess: boolean;
  getDimonaSuccess?: DimonaContractKeyPair;
  getDimonaError?: DimonaContractKeyPair;
  tooManyContracts?: boolean;
  contractPageDetails: ContractPageState;
}

const initialState: IContractsState = {
  contracts: [],
  mappedContracts: [],
  selectedContracts: [],
  filteredContracts: [],
  listViewContracts: [],
  activeFilters: {
    costCenterId: null,
    functionId: null,
    contractType: null,
    locationId: null,
    confirmHours: null,
    date: null,
    selectedEmployee: null,
  },
  validateEditContractSuccess: false,
  validateContractErrorMessage: '',
  validateContractErrorMessageRow: null,
  errors: [],
  shouldCopy: false,
  directlyCardToCopy: null,
  isCopyModeEnabled: false,
  contractDateToCopy: null,
  deleteContractsSuccess: false,
  contractsCount: null,
  loading: false,
  costEstimation: null,
  contractsFromPreviousWeeks: null,
  multipleConfirmationResponse: [],
  multipleConfirmationSuccess: false,
  getDimonaSuccess: null,
  getDimonaError: null,
  tooManyContracts: false,
  contractPageDetails: null,
};

const generateCommonData = (dataObj: IContractToEdit) => {
  const commonData = {
    contractId: dataObj.contractId,
    companyId: dataObj.companyId,
    contractType: dataObj.contractType,
    functionId: dataObj.functionId,
    locationId: dataObj.locationId,
    workingLocation: dataObj.workingLocation,
    dimonaId: dataObj.dimonaId,
  };
  return commonData;
};

const generateDataForCards = (dataObj: IContractToEdit) => {
  const commonData = generateCommonData(dataObj);
  if (dataObj.employee) {
    return {
      ...commonData,
      employee: {
        id: dataObj.employee.id,
        firstName: dataObj.employee.firstName,
        lastName: dataObj.employee.lastName,
        profilePicture: dataObj.employee.profilePicture,
        functionName: dataObj.employee.functionName,
        inactive: dataObj.employee.inactive,
      },
      vendor: null,
    };
  }
  return {
    ...commonData,
    employee: null,
    vendor: {
      id: dataObj.vendor.id,
      name: dataObj.vendor.name,
      functionName: dataObj.vendor.functionName,
    },
  };
};

const generateIsEditable = (c: IContractData, allDates: IContractData[][]) => {
  if (allDates.length > 1) {
    return isContractEditable(c, new Date(allDates[0][0].date));
  }
  return isContractEditable(c, new Date(c.date));
};

const generateMappedCardsForOneContract = (rawContract: IContractToEdit) => {
  const commonData = generateDataForCards(rawContract);
  const mappedContracts = rawContract.dates.map((d: IContractDate) => {
    return {
      ...commonData,
      costCenterId: d.costCenterId,
      date: d.date,
      startHour: d.startHour,
      endHour: d.endHour,
      startBreak: d.startBreak,
      endBreak: d.endBreak,
      confirmHours: d.confirmHours,
      id: d.id,
      errorMessage: d?.errorMessage,
      warningMessage: d?.warningMessage,
      noShow: d?.noShow,
      dimonaId: d?.dimonaId,
      initialHours: d?.initialHours,
    };
  });

  const allDates = [];
  let auxCurrent = [];
  for (let i = 0; i < mappedContracts.length; ++i) {
    if (auxCurrent.length === 0) {
      auxCurrent.push(mappedContracts[i]);
    } else if (auxCurrent.length > 0) {
      // const dayBefore = addOrSubtractDays(1, auxCurrent[auxCurrent.length - 1].date);

      const previousDate = new Date(auxCurrent[auxCurrent.length - 1].date);
      const currentDay = new Date(mappedContracts[i].date);
      if (
        previousDate.getFullYear() === currentDay.getFullYear() &&
        previousDate.getMonth() === currentDay.getMonth() &&
        previousDate.getDate() === currentDay.getDate()
      ) {
        auxCurrent.push(mappedContracts[i]);
      } else {
        allDates.push(auxCurrent);
        auxCurrent = [mappedContracts[i]];
      }
    }
  }
  if (auxCurrent.length) {
    allDates.push(auxCurrent);
  }

  const auxContracts = allDates.map(dateArray => {
    let contractsToReturn = [];
    if (dateArray.length > 1) {
      const isEditable = generateIsEditable(dateArray[0], allDates);
      const newDateArray = dateArray.map((d: IContractToEdit) => {
        return {
          ...d,
          editable: isEditable,
          // weeklyId: `weekly ${d.contractId}`,
        };
      });
      contractsToReturn = newDateArray;
    } else {
      contractsToReturn = [{ ...dateArray[0], editable: generateIsEditable(dateArray[0], allDates) }];
    }
    return contractsToReturn;
  });

  let finalContracts: IContractToEdit[] = [];
  auxContracts.forEach((a: IContractToEdit[]) => {
    finalContracts = [...finalContracts, ...a];
  });

  for (let i = 0; i < finalContracts.length; i++) {
    for (let j = 0; j < finalContracts.length; j++) {
      if (j !== i && finalContracts[i].contractId === finalContracts[j].contractId) {
        finalContracts[i].weeklyId = `weekly ${finalContracts[i].contractId}`;
      }
    }
  }

  return finalContracts;
};

const filterContracts = (contracts: IContractData[], activeFilters: ActiveFiltersData) => {
  const auxActiveFilters = { ...activeFilters };
  let filteredContracts = contracts;
  if (activeFilters.confirmHours) {
    filteredContracts = contracts.filter((c: IContractData) => !c.editable);
    auxActiveFilters.confirmHours = activeFilters.confirmHours !== '1';
  }

  const activeFiltersKeys = Object.keys(auxActiveFilters);

  activeFiltersKeys.forEach(key => {
    const auxKey = key as keyof ActiveFiltersData;
    if (auxActiveFilters[auxKey] !== null) {
      let auxFilteredContracts;
      if (key === 'selectedEmployee') {
        auxFilteredContracts = filteredContracts.filter(c => c.employee.id === auxActiveFilters[auxKey]);
      } else if (key === 'date') {
        auxFilteredContracts = filteredContracts.filter(c => {
          const cDate = new Date(c.date);
          const filterKeyDate = auxActiveFilters[auxKey] as string;
          const filterDate = new Date(filterKeyDate);
          if (
            cDate.getDate() === filterDate.getDate() &&
            cDate.getMonth() === filterDate.getMonth() &&
            cDate.getFullYear() === filterDate.getFullYear()
          ) {
            return true;
          }
          return false;
        });
      } else auxFilteredContracts = filteredContracts.filter(c => c[key] === auxActiveFilters[auxKey]);
      filteredContracts = auxFilteredContracts;
    }
  });
  return filteredContracts;
};

const generateErrorArray = (errors: ErrorsInterface[]) => {
  const auxErrors = errors.map((err: ErrorsInterface, i: number) => {
    if (err.type === 'deletePastContract' || err.type === 'signedPastContract')
      return {
        id: i,
        type: err.type,
        contractId: err.contractId,
      };
    return {
      id: i,
      type: err.type,
    };
  });
  return auxErrors;
};

const contractsReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setContractsRequestsLoadingAction, (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    })
    .addCase(getContractsPayloadAction, (state, action) => {
      const sortedContractsDates = action.payload.map((c: IContractToEdit) => {
        return {
          ...c,
          dates: c.dates, // sortDaysByDate(c.dates),
        };
      });
      let allContracts = [];
      sortedContractsDates.forEach((c: IContractToEdit) => {
        allContracts = [...allContracts, ...generateMappedCardsForOneContract(c)];
      });
      return {
        ...state,
        contracts: action.payload,
        mappedContracts: allContracts,
        filteredContracts: filterContracts(allContracts, current(state).activeFilters),
      };
    })
    .addCase(getContractsInListViewPayloadAction, (state, action) => {
      return {
        ...state,
        listViewContracts: action.payload,
      };
    })
    .addCase(tooManyContractsAction, (state, action) => {
      return {
        ...state,
        tooManyContracts: action.payload,
      };
    })
    .addCase(contractCountAction, (state, action) => {
      return {
        ...state,
        contractPageDetails: action.payload,
      };
    })
    .addCase(getCostEstimationPayloadAction, (state, action) => {
      return {
        ...state,
        costEstimation: action.payload,
      };
    })
    .addCase(setFilter, (state, action) => {
      let auxAction;
      if (
        (action.payload.filterType === 'confirmHours' || action.payload.filterType === 'contractType') &&
        action.payload.value === ''
      ) {
        auxAction = null;
      } else auxAction = action.payload.value;
      const newActiveFilters = {
        ...state.activeFilters,
        [action.payload.filterType]: auxAction,
      };

      const filteredContracts = filterContracts(current(state).mappedContracts, newActiveFilters);

      return {
        ...state,
        activeFilters: newActiveFilters,
        filteredContracts,
      };
    })
    .addCase(validateContractSuccessAction, (state, action) => {
      return {
        ...state,
        validateEditContractSuccess: action.payload,
      };
    })
    .addCase(validateContractErrorMessageAction, (state, action) => ({
      ...state,
      validateContractErrorMessage: action.payload,
    }))
    .addCase(validateContractErrorMessageRowAction, (state, action) => {
      return {
        ...state,
        validateContractErrorMessageRow: action.payload,
      };
    })
    .addCase(setErrors, (state, action) => {
      return {
        ...state,
        errors: generateErrorArray(action.payload),
      };
    })
    .addCase(setSelectedContracts, (state, action) => {
      return {
        ...state,
        selectedContracts: action.payload,
      };
    })
    .addCase(setShouldCopyContracts, (state, action) => {
      return {
        ...state,
        shouldCopy: action.payload,
      };
    })
    .addCase(setDirectlyCardToCopy, (state, action) => {
      return {
        ...state,
        directlyCardToCopy: action.payload,
      };
    })
    .addCase(setContractDateToCopyAction, (state, action) => {
      return {
        ...state,
        contractDateToCopy: action.payload,
      };
    })
    .addCase(toggleCopyModeAction, state => {
      return {
        ...state,
        isCopyModeEnabled: !state.isCopyModeEnabled,
      };
    })
    .addCase(deleteContractsSuccessAction, (state, action) => {
      return {
        ...state,
        deleteContractsSuccess: action.payload,
      };
    })
    .addCase(setContractsCount, (state, action) => {
      return {
        ...state,
        contractsCount: action.payload,
      };
    })
    .addCase(setContractsCountFromPreviousWeeksAction, (state, action) => {
      return {
        ...state,
        contractsFromPreviousWeeks: action.payload,
      };
    })
    .addCase(setContractsMultipleConfimrationPayload, (state, action) => {
      return {
        ...state,
        multipleConfirmationResponse: action.payload,
      };
    })
    .addCase(setContractsMultipleConfirmationSucces, (state, action) => {
      return {
        ...state,
        multipleConfirmationSuccess: action.payload,
      };
    })
    .addCase(getDimonaNumberSuccessAction, (state, action) => {
      return {
        ...state,
        getDimonaSuccess: action.payload,
      };
    })
    .addCase(getDimonaNumberErrorAction, (state, action) => {
      return {
        ...state,
        getDimonaError: action.payload,
      };
    });
});

export default contractsReducer;
