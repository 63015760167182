import { Box, Checkbox, styled } from '@mui/material';

export const ContractHours = styled(Box, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '20px',
  color: error ? theme.palette.error.main : theme.palette.primary.main,
  display: 'flex',
}));

export const ContractCardCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

export const ContractCardDetailsContainer = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  paddingTop: theme.spacing(1),
  height: '76px',
}));

export const ContractCardDetailsText = styled(Box)(() => ({
  marginLeft: '30px',
  marginRight: '10px',
  position: 'absolute',
}));

export const ContractCardName = styled(Box, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  fontSize: '14px',
  paddingLeft: '10px',
  color: active ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
  fontWeight: 'bold',
}));

export const ContractCardFunction = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  paddingLeft: '10px',
  color: theme.palette.secondary.main,
}));
