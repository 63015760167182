import { Backdrop, Box } from '@mui/material';
import { UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import ErrorBar from 'components/error-bar';
import UPInput from 'components/input';
import WarningBar from 'components/warning-bar';
import { CompanyRole } from 'constants/CompanyRole';
import { useContractForm, useContractModalDropdownData } from 'hooks/contracts';
import { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getWageAction, validateAndSaveEditContractAction } from 'store/actions/contract-actions';
import UPButton from 'components/button';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  CONTRACTS__EDIT_CONTRACT,
  GENERAL__NAME,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  GENERAL__SAVE_BUTTON,
  GENERAL__CANCEL_BUTTON,
} from 'translations/constants';
import { IWageData } from 'types/contract';
import { ContractFormFields, IContractFormFields } from 'types/contract/IContractFormFields';
import { ContractDateContainer } from 'modules/contracts/styles';
import { generateEmployeesDropdownItems } from 'modules/contracts/helpers';
import { IContractDate } from 'modules/contracts/types';
import { getInputErrorText } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg';
import {
  AddContractModalContainer,
  ModalCloseIconStyled,
  ModalContentContainer,
  ModalHeaderContract,
  ModalTitle,
  ModalFooter,
  ModalTitleContainer,
  ModalWrapperContract,
  NameInputStyled,
  RowContainerForNameAndContractType,
} from '../add/styles';
import { WorkerclassInputStyled } from '../styles';
import { EditContractModalProps } from '../types';
import ContractModalsBottomSection from '../ContractModalsBottomSection';
import EditDayPickerRow from './EditDayPickerRow';
import { areAllTimeSpansValidEdit, onError } from '../helpers';
import { addTimespans } from '../add/helpers';

const EditContractModal: React.FC<EditContractModalProps> = props => {
  const { onClose, onSuccess, companyId, week, weekDate, contractToEdit, isContractSlot, defaultBreakTime } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(profileSelector);
  const isEmployee = profile.role === CompanyRole.EMPLOYEE;
  const isWageVisible = profile.showWages;

  const { functions, costCenters, companyEmployees, locationsDropdownItems, costCentersWithDetails } =
    useContractModalDropdownData(+companyId, isEmployee, week, weekDate);

  const [dateRows, setDateRows] = useState<IContractDate[]>(contractToEdit.dates);

  const [currentStartWorkError, setCurrentStartWorkError] = useState<boolean>(false);
  const [currentEndWorkError, setCurrentEndWorkError] = useState<boolean>(false);
  const [hourFormatErrorIndex, setHourFormatErrorIndex] = useState<number>(-1);

  const permanent = !isContractSlot
    ? companyEmployees.find(employee => employee.id === contractToEdit.employee.id)?.permanent
    : false;

  const employees: UPDropdownItem[] = !isContractSlot
    ? [
        {
          value: contractToEdit.employee.id.toString(),
          label: `${contractToEdit.employee.firstName} ${contractToEdit.employee.lastName}`,
        },
      ]
    : generateEmployeesDropdownItems(companyEmployees);

  const { handleSubmit, control, setValue, watch, formState, getValues } = useContractForm(
    functions,
    costCenters,
    contractToEdit,
  );
  const { errors } = formState;

  const functionWatch = watch(ContractFormFields.FUNCTION_ID);
  const locationWatch = watch(ContractFormFields.LOCATION_ID);
  const costCenterWatch = watch(ContractFormFields.COST_CENTER_ID);
  const employeesWatch = watch(ContractFormFields.EMPLOYEE_IDS);

  const isSaveDisabled = () => {
    return currentStartWorkError || currentEndWorkError;
  };

  const onChangeStartBreak = (value: string, rowIndex: number) => {
    let newBreak = null;
    if (defaultBreakTime && value) {
      newBreak = addTimespans(value, defaultBreakTime);
    }
    const newDateRows: IContractDate[] = dateRows.map((dateRow, index) => {
      if (rowIndex === index) {
        return {
          ...dateRow,
          startBreak: value,
          endBreak: newBreak ?? dateRow.endBreak,
        };
      }
      return dateRow;
    });

    setDateRows(newDateRows);
  };

  const onChangeHours = (value: string, type: string, rowIndex: number) => {
    const newDateRows: IContractDate[] = dateRows.map((dateRow, index) => {
      if (index === rowIndex) {
        return {
          ...dateRow,
          [type]: value,
        };
      }
      return dateRow;
    });

    setDateRows(newDateRows);
  };

  const validateAndSaveContract: SubmitHandler<IContractFormFields> = (contractForm: IContractFormFields) => {
    dispatch(
      validateAndSaveEditContractAction({
        contract: {
          contractId: contractForm.id,
          employeeId: contractForm.employeeIds[0],
          companyId: contractToEdit.companyId,
          contractType: contractForm.workerclass,
          functionId: +contractForm.functionId,
          costCenterId: contractForm.costCenterId,
          locationId: +contractForm.locationId,
          workingLocation: contractForm.workingLocation,
          wage: +contractForm.wage,
          dates: dateRows,
        },
        companyId: contractToEdit.companyId,
      }),
    ).then(result => {
      if (result.payload) {
        onSuccess();
      }
    });
  };

  useEffect(() => {
    if (!permanent && isWageVisible && employeesWatch[0]) {
      const wageData: IWageData = {
        employeeId: employeesWatch[0],
        functionId: +functionWatch,
        companyId: +companyId,
      };

      dispatch(getWageAction(wageData)).then(response => {
        const wage = response.payload.toString();
        setValue(`${ContractFormFields.WAGE}`, wage);
      });
    }
  }, [companyId, employeesWatch, functionWatch, permanent, isWageVisible, dispatch, setValue]);

  const onSubmit = () => {
    let isSavePossible = true;

    const dateRowWithErrors = dateRows.find(row => !row.startHour || !row.endHour);

    if (dateRowWithErrors) {
      setCurrentStartWorkError(!dateRowWithErrors.startHour);
      setCurrentEndWorkError(!dateRowWithErrors.endHour);
      isSavePossible = false;
    } else {
      isSavePossible = areAllTimeSpansValidEdit(dateRows, setHourFormatErrorIndex);
    }

    if (isSavePossible) {
      handleSubmit(validateAndSaveContract, onError)();
    }
  };

  const onEmployeeChange =
    (field: ControllerRenderProps<IContractFormFields, ContractFormFields.EMPLOYEE_IDS>) => (newValue: string) => {
      const currentEmployee = companyEmployees.find(employee => employee.id === +newValue);
      field.onChange([+newValue]);
      setValue(`${ContractFormFields.WORKERCLASS}`, currentEmployee?.workerclass);
    };

  return (
    <Backdrop open sx={{ zIndex: 1 }}>
      <ModalWrapperContract open>
        <AddContractModalContainer>
          <ModalHeaderContract>
            <ModalTitleContainer>
              <ModalTitle>{t(CONTRACTS__EDIT_CONTRACT)}</ModalTitle>
              <ModalCloseIconStyled onClick={onClose}>
                <CloseIcon fill={theme.palette.secondary.contrastText} />
              </ModalCloseIconStyled>
            </ModalTitleContainer>
            {contractToEdit.errorMessage && (
              <Box>
                <ErrorBar message={contractToEdit.errorMessage} />
              </Box>
            )}
            {contractToEdit.warningMessage && (
              <Box>
                <WarningBar message={contractToEdit.warningMessage} />
              </Box>
            )}
            <RowContainerForNameAndContractType>
              <NameInputStyled>
                <Controller
                  name={ContractFormFields.EMPLOYEE_IDS}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdownWithSearch
                      value={field.value}
                      onChange={onEmployeeChange(field)}
                      placeholder={t(GENERAL__SELECT)}
                      items={employees}
                      label={t(GENERAL__NAME)}
                      required
                      helperText={getInputErrorText(errors, ContractFormFields.EMPLOYEE_IDS)}
                      error={!!errors.employeeIds}
                      disabled={!isContractSlot || isEmployee}
                    />
                  )}
                />
              </NameInputStyled>
              <WorkerclassInputStyled>
                <Controller
                  name={ContractFormFields.WORKERCLASS}
                  control={control}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={null}
                      label={t(GENERAL__WORKERCLASS)}
                      placeholder=""
                      disabled
                    />
                  )}
                />
              </WorkerclassInputStyled>
            </RowContainerForNameAndContractType>
          </ModalHeaderContract>
          <ModalContentContainer>
            <ContractDateContainer key={`${contractToEdit.contractId} edit`}>
              <EditDayPickerRow
                weekDays={dateRows}
                onChangeHours={onChangeHours}
                currentStartWorkError={currentStartWorkError}
                currentEndWorkError={currentEndWorkError}
                formatError={hourFormatErrorIndex}
                onChangeStartBreak={onChangeStartBreak}
              />
            </ContractDateContainer>
            <ContractModalsBottomSection
              permanent={permanent}
              control={control}
              formState={formState}
              locations={locationsDropdownItems}
              functions={functions}
              costCenters={costCenters}
              isWageVisible={isWageVisible}
              locationId={locationWatch}
              companyId={companyId}
              setValue={setValue}
              getValues={getValues}
              costCentersWithDetails={costCentersWithDetails}
              costCenterId={costCenterWatch}
              isEdit
            />
          </ModalContentContainer>
          <ModalFooter>
            <UPButton text={t(GENERAL__SAVE_BUTTON)} onClick={onSubmit} disabled={isSaveDisabled()} />
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onClose} />
          </ModalFooter>
        </AddContractModalContainer>
      </ModalWrapperContract>
    </Backdrop>
  );
};

export default EditContractModal;
