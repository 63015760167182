import UPButton from 'components/button';
import { CONTRACTS__NEW_CONTRACT, MENU__CONTRACTS } from 'translations/constants';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContractsGraphSection, GraphButtonContainer, GraphDisplayContainer, GraphTitle } from './styles';
import ContractsGraph from './ContractsGraph';
import { DashboardContractsGraphProps } from './types';
import { generateDummyElements } from './helpers';

const DashboardContractsGraph = (props: DashboardContractsGraphProps) => {
  const { openAddNewContractModal, contractsNumber, isEmployee } = props;

  const [t] = useTranslation();

  const [graphData, setGraphData] = useState<any>(null);
  const [maxValue, setMaxValue] = useState<number>(0);

  useEffect(() => {
    if (contractsNumber) {
      let maxYValue = 1.5 * Math.max(...contractsNumber.map(item => item.count));
      maxYValue = Math.max(maxYValue, 5);
      let data = contractsNumber.map(item => {
        return {
          name: `Week ${item.week}`,
          value: item.count,
          place: maxYValue - item.count,
        };
      });
      setMaxValue(maxYValue);
      data = generateDummyElements(data.reverse(), maxYValue);
      setGraphData(data);
    }
  }, [contractsNumber, setGraphData]);

  return (
    <ContractsGraphSection>
      <GraphTitle>
        <Typography>{t(MENU__CONTRACTS)}</Typography>
        <Typography>Total Contracts</Typography>
      </GraphTitle>
      <GraphDisplayContainer>
        {graphData?.length > 0 && <ContractsGraph data={graphData} maxValue={maxValue} />}
      </GraphDisplayContainer>
      {!isEmployee && (
        <GraphButtonContainer>
          <UPButton
            text={t(CONTRACTS__NEW_CONTRACT)}
            onClick={openAddNewContractModal}
            gtmEvent="dashboard-add-new-contract"
          />
        </GraphButtonContainer>
      )}
    </ContractsGraphSection>
  );
};

export default DashboardContractsGraph;
