import { Box, styled, InputLabel } from '@mui/material';

export const RowContainerForContractDate = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '24px',
  width: '100%',
  [theme.breakpoints.down(1500)]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down(768)]: {
    flexWrap: 'wrap',
  },
}));

export const DateDropdownContainer = styled(Box)(({ theme }) => ({
  marginRight: '15px',
  width: '50%',
  [theme.breakpoints.down(768)]: {
    minWidth: 'unset',
    marginRight: 'unset',
    width: '100%',
    paddingBottom: '10px',
  },
  [theme.breakpoints.up(768)]: {
    minWidth: '300px',
  },
}));

export const HourDropdownContainer = styled(Box)(({ theme }) => ({
  marginRight: '15px',
  minWidth: '93px',
  width: '25%',
  [theme.breakpoints.down(768)]: {
    marginRight: 'unset',
    padding: '10px',
    minWidth: 'unset',
    width: '50%',
  },
}));

export const DayPickerHoursError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  marginLeft: '328px',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(768)]: {
    marginLeft: '5px',
  },
}));

export const DayPickerEndHourError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexDirection: 'row-reverse',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(768)]: {
    marginLeft: '5px',
  },
}));

export const DayPickerHoursSecondError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  marginLeft: '328px',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(768)]: {
    marginLeft: 'unset',
    position: 'unset',
    marginTop: 'unset',
    paddingRight: '10px',
  },
}));

export const WageStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: '20px',
  width: '33.3333%',
  [theme.breakpoints.down(768)]: {
    width: '100%',
    marginRight: 'unset',
    paddingTop: '10px',
  },
}));

export const WorkingLocationStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: '20px',
  width: '66.6667%',

  [theme.breakpoints.down(768)]: {
    width: '100%',
    marginRight: 'unset',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));

export const WorkerclassInputStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  width: '50%',
  [theme.breakpoints.down(768)]: {
    width: '100%',
  },
}));

export const CompanyBranchInputStyled = styled(Box)(({ theme }) => ({
  minWidth: '280px',
  display: 'inline-block',
  marginRight: '20px',
  width: '66.6667%',
  [theme.breakpoints.down(768)]: {
    minWidth: 'unset',
    width: '100%',
    marginRight: 'unset',
    paddingTop: '10px',
  },
}));

export const CostCenterStyled = styled(Box)(({ theme }) => ({
  minWidth: '280px',
  display: 'inline-block',
  marginRight: '20px',
  width: '33.3333%',
  [theme.breakpoints.down(1500)]: {
    minWidth: '246px',
  },
  [theme.breakpoints.down(768)]: {
    minWidth: 'unset',
    width: '100%',
    marginRight: 'unset',
    paddingTop: '10px',
  },
}));

export const FunctionStyled = styled(Box)(({ theme }) => ({
  minWidth: '280px',
  display: 'inline-block',
  marginRight: '20px',
  width: '33.3333%',
  [theme.breakpoints.down(768)]: {
    minWidth: 'unset',
    width: '100%',
    marginRight: 'unset',
    paddingTop: '10px',
  },
}));
