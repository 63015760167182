import { UPDropdownItem } from 'components/dropdown';
import {
  COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT,
  COMPANY_PAYROLL__MAX_BREAKTIME_REACHED,
} from 'translations/constants';

export const countriesWithBelgiumFirst = (countries: UPDropdownItem[]) => {
  const found = countries.find((c: UPDropdownItem) => c.value === 21);
  const auxCountries = countries.filter((c: UPDropdownItem) => c.value !== 21);
  auxCountries.unshift(found);
  return auxCountries;
};

export const isDefaultBreakTimeValid = (breakTime: string, setError: any, t: any): boolean => {
  const maxHoursError = { type: 'invalid', message: `${t(COMPANY_PAYROLL__MAX_BREAKTIME_REACHED)} - 05:59` };

  // not mandatory
  if (!breakTime) {
    return true;
  }

  const pattern = /^(0[0-6]:[0-5]\d)?$/;
  if (!pattern.test(breakTime)) {
    setError('defaultBreakTime', maxHoursError);
    return false;
  }

  return true;
};

export const areAvailabilityIntervalsValid = (
  morningIntervalEnds: string[],
  afternoonIntervalEnds: string[],
  eveningIntervalEnds: string[],
  setError: any,
  t: any,
): boolean => {
  const defaultError = { type: 'invalid', message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT) };
  const [morningStart, morningEnd] = morningIntervalEnds;
  const [afternoonStart, afternoonEnd] = afternoonIntervalEnds;
  const [eveningStart, eveningEnd] = eveningIntervalEnds;

  if (morningStart >= morningEnd) {
    setError('morningInterval', defaultError);
    return false;
  }

  if (afternoonStart >= afternoonEnd) {
    setError('afternoonInterval', defaultError);
    return false;
  }

  if (eveningStart >= eveningEnd) {
    setError('eveningInterval', defaultError);
    return false;
  }

  if (morningEnd > afternoonStart) {
    setError('morningInterval', defaultError);
    setError('afternoonInterval', defaultError);
    return false;
  }
  if (morningEnd > eveningStart) {
    setError('morningInterval', defaultError);
    setError('eveningInterval', defaultError);
    return false;
  }
  if (afternoonEnd > eveningStart) {
    setError('afternoonInterval', defaultError);
    setError('eveningInterval', defaultError);
    return false;
  }

  return true;
};
