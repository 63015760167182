import store from 'store';
import loadProfile from './load-profile';
import pushProfileToDataLayer from './push-profile-to-datalayer';

const postAuthStartup = async (): Promise<void> => {
  await store.dispatch(loadProfile);
  await pushProfileToDataLayer();
};

export default postAuthStartup;
