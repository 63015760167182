import { Box } from '@mui/material';
import React from 'react';
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/WarningIcon.svg';
import { ContractCardCheckbox, ContractHours } from './styles';
import { ContractCardHeaderProps } from '../types';

const ContractCardHeader: React.FC<ContractCardHeaderProps> = ({ card, onChangeCardCheck, isEmployee }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" position="relative">
    <ContractHours error={card.error}>
      {card.startHour} - {card.endHour}
      {card.error && <WarningIcon />}
    </ContractHours>
    {card.checked && !isEmployee && (
      <ContractCardCheckbox checked={card.checked} onChange={() => onChangeCardCheck(card.contractId)} />
    )}
  </Box>
);

export default ContractCardHeader;
