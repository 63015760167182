import { Box, styled } from '@mui/material';

export const ContractCardActionsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

export const DimonaRefreshContainer = styled(Box)(() => ({
  marginLeft: '150px',
  marginTop: '90px',
  position: 'absolute',
  cursor: 'pointer',
}));
