import { Dispatch, SetStateAction } from 'react';
import { IHours, IWeekDaysRow } from 'types/contract';
import { IContractDate } from '../types';

// Helper function to validate a specific time span property
const validateTimeSpan = (dates: IWeekDaysRow[], property: keyof IHours, validTimeSpanRegex: RegExp): number => {
  return dates.findIndex(row => !validTimeSpanRegex.test(row.hours[property]));
};

// Main function to validate all time spans
export const areAllTimeSpansValid = (
  dates: IWeekDaysRow[],
  setFormatError: Dispatch<SetStateAction<number>>,
): boolean => {
  const validTimeSpanRegex = /^(?:\d{2}:\d{2})?$/;

  const timeSpanProperties: (keyof IHours)[] = ['startWork', 'endWork', 'startBreak', 'endBreak'];

  const invalidIndex = timeSpanProperties.findIndex(
    property => validateTimeSpan(dates, property, validTimeSpanRegex) > -1,
  );
  if (invalidIndex > -1) {
    setFormatError(invalidIndex);
    return false;
  }

  return true;
};

const validateTimeSpanEdit = (dates: IContractDate[], property: string, validTimeSpanRegex: RegExp): number => {
  return dates.findIndex(row => !validTimeSpanRegex.test(row[property]));
};

export const areAllTimeSpansValidEdit = (
  dates: IContractDate[],
  setFormatError: Dispatch<SetStateAction<number>>,
): boolean => {
  const validTimeSpanRegex = /^(?:\d{2}:\d{2})?$/;

  const timeSpanProperties: string[] = ['startHour', 'startBreak', 'endBreak', 'endHour'];

  const invalidIndex = timeSpanProperties.findIndex(
    property => validateTimeSpanEdit(dates, property, validTimeSpanRegex) > -1,
  );
  if (invalidIndex > -1) {
    setFormatError(invalidIndex);
    return false;
  }

  return true;
};

export const onError = (err, e) => {
  console.log(err, e);
};
