import { Box, Checkbox } from '@mui/material';
import { getAvailabilityIntervals } from 'modules/availabilities/company-availability/helpers';
import { CompanyAvailabilityRegularCellProps } from 'modules/availabilities/company-availability/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AVAILABILITIES__NO_AVAILABILITIES, AVAILABILITIES__UNAVAILABLE } from 'translations/constants';
import { ReactComponent as AddNewContractIcon } from '../../../../../../assets/icons/CircleAddNewContractIcon.svg';
import {
  AvailabilityBarBody,
  AvailabilityBarContainer,
  AvailabilityBarSegment,
  AvailabilityCellActions,
  AvailabilityCellOptions,
  HasContractDot,
  RegularCellBody,
  RegularCellContainer,
  RegularCellTextContainer,
} from './styles';

const CompanyAvailabilityRegularCell = (props: CompanyAvailabilityRegularCellProps) => {
  const {
    availabilityDay,
    companyAvailabilityIntervals,
    canBeSelected,
    onSelectDay,
    isChecked,
    openModal,
    buttonDisplayed,
    month,
    hasDaysInPreviousMonth,
    hasContract,
    areAllCardsUnhovered,
  } = props;
  const [t] = useTranslation();

  const [availabilityIntervals, setAvailabilityIntervals] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const displayAvailabilityIntervals = () => {
    const intervals = getAvailabilityIntervals(availabilityDay, companyAvailabilityIntervals);
    if (intervals[0] === AVAILABILITIES__UNAVAILABLE) {
      setAvailabilityIntervals([t(AVAILABILITIES__UNAVAILABLE)]);
      return;
    }
    if (intervals[0] === AVAILABILITIES__NO_AVAILABILITIES) {
      setAvailabilityIntervals([t(AVAILABILITIES__NO_AVAILABILITIES)]);
      return;
    }
    setAvailabilityIntervals(intervals);
  };

  useEffect(displayAvailabilityIntervals, [availabilityDay, companyAvailabilityIntervals, t]);

  const isUnavailable = availabilityIntervals[0] === t(AVAILABILITIES__UNAVAILABLE);
  const isNotSubmitted = availabilityIntervals[0] === t(AVAILABILITIES__NO_AVAILABILITIES);
  const isAvailable = !isUnavailable && !isNotSubmitted;

  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();
  const isInThePast =
    currentMonth > month ||
    (currentMonth === month && currentDay > availabilityDay.day) || // days in the same month but in the past
    (currentMonth === month - 1 &&
      hasDaysInPreviousMonth &&
      currentDay > availabilityDay.day &&
      availabilityDay.day > 7) || // days from the current month but in the past, at the end of the month
    (currentMonth === month && hasDaysInPreviousMonth && availabilityDay.day > 7); // days from the previous month

  useEffect(() => {
    if (areAllCardsUnhovered && isHovered) {
      setIsHovered(false);
    }
  }, [areAllCardsUnhovered, isHovered]);

  return (
    <RegularCellContainer
      onMouseEnter={() => canBeSelected && !isInThePast && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {(isHovered || isChecked) && (
        <AvailabilityCellOptions>
          <Checkbox
            checked={isChecked}
            onChange={() => onSelectDay(availabilityDay.day)}
            sx={{ padding: '0px', width: '16px', height: '16px', position: 'absolute', top: '8px', right: '8px' }}
            disabled={!canBeSelected}
          />
          {buttonDisplayed && (
            <AvailabilityCellActions>
              <Box sx={{ cursor: 'pointer' }}>
                <AddNewContractIcon
                  onClick={() => {
                    onSelectDay(availabilityDay.day);
                    openModal();
                  }}
                />
              </Box>
            </AvailabilityCellActions>
          )}
        </AvailabilityCellOptions>
      )}
      <RegularCellBody isHovered={isHovered}>
        <AvailabilityBarContainer>
          <AvailabilityBarBody isAvailable={isAvailable} isNotSubmitted={isNotSubmitted} hasContract={hasContract}>
            <AvailabilityBarSegment isAvailable={availabilityDay.isAvailableMorning} />
            <AvailabilityBarSegment isAvailable={availabilityDay.isAvailableAfternoon} />
            <AvailabilityBarSegment isAvailable={availabilityDay.isAvailableEvening} />
            {hasContract && <HasContractDot>1</HasContractDot>}
          </AvailabilityBarBody>
        </AvailabilityBarContainer>
        <RegularCellTextContainer isAvailable={isAvailable}>{availabilityIntervals[0]}</RegularCellTextContainer>
        <RegularCellTextContainer isAvailable>{availabilityIntervals[1]}</RegularCellTextContainer>
      </RegularCellBody>
    </RegularCellContainer>
  );
};

export default CompanyAvailabilityRegularCell;
