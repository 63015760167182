import { Avatar } from '@mui/material';
import { CardContent, CardSubtitle, UPCardStyled, CardTitle, MediaContent, CardDetails } from './styles';
import { UPCardProps } from './types';

const UPCard = (props: UPCardProps): JSX.Element => {
  const { title, subTitle, description, logo, onClick, defaultLogo } = props;
  return (
    <UPCardStyled onClick={onClick}>
      <CardContent>
        <MediaContent>
          {logo !== null ? <Avatar src={logo} sx={{ width: '80px', height: '80px' }} /> : defaultLogo}
        </MediaContent>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subTitle}</CardSubtitle>
        <CardDetails>{description}</CardDetails>
      </CardContent>
    </UPCardStyled>
  );
};

export default UPCard;
