/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { loginAction, ssoLoginAction } from 'store/actions/auth-actions';
import ILoginFormInputs from 'types/auth/ILoginFormInputs';
import { authErrorSelector, authErrorTextSelector, authLoadingSelector } from 'store/selectors/auth-selectors';
import Lang from 'types/Lang';
import { getUpDropdownItemsWithIdAsValueFromEnum, validatePassword } from 'utils/helpers';
import { UPDropdownItem } from 'components/dropdown';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'msalConfig';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';
import {
  LOGIN__PAGE_TITLE,
  LOGIN__PAGE_QUOTE,
  LOGIN__PASSWORD_REQUIRED_ERROR,
  LOGIN__EMAIL_LABEL,
  LOGIN__PASSWORD_LABEL,
  LOGIN__SIGN_IN,
  LOGIN__CREATE_ACCOUNT,
  LOGIN__FORGOT_PASSWORD,
  LOGIN__CONTACT_US,
  LOGIN__EMAIL_REQUIRED_ERROR,
  LOGIN__EMAIL_MAX_LENGTH_ERROR,
  LOGIN__INCORRECT_EMAIL_OR_PASSWORD,
  PASSWORD_POLICY__PASSWORD_POLICY,
  LOGIN__PASSWORD_MIN_LENGTH_ERROR,
} from '../../translations/constants';
import {
  AuthContainer,
  AuthTitle,
  AuthLeftContainer,
  AuthRightContainer,
  AuthSignInContainer,
  AuthSignIn,
  AuthUPInput,
  AuthUPButton,
  AuthCreateAccountTitle,
} from './styles';

const Auth = (): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginFormInputs>({ defaultValues: { Email: '', Password: '' } });
  const [t, i18next] = useTranslation();
  const dispatch = useAppDispatch();

  const [passwordPolicyError, setPasswordPolicyError] = useState<string>('');

  const onLogin = useCallback(
    (data: ILoginFormInputs): void => {
      if (validatePassword(data.Password)) {
        dispatch(loginAction({ email: data.Email, password: data.Password }));
        dispatch(setMenuStatusAction('Dashboard'));
      } else {
        // setPasswordPolicyError('Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character');
        setPasswordPolicyError(t(PASSWORD_POLICY__PASSWORD_POLICY));
      }
    },
    [dispatch, t],
  );

  const authLoading = useAppSelector(authLoadingSelector);
  const authError = useAppSelector(authErrorSelector);
  const authErrorText = useAppSelector(authErrorTextSelector);

  const getEmailErrorText = useCallback(() => {
    if (errors.Email?.type === 'required') {
      return t(LOGIN__EMAIL_REQUIRED_ERROR);
    }
    if (errors.Email?.type === 'maxLength') {
      return t(LOGIN__EMAIL_MAX_LENGTH_ERROR);
    }
    return '';
  }, [t, errors]);

  const getPasswordErrorText = useCallback(() => {
    if (errors.Password?.type === 'required') {
      return t(LOGIN__PASSWORD_REQUIRED_ERROR);
    }

    if (authError) {
      return t(LOGIN__INCORRECT_EMAIL_OR_PASSWORD);
    }

    if (passwordPolicyError) {
      return passwordPolicyError;
    }
    return '';
  }, [t, errors, authError, passwordPolicyError]);

  const onKeyPress = useCallback(
    (event: any): void => {
      const code = event.keyCode || event.which;
      if (code === 13) {
        handleSubmit(onLogin)();
      }
    },
    [handleSubmit, onLogin],
  );

  const { instance } = useMsal();
  let loggedAccount;

  if (instance) {
    loggedAccount = instance.getActiveAccount();
  }

  const handleLoginPopup = async () => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    await instance
      .loginPopup({
        ...loginRequest,
        redirectUri: `${window.location.origin}/redirect`,
      })
      .then(response => {
        console.log('loginresponse');
        console.log(response);
        const { account } = response;
        dispatch(ssoLoginAction({ email: account.username, password: '' }));
        dispatch(setMenuStatusAction('Dashboard'));
      })
      .catch(error => console.log('========auth error:', error));
  };

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  useEffect(() => {
    const userLang = navigator.language;
    const languageMatch = languageOptions.find(l => l.label === userLang);
    if (languageMatch !== undefined) {
      i18next.changeLanguage(languageMatch.label);
    }
  }, [i18next, languageOptions]);

  return (
    <Box>
      <AuthHeader />
      <AuthContainer>
        <AuthLeftContainer>
          <AuthTitle>{t(LOGIN__PAGE_QUOTE)}</AuthTitle>
        </AuthLeftContainer>
        <AuthRightContainer>
          <AuthSignInContainer>
            <AuthSignIn>{t(LOGIN__PAGE_TITLE)}</AuthSignIn>
            <Controller
              name="Email"
              control={control}
              rules={{ required: true, maxLength: 50 }}
              render={({ field }) => (
                <AuthUPInput
                  value={field.value}
                  label={t(LOGIN__EMAIL_LABEL)}
                  placeholder={t(LOGIN__EMAIL_LABEL)}
                  error={!!errors.Email || authError}
                  required
                  type="email"
                  helperText={getEmailErrorText()}
                  onChange={field.onChange}
                  onKeyPress={event => onKeyPress(event)}
                />
              )}
            />
            <Controller
              name="Password"
              control={control}
              rules={{ required: true, minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) } }}
              render={({ field }) => (
                <AuthUPInput
                  value={field.value}
                  label={t(LOGIN__PASSWORD_LABEL)}
                  placeholder={t(LOGIN__PASSWORD_LABEL)}
                  type="password"
                  required
                  error={!!errors.Password || authError}
                  helperText={getPasswordErrorText()}
                  onChange={field.onChange}
                  onKeyPress={event => onKeyPress(event)}
                />
              )}
            />
            <Box display="flex" gap="10px">
              <AuthUPButton
                text={t(LOGIN__SIGN_IN)}
                onClick={() => {
                  handleSubmit(onLogin)();
                }}
                disabled={authLoading}
              />
              <AuthUPButton
                onClick={async () => {
                  await handleLoginPopup();
                }}
                text={t('LOGIN_WITH_MICROSOFT_ACCOUNT')}
              />
            </Box>
            {authErrorText && <p>Unable to login with Microsoft Account!</p>}
            <Link href="/forgotpassword">{t(LOGIN__FORGOT_PASSWORD)}</Link>
            <AuthCreateAccountTitle>{t(LOGIN__CREATE_ACCOUNT)}</AuthCreateAccountTitle>
            <Link href="https://www.update-pro.be/contact/" target="_blank">
              <AuthUPButton text={t(LOGIN__CONTACT_US)} onClick={null} outlined />
            </Link>
          </AuthSignInContainer>
        </AuthRightContainer>
      </AuthContainer>
      <AuthFooter />
    </Box>
  );
};

export default Auth;
