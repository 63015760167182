import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { UPDropdownItem } from 'components/dropdown';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_PAYROLL__ADD_MOTIVE_EXEMPTION,
  COMPANY_PAYROLL__DELETE_MOTIVE_EXEMPTION,
  COMPANY_PAYROLL__EMPLOYEE,
  COMPANY_PAYROLL__MOTIVE,
  GENERAL__SELECT,
} from 'translations/constants';
import toNumber from 'lodash/toNumber';
import { useDispatch } from 'react-redux';
import DataGridEditInputCell from './wage-exemptions/DataGridEditInputCell';
import { MotivesPerEmployeeGridProps } from './types';
import { CoefficientPerWorkerClassGridContainer } from './styles';

const MotivePerEmployeeGrid = (props: MotivesPerEmployeeGridProps): JSX.Element => {
  const { motivePerEmployees, setMotivePerEmployees, contractMotives, employees, clearErrors } = props;

  const [t] = useTranslation();
  const [employeesOptions, setEmployeesOptions] = useState<UPDropdownItem[]>(employees);
  const [rowIsUpdated, setRowIsUpdated] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();

  useEffect(() => {
    if (rowIsUpdated) {
      clearErrors('motivePerEmployee');
      const updatedOptions = employeesOptions.map(employeeOption => {
        const employeeWithMotive = motivePerEmployees.find(
          w => w.employeeId === employeeOption.value || Number(w.employeeId) === Number(employeeOption.value),
        );
        return { ...employeeOption, isDisabled: employeeWithMotive !== undefined };
      });
      if (updatedOptions !== employeesOptions) {
        setEmployeesOptions(updatedOptions);
      }
      setRowIsUpdated(false);
    }
  }, [motivePerEmployees, employeesOptions, rowIsUpdated, clearErrors]);

  useEffect(() => {
    if (employees.length > 0) {
      if (motivePerEmployees.length > 0) {
        const updatedOptions = employees.map(employeeOption => {
          const employeeWithMotive = motivePerEmployees.find(
            w => w.employeeId === employeeOption.value || Number(w.employeeId) === Number(employeeOption.value),
          );
          return { ...employeeOption, isDisabled: employeeWithMotive !== undefined };
        });
        setEmployeesOptions(updatedOptions);
      } else {
        setEmployeesOptions(employees);
      }
    }
  }, [employees, motivePerEmployees]);

  const getEmployee = useCallback(
    employeeId => {
      const employeeData = employeesOptions.find(pc => toNumber(pc.value) === employeeId || pc.value === employeeId);
      return employeeData ? employeeData.label : <span>{t(GENERAL__SELECT)}</span>;
    },
    [employeesOptions, t],
  );

  const getMotive = useCallback(
    motiveId => {
      const motive = contractMotives?.find(
        (item: UPDropdownItem) => item.value === motiveId || Number(item.value) === motiveId,
      );
      return motive ? motive.label : <span>{t(GENERAL__SELECT)}</span>;
    },
    [contractMotives, t],
  );

  const onAddNewEmployeeMotiveExemption = useCallback(() => {
    setMotivePerEmployees([...motivePerEmployees, { id: Math.random(), employeeId: 0, motiveId: 0 }]);
  }, [motivePerEmployees, setMotivePerEmployees]);

  const onDeleteEmployeeMotive = useCallback(() => {
    setMotivePerEmployees([...motivePerEmployees.filter(c => c.id !== selectedRow)]);
    setSelectedRow(null);
    setRowIsUpdated(true);
  }, [motivePerEmployees, selectedRow, setMotivePerEmployees]);

  const renderFunctionEditInputCell = useCallback(
    (params: any) => {
      /* eslint-disable react/jsx-props-no-spreading */
      return <DataGridEditInputCell {...params} dropdownOptions={employeesOptions} />;
    },
    [employeesOptions],
  );

  const updateEmployeeMotiveList = useCallback(
    (id, field, value) => {
      let motiveEmployeeRow = motivePerEmployees.find(c => c.id === id);
      const index = motivePerEmployees.indexOf(motiveEmployeeRow);
      motiveEmployeeRow = { ...motiveEmployeeRow, [field]: value };
      const updatedList = [
        ...motivePerEmployees.slice(0, index),
        motiveEmployeeRow,
        ...motivePerEmployees.slice(index + 1, motivePerEmployees.length),
      ];
      setMotivePerEmployees(updatedList);
      setRowIsUpdated(true);

      return motiveEmployeeRow;
    },
    [motivePerEmployees, setMotivePerEmployees],
  );

  const motiveExemptionsColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'employeeId',
      headerName: t(COMPANY_PAYROLL__EMPLOYEE),
      width: 200,
      editable: true,
      renderCell: params => {
        return getEmployee(params.row.employeeId);
      },
      renderEditCell: renderFunctionEditInputCell,
      valueSetter: params => {
        const updatedMotiveExemption = updateEmployeeMotiveList(params.row.id, 'employeeId', params.value);
        return updatedMotiveExemption;
      },
      sortable: false,
    },
    {
      field: 'motiveId',
      headerName: t(COMPANY_PAYROLL__MOTIVE),
      width: 300,
      editable: true,
      sortable: false,
      renderCell: params => {
        return getMotive(params.row.motiveId);
      },
      renderEditCell: params => {
        const found = contractMotives?.find((item: UPDropdownItem) => item.value === params.row.motiveId);
        return (
          <DataGridEditInputCell
            {...params}
            value={found?.value}
            dropdownOptions={contractMotives}
            category="motivePerEmployees"
          />
        );
      },
      valueSetter: params => {
        const updatedMotiveExemption = updateEmployeeMotiveList(params.row.id, 'motiveId', params.value);
        return updatedMotiveExemption;
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          marginTop: '8px',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <UPButton text={`+ ${t(COMPANY_PAYROLL__ADD_MOTIVE_EXEMPTION)}`} onClick={onAddNewEmployeeMotiveExemption} />
        </Box>
        <Box>
          <UPButton text={t(COMPANY_PAYROLL__DELETE_MOTIVE_EXEMPTION)} onClick={onDeleteEmployeeMotive} outlined />
        </Box>
      </Box>
      <CoefficientPerWorkerClassGridContainer>
        <UPDataGrid
          rows={motivePerEmployees}
          columns={motiveExemptionsColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selection: any) => setSelectedRow(selection[0])}
          withCheckboxes={false}
          hideFooter
          getRowId={r => r.id}
        />
      </CoefficientPerWorkerClassGridContainer>
    </Box>
  );
};
export default MotivePerEmployeeGrid;
