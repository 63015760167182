import React from 'react';
import { Box, Checkbox, ListItemText, MenuItem, OutlinedInput } from '@mui/material';
import { UPDropdownHelperText, UPSelectStyled } from 'components/dropdown/styles';
import { UPInputLabel } from 'components/input/styles';
import { UPMultipleSelectWithCheckboxProps } from './types';
import { DropdownPlaceholder, MenuProps } from './styles';

const UPMultipleSelectWithCheckbox = (props: UPMultipleSelectWithCheckboxProps) => {
  const { items, label, required, selectedItems, onChange, placeholder, error, helperText } = props;

  return (
    <Box width={1}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>

      <UPSelectStyled
        displayEmpty
        multiple
        fullWidth
        value={selectedItems}
        onChange={onChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        renderValue={(value: unknown) => {
          const selected = value as string[];
          if (selected.length === 0) {
            return <DropdownPlaceholder>{placeholder}</DropdownPlaceholder>;
          }

          return selected.join(', ');
        }}
        error={error}
      >
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={selectedItems.includes(item.value)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </UPSelectStyled>
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

export default UPMultipleSelectWithCheckbox;
