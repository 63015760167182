import { Box, Modal, styled, Typography } from '@mui/material';

export const CopyMultipleModalStyled = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '450px',
  zIndex: '1200',
  maxHeight: '500px',
  height: 'auto',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0,0,0,0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.down(611)]: {
    width: '90%',
  },
}));

export const ModalContainer = styled(Box)(() => ({
  zIndex: '1200',
  width: '100%',
  background: 'white',
  padding: '32px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
  maxHeight: '650px',
  overflowX: 'auto',
}));

export const CopyMultipleModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'center',
}));

export const ModalActionContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
}));
