import { InputLabel, Select, MenuItem, Pagination } from '@mui/material';
import { t } from 'i18next';
import { GENERAL__ITEMS_PER_PAGE } from 'translations/constants';
import { UPPaginationProps } from './types';
import { CustomizeSection, UPPaginationStyled } from './styles';

const UPPagination = (props: UPPaginationProps): JSX.Element => {
  const { itemsPerPage, onChange, numberOfPages, currentPage, handlePageChange } = props;

  return (
    <UPPaginationStyled>
      <CustomizeSection>
        <InputLabel id="paginationLabel" sx={{ fontSize: '13px', paddingRight: '10px' }}>
          {t(GENERAL__ITEMS_PER_PAGE)}
        </InputLabel>
        <Select
          sx={{ fontSize: '13px', height: '33px' }}
          labelId="paginationLabel"
          id="select"
          value={`${itemsPerPage}`}
          onChange={onChange}
        >
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="40">40</MenuItem>
          <MenuItem value="80">80</MenuItem>
          <MenuItem value="100">100</MenuItem>
        </Select>
      </CustomizeSection>
      <Pagination count={numberOfPages} page={currentPage} onChange={handlePageChange} />
    </UPPaginationStyled>
  );
};

export default UPPagination;
