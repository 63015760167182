import { Backdrop } from '@mui/material';
import UPButton from 'components/button';
import { UPDropdownItem } from 'components/dropdown';
import UPMultipleSelectAutocomplete from 'components/dropdown-with-search/UPMultipleSelectAutocomplete';
import UPInput from 'components/input';
import { CompanyRole } from 'constants/CompanyRole';
import { ContractTypes } from 'constants/ContractTypes';
import { useContractForm, useContractModalDropdownData } from 'hooks/contracts';
import { ContractDateContainer, ContractXIcon } from 'modules/contracts/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getWageAction,
  validateAndSaveMultipleEmployeeContractAction,
  validateContractErrorMessageAction,
  validateContractErrorMessageExtraInfoAction,
  validateContractErrorMessageRowAction,
} from 'store/actions/contract-actions';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  CONTRACTS__ADD_ANOTHER,
  CONTRACTS__NEW_CONTRACT,
  GENERAL__CANCEL_BUTTON,
  GENERAL__CREATE,
  GENERAL__NAME,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  HEADER__WEEK,
} from 'translations/constants';
import {
  ContractFormFields,
  IAddContract,
  IContractFormFields,
  IDateError,
  IEmployeeDataPair,
  IHours,
  IWageData,
  IWeekDays,
  IWeekDaysRow,
} from 'types/contract';
import { fromMultipleEmployeeContractToMultipleEmployeeContractRequest } from 'mappers/contracts-mapper';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg';
import { generateEmployeesDropdownItems, getCurrentTime, hoursToMinutes } from '../../helpers';
import { areAllTimeSpansValid, onError } from '../helpers';
import { AddContractModalProps } from '../types';
import AddDayPickerRow from './AddDayPickerRow';
import { generateDateRows, generateDateRowsFromDatesForPaste } from './helpers';
import {
  AddContractModalContainer,
  ModalButtonContainer,
  ModalCloseIconStyled,
  ModalContentContainer,
  ModalFooter,
  ModalHeaderContract,
  ModalTitle,
  ModalTitleContainer,
  ModalWrapperContract,
  NameInputStyled,
  RowContainerForNameAndContractType,
} from './styles';
import { WorkerclassInputStyled } from '../styles';
import ContractModalsBottomSection from '../ContractModalsBottomSection';

const defaultDateError: IDateError = {
  found: false,
  foundDateInPresent: false,
  foundHourInPresent: false,
};

const AddContractModal: React.FC<AddContractModalProps> = props => {
  const {
    onClose,
    onSuccess,
    companyId,
    week,
    prefilledEmployeeId,
    prefilledDates,
    setSavedContract,
    weekDate,
    defaultBreakTime,
    contractToPaste,
  } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(profileSelector);

  const isEmployee = profile.role === CompanyRole.EMPLOYEE;
  const isWageVisible = profile.showWages;

  const { functions, costCenters, companyEmployees, locationsDropdownItems, costCentersWithDetails, weekDays } =
    useContractModalDropdownData(+companyId, isEmployee, week, weekDate, prefilledDates);

  const [employees, setEmployees] = useState<UPDropdownItem[]>([]);
  const [locations, setLocations] = useState<UPDropdownItem[]>(locationsDropdownItems);
  const [selectedEmployees, setSelectedEmployees] = useState<UPDropdownItem[]>([]);
  const [permanent, setPermanent] = useState<boolean>(false);
  const [dateRows, setDateRows] = useState<IWeekDaysRow[]>(generateDateRows(weekDays));

  const [currentStartWorkErrorIndexes, setCurrentStartWorkErrorIndexes] = useState<number[]>([]);
  const [currentEndWorkErrorIndexes, setCurrentEndWorkErrorIndexes] = useState<number[]>([]);
  const [hourFormatErrorIndex, setHourFormatErrorIndex] = useState<number>(-1);

  const dateRowRequiredErrorIndexes = useMemo(() => {
    return dateRows.reduce((acc, row, index) => {
      if (row.days.every(day => !day.checked)) {
        acc.push(index);
      }
      return acc;
    }, []);
  }, [dateRows]);

  const dateErrors: IDateError[] = useMemo(() => [{ ...defaultDateError }], []);

  const { handleSubmit, control, setValue, watch, clearErrors, setError, formState, reset } = useContractForm(
    functions,
    costCenters,
  );

  // handle name change
  const onSelectEmployee = useCallback(
    (value: UPDropdownItem[]) => {
      setSelectedEmployees(value);
      clearErrors();

      const selectedIds = value.map(item => +item.value);

      if (!selectedIds || selectedIds.length === 0) {
        return;
      }

      const selectedEmployeesWithDetails = companyEmployees.filter(employee => selectedIds.includes(employee.id));

      const locationArray = [];
      selectedEmployeesWithDetails.forEach(employee => locationArray.push(employee.locations));

      const locationIds = locationArray.reduce((a, b) => a.filter(c => b.includes(c)));
      const newLocations = locationsDropdownItems.filter(location => locationIds.includes(location.value));

      const employeesWithCommonLocations = companyEmployees.filter(employee =>
        employee.locations.some(location => locationIds.includes(location)),
      );
      const employeesWithCommonLocationsIds = employeesWithCommonLocations.map(employee => employee.id.toString());

      if (newLocations.length > 0) {
        setEmployees(items => items.filter(employee => employeesWithCommonLocationsIds.includes(employee.value)));
        setLocations(newLocations);
      } else {
        setLocations([]);
        setError(ContractFormFields.LOCATION_ID, { type: 'noCompanyLocation' });
      }
    },
    [clearErrors, companyEmployees, locationsDropdownItems, setError],
  );

  useEffect(() => {
    if (contractToPaste) {
      const formFieldsToPaste: IContractFormFields = {
        id: 0,
        employeeIds: [],
        workerclass: contractToPaste.workerclass,
        locationId: contractToPaste.locationId.toString(),
        costCenterId: contractToPaste.costCenterId,
        functionId: contractToPaste.functionId.toString(),
        wage: '',
        workingLocation: contractToPaste.workingLocation,
      };
      const newDateRows = generateDateRowsFromDatesForPaste(contractToPaste.dateRows, weekDays);
      reset({ ...formFieldsToPaste });
      setDateRows(newDateRows);
      for (let i = 1; i < newDateRows.length; i++) {
        dateErrors.push({ ...defaultDateError });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractToPaste, reset]);

  const locationWatch = watch(ContractFormFields.LOCATION_ID);
  const costCenterWatch = watch(ContractFormFields.COST_CENTER_ID);
  const functionWatch = watch(ContractFormFields.FUNCTION_ID);

  // handle workerclass and permanent status + filter employees based on the selected employee
  useEffect(() => {
    if (selectedEmployees.length === 1) {
      const selectedEmployee = companyEmployees.find(employee => employee.id === +selectedEmployees[0].value);
      if (!selectedEmployee) {
        return;
      }

      if (selectedEmployee.permanent) {
        setPermanent(true);
        setValue(`${ContractFormFields.WORKERCLASS}`, '');
      } else {
        setPermanent(false);
        setValue(`${ContractFormFields.WORKERCLASS}`, selectedEmployee.workerclass);
      }

      const filteredEmployees = companyEmployees.filter(employee => employee.permanent === selectedEmployee.permanent);
      const filteredEmployeeIds = filteredEmployees.map(employee => employee.id.toString());

      setEmployees(items => items.filter(employee => filteredEmployeeIds.includes(employee.value)));
      return;
    }
    if (selectedEmployees.length === 0) {
      setEmployees(generateEmployeesDropdownItems(companyEmployees));
      setValue(`${ContractFormFields.WORKERCLASS}`, '');
      setPermanent(false);
    }
  }, [companyEmployees, selectedEmployees, setValue]);

  // generate employees list from the company employees data
  useEffect(() => {
    if (companyEmployees) {
      const employeesList = generateEmployeesDropdownItems(companyEmployees);
      setEmployees(employeesList);
      if (prefilledEmployeeId && employeesList.length > 0) {
        const employee = employeesList.find(e => e.value === prefilledEmployeeId.toString());
        if (employee) {
          onSelectEmployee([employee]);
        }
      }
      if (contractToPaste && employeesList.length > 0) {
        const employee = employeesList.find(e => e.value === contractToPaste.employeeId.toString());
        if (employee) {
          onSelectEmployee([employee]);
        }
      }
    }
  }, [companyEmployees, contractToPaste, onSelectEmployee, prefilledEmployeeId]);

  // get the default employee if there is one
  const getDefaultEmployee = () => {
    let defaultEmployee: UPDropdownItem[] = [];
    if (prefilledEmployeeId) {
      const e = employees.find(employee => employee.value === prefilledEmployeeId.toString());
      if (e) {
        defaultEmployee = [e];
      }
    }
    if (contractToPaste) {
      const e = employees.find(employee => employee.value === contractToPaste.employeeId.toString());
      if (e) {
        defaultEmployee = [e];
      }
    }
    return defaultEmployee;
  };

  // clear the error messages when the modal is closed
  const onCloseModal = () => {
    onClose();
    dispatch(validateContractErrorMessageAction(''));
    dispatch(validateContractErrorMessageExtraInfoAction(''));
    dispatch(validateContractErrorMessageRowAction(0));
  };

  // get wage for the contract
  useEffect(() => {
    if (isWageVisible && functionWatch && selectedEmployees.length === 1) {
      const wageData: IWageData = {
        employeeId: +selectedEmployees[0].value,
        functionId: +functionWatch,
        companyId: +companyId,
      };
      dispatch(getWageAction(wageData)).then(response => {
        const wage = response.payload.toString();
        setValue(`${ContractFormFields.WAGE}`, wage);
      });
    }
  }, [companyId, dispatch, functionWatch, isWageVisible, selectedEmployees, setValue]);

  const isSaveDisabled = () => {
    const isDateWithError = dateErrors.some(
      error => error.found || error.foundHourInPresent || error.foundDateInPresent,
    );
    return (
      currentStartWorkErrorIndexes.length > 0 ||
      currentEndWorkErrorIndexes.length > 0 ||
      (dateErrors && isDateWithError)
    );
  };

  // add another button click event
  const addAnotherDateRow = () => {
    const newDateRows = [...dateRows];
    const newId = dateRows[dateRows.length - 1].id + 1;
    const newDays = weekDays.map(day => ({ ...day, checked: false }));
    const newHours: IHours = {
      startWork: '',
      startBreak: '',
      endBreak: '',
      endWork: '',
    };

    const newDateRow: IWeekDaysRow = {
      id: newId,
      days: newDays,
      hours: newHours,
    };

    newDateRows.push(newDateRow);

    dateErrors.push({ ...defaultDateError });

    setDateRows(newDateRows);
  };

  const deleteDateRow = (rowIndex: number) => {
    dateRows.splice(rowIndex, 1);
    dateErrors.splice(rowIndex, 1);
    setDateRows([...dateRows]);
  };

  // check day function
  const onCheckDay = (item: IWeekDays, rowIndex: number) => {
    const currentRow = dateRows[rowIndex];
    const currentDayIndex = currentRow.days.findIndex(day => day.day === item.day);
    const changedDay = { ...currentRow.days[currentDayIndex], checked: !item.checked };
    currentRow.days[currentDayIndex] = changedDay;
    setDateRows([...dateRows]);
  };

  const checkForStartAndEndWorkErrors = (value: string, type: keyof IHours, rowIndex: number) => {
    const currentTime = getCurrentTime();
    const currentMinutes = currentTime.minutes + 30 + currentTime.hours * 60;
    const itemMinutes = hoursToMinutes(value);
    if (dateErrors[rowIndex].foundDateInPresent && type === 'startWork' && itemMinutes < currentMinutes) {
      setCurrentStartWorkErrorIndexes([...currentStartWorkErrorIndexes, rowIndex]);
      return;
    }
    if (type === 'startWork' && !value) {
      setCurrentStartWorkErrorIndexes([...currentStartWorkErrorIndexes, rowIndex]);
      return;
    }
    if (type === 'endWork' && !value) {
      setCurrentEndWorkErrorIndexes([...currentEndWorkErrorIndexes, rowIndex]);
      return;
    }
    setCurrentStartWorkErrorIndexes(currentStartWorkErrorIndexes.filter(index => index !== rowIndex));
    setCurrentEndWorkErrorIndexes(currentEndWorkErrorIndexes.filter(index => index !== rowIndex));
  };

  // onChange function for hours
  const onChangeHours = (value: string, type: keyof IHours, rowIndex: number) => {
    const currentRow = dateRows[rowIndex];
    currentRow.hours[type] = value;
    setDateRows([...dateRows]);

    checkForStartAndEndWorkErrors(value, type, rowIndex);
  };

  // validate and save contract function
  const validateAndSaveContract: SubmitHandler<IContractFormFields> = (contractForm: IContractFormFields) => {
    const contractsToSave: IAddContract = {
      employees: [],
      locationId: contractForm.locationId,
      costCenterId: contractForm.costCenterId,
      functionId: contractForm.functionId,
      workingLocation: contractForm.workingLocation,
    };
    contractsToSave.employees = selectedEmployees.map((employee): IEmployeeDataPair => {
      const employeeId = +employee.value;
      const workerClass = companyEmployees.find(e => e.id === employeeId)?.workerclass;
      return { id: employeeId, workerclass: workerClass || '' };
    });

    const contractsForValidation = fromMultipleEmployeeContractToMultipleEmployeeContractRequest(
      contractsToSave,
      dateRows,
      week,
      weekDate.getFullYear(),
    );

    dispatch(
      validateAndSaveMultipleEmployeeContractAction({
        contract: contractsForValidation,
        companyId: +companyId,
      }),
    ).then(response => {
      if (response.payload) {
        onClose();

        if (setSavedContract) {
          setSavedContract(true);
        }

        const d = getDefaultEmployee();
        const employee = +d.length > 0 ? d[0].value : undefined;
        if (!employee) {
          return;
        }

        // this means that there is an employee selected through availabilities
        const currentEmployee = companyEmployees.find(e => e.id === employee);
        const selectedDays: number[] = [];

        dateRows.forEach(row => {
          row.days.forEach(day => {
            if (day.checked) {
              selectedDays.push(day.nativeDate.getDate());
            }
          });
        });

        onSuccess(selectedDays, currentEmployee);
      }
    });
  };

  // onSubmit function and validations
  const onSubmit = () => {
    let isSavePossible = true;

    const dateRowWithErrorsIndex = dateRows.findIndex(row => !row.hours.startWork || !row.hours.endWork);
    if (dateRowWithErrorsIndex !== -1) {
      setCurrentStartWorkErrorIndexes(
        !dateRows[dateRowWithErrorsIndex].hours.startWork ? [dateRowWithErrorsIndex] : [],
      );
      setCurrentEndWorkErrorIndexes(!dateRows[dateRowWithErrorsIndex].hours.endWork ? [dateRowWithErrorsIndex] : []);
      isSavePossible = false;
    } else {
      isSavePossible = areAllTimeSpansValid(dateRows, setHourFormatErrorIndex);
    }

    const isDateWithError = dateErrors.some(error => error.found);

    if (dateRowRequiredErrorIndexes.length === 0 && !isDateWithError && isSavePossible) {
      handleSubmit(validateAndSaveContract, onError)();
    }
  };

  return (
    <Backdrop open sx={{ zIndex: 1 }}>
      <ModalWrapperContract open>
        <AddContractModalContainer>
          <ModalHeaderContract>
            <ModalTitleContainer>
              <ModalTitle>{`${t(CONTRACTS__NEW_CONTRACT)} | ${t(HEADER__WEEK)} ${week}`} </ModalTitle>
              <ModalCloseIconStyled onClick={onCloseModal}>
                <CloseIcon fill={theme.palette.secondary.contrastText} />
              </ModalCloseIconStyled>
            </ModalTitleContainer>
            <RowContainerForNameAndContractType>
              <NameInputStyled expanded={selectedEmployees.length > 1}>
                <Controller
                  name={ContractFormFields.EMPLOYEE_IDS}
                  control={control}
                  render={({ field }) => (
                    <UPMultipleSelectAutocomplete
                      handleChange={(event, newValue: UPDropdownItem[]) => {
                        field.onChange(event);
                        onSelectEmployee(newValue);
                      }}
                      defaultValue={getDefaultEmployee()}
                      items={employees}
                      label={t(GENERAL__NAME)}
                      placeholder={t(GENERAL__SELECT)}
                      required
                    />
                  )}
                />
              </NameInputStyled>
              {selectedEmployees.length <= 1 && (
                <WorkerclassInputStyled>
                  <Controller
                    name={ContractFormFields.WORKERCLASS}
                    control={control}
                    render={({ field }) => (
                      <UPInput
                        value={field.value}
                        onChange={null}
                        disabled
                        label={t(GENERAL__WORKERCLASS)}
                        placeholder=""
                      />
                    )}
                  />
                </WorkerclassInputStyled>
              )}
            </RowContainerForNameAndContractType>
          </ModalHeaderContract>
          <ModalContentContainer>
            {dateRows.map((row, index) => (
              <ContractDateContainer key={`row ${row.id}`}>
                <AddDayPickerRow
                  week={week}
                  weekDate={weekDate}
                  weekDays={row.days}
                  onCheckDay={(selectedDay: IWeekDays) => onCheckDay(selectedDay, index)}
                  hours={row.hours}
                  onChangeHours={(value: string, type: keyof IHours) => onChangeHours(value, type, index)}
                  row={index}
                  error={dateRowRequiredErrorIndexes.includes(index)}
                  formatError={hourFormatErrorIndex}
                  dateErrors={dateErrors}
                  currentStartWorkError={currentStartWorkErrorIndexes.includes(index)}
                  currentEndWorkError={currentEndWorkErrorIndexes.includes(index)}
                  defaultBreakTime={defaultBreakTime}
                />
                <ContractXIcon onClick={() => deleteDateRow(index)}>
                  {dateRows.length > 1 && <CloseIcon fill={theme.palette.secondary.contrastText} />}
                </ContractXIcon>
              </ContractDateContainer>
            ))}
            <ModalButtonContainer>
              <UPButton text={t(CONTRACTS__ADD_ANOTHER)} onClick={addAnotherDateRow} outlined />
            </ModalButtonContainer>
            <ContractModalsBottomSection
              permanent={permanent}
              control={control}
              formState={formState}
              locations={locations}
              functions={functions}
              costCenters={costCenters}
              isWageVisible={isWageVisible && selectedEmployees.length <= 1}
              locationId={locationWatch}
              companyId={companyId}
              setValue={setValue}
              costCentersWithDetails={costCentersWithDetails}
              costCenterId={costCenterWatch}
            />
          </ModalContentContainer>
          <ModalFooter>
            <UPButton text={t(GENERAL__CREATE)} onClick={onSubmit} disabled={isSaveDisabled()} />
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCloseModal} outlined />
          </ModalFooter>
        </AddContractModalContainer>
      </ModalWrapperContract>
    </Backdrop>
  );
};

export default AddContractModal;
