import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { DocumentToSend } from 'modules/documents/types';
import { IDocument } from 'types/documents';
import IBlobWithName from 'types/documents/IBlobWithName';
import IDocumentCategories from 'types/documents/IDocumentCategories';

export const uploadDocumentsRequest = async (props: {
  documents: DocumentToSend[];
  companyId: number;
}): Promise<void> => {
  const { companyId, documents } = props;
  const config = { headers: { 'Content-Type': 'multipart/form-data', companyId } };
  const formData = new FormData();

  documents.forEach((item, index) => {
    formData.append(`models[${index}].File`, item.file);
    formData.append(`models[${index}].Category`, item.category);
    formData.append(`models[${index}].PeriodStart`, item.periodStart);
    formData.append(`models[${index}].PeriodEnd`, item.periodEnd);
    formData.append(`models[${index}].EmployeeId`, item.employeeId);
    formData.append(`models[${index}].IdUpload`, item.idUpload);
    formData.append(`models[${index}].IdUploadBack`, item.idUploadBack);
    formData.append(`models[${index}].IdCardNumber`, item.idCardNumber);
  });

  await getApi().post(`Documents`, formData, config);
};

export const updateStudentDeclarationRequest = async (props: {
  companyId: number;
  employeeId: number;
  startDate: Date;
  endDate: Date;
}): Promise<any> => {
  const { companyId, employeeId, startDate, endDate } = props;

  const response: AxiosResponse<any> = await getApi().post(
    `Documents/UpdateStudentDeclaration`,
    {
      employeeId,
      startDate,
      endDate,
    },
    {
      headers: {
        companyId,
      },
    },
  );
  return response.data;
};

export const getDocumentsRequest = async (props: {
  companyId: number;
  category: number | null;
  employeeId: number | null;
  startDate: Date | null;
  endDate: Date | null;
}): Promise<IDocument[]> => {
  const { companyId, category, startDate, endDate, employeeId } = props;
  const response: AxiosResponse<IDocument[]> = await getApi().post(
    'Documents/GetAll',
    { category, employeeId, startDate, endDate },
    { headers: { companyId } },
  );

  return response.data;
};

export const deleteDocumentRequest = async (props: { companyId: number; id: number }): Promise<any> => {
  const { companyId, id } = props;
  const response: AxiosResponse<number> = await getApi().delete(`Documents/${id}`, {
    headers: { companyId },
  });
  return response.data;
};

export const downloadDocumentRequest = async (props: { companyId: number; id: number }): Promise<any> => {
  const { companyId, id } = props;
  const response: AxiosResponse<IBlobWithName> = await getApi().get(`Documents/${id}`, {
    headers: { companyId },
    responseType: 'blob',
  });

  const contentDisposition = response.headers['content-disposition'];
  const regex = /filenames*=s*"?(.+)"?;/;
  const match = regex.exec(contentDisposition);

  const result: IBlobWithName = {
    name: match ? match[1] : 'default-filename',
    blob: response.data,
  };

  return result;
};

export const downloadMultipleDocumentRequest = async (props: { companyId: number; id: number[] }): Promise<any> => {
  const { companyId, id } = props;
  const url = `Documents/DownloadMultiple`;
  const headers = {
    'Content-Type': 'application/json',
    companyId,
  };

  const response: AxiosResponse<number> = await getApi().post(url, id, {
    headers,
    responseType: 'blob',
  });

  return response.data;
};

export const syncExpressoDocumentRequest = async (props: {
  companyId: number;
  startDate: Date;
  endDate: Date;
  addEmployeeDocs: boolean;
}): Promise<any> => {
  const { companyId, startDate, endDate, addEmployeeDocs } = props;

  const response: AxiosResponse<number> = await getApi().post(
    'Documents/SyncExpressoInvoices',
    { companyId, startDate, endDate, includeEmployeeDocuments: addEmployeeDocs },
    { headers: { companyId } },
  );

  return response.data;
};

export const updateEmployeeOnDocumentRequest = async (props: {
  documentId: number;
  employeeId: number;
  companyId: number;
}): Promise<any> => {
  const { documentId, employeeId, companyId } = props;

  const response: AxiosResponse<number> = await getApi().post(
    'Documents/UpdateEmployeeOnDocument',
    {
      documentId,
      employeeId,
    },
    { headers: { companyId } },
  );

  return response.data;
};

export const getDocumentCategoriesRequest = async (): Promise<IDocumentCategories> => {
  const response: AxiosResponse<IDocumentCategories> = await getApi().get('Documents/GetCategoriesByRole');
  return response.data;
};
