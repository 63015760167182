import { Backdrop, SelectChangeEvent, Typography } from '@mui/material';
import UPCheckbox from 'components/app-container/checkbox-with-label';
import UPButton from 'components/button';
import UPMultipleSelectWithCheckbox from 'components/multiple-select-with-checkbox/UPMultipleSelectWithCheckbox';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CONTRACTS__EDIT_BEFORE_PASTING,
  CONTRACTS__PASTE_HERE,
  CONTRACTS__WEEKS_TO_COPY_TO,
  GENERAL__CANCEL_BUTTON,
  GENERAL__SELECT,
} from 'translations/constants';
import { CopyMultipleModalStyled, CopyMultipleModalTitle, ModalActionContainer, ModalContainer } from './styles';
import { CopyMultipleModalProps } from './types';

const CopyMultipleModal = (props: CopyMultipleModalProps): JSX.Element => {
  const { open, onClose, items, onPaste, content, onEditBeforePaste, isForSingleWeekly } = props;

  const [t] = useTranslation();

  const [weeksToCopy, setWeeksToCopy] = useState<string[]>([]);
  const [editBeforePasting, setEditBeforePasting] = useState(false);
  const willEditBeforePasting = useMemo(
    () => editBeforePasting && weeksToCopy.length <= 1,
    [editBeforePasting, weeksToCopy],
  );

  const onWeekPickerChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setWeeksToCopy(typeof value === 'string' ? value.split(',') : value);
  };

  const onPasteHereClick = () => {
    if (weeksToCopy.length === 1 && willEditBeforePasting) {
      onEditBeforePaste(+weeksToCopy[0]);
      return;
    }
    onPaste(weeksToCopy.map(Number));
    onClose('');
  };

  return (
    <Backdrop open={open} sx={{ zIndex: '1000' }}>
      <CopyMultipleModalStyled open={open} onClose={onClose}>
        <ModalContainer>
          <CopyMultipleModalTitle>{content}</CopyMultipleModalTitle>
          {isForSingleWeekly && (
            <UPCheckbox
              label={<Typography fontWeight="bold">{t(CONTRACTS__EDIT_BEFORE_PASTING)}</Typography>}
              value={willEditBeforePasting}
              handleChange={() => setEditBeforePasting(!editBeforePasting)}
              disabled={weeksToCopy.length > 1}
              gtmEvent="contracts-edit-before-copy"
            />
          )}
          <UPMultipleSelectWithCheckbox
            items={items}
            label={t(CONTRACTS__WEEKS_TO_COPY_TO)}
            required
            selectedItems={weeksToCopy.sort()}
            onChange={onWeekPickerChange}
            placeholder={t(GENERAL__SELECT)}
          />
          <ModalActionContainer>
            <UPButton text={t(CONTRACTS__PASTE_HERE)} onClick={onPasteHereClick} disabled={weeksToCopy.length === 0} />
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </ModalActionContainer>
        </ModalContainer>
      </CopyMultipleModalStyled>
    </Backdrop>
  );
};

export default CopyMultipleModal;
