import { Backdrop, Box, Stack, Typography } from '@mui/material';
import UPInput from 'components/input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import {
  GENERAL__CANCEL_BUTTON,
  GENERAL__FIRST_NAME,
  GENERAL__LAST_NAME,
  GENERAL__SAVE_BUTTON,
  COMPANY_INFORMATION__ACTIVE,
  COMPANY_INFORMATION__NON_ACTIVE,
  GENERAL__SELECT,
  COMPANY__COMPANY,
  LOGIN__PASSWORD_MIN_LENGTH_ERROR,
  LOGIN__PASSWORD_LABEL,
  GENERAL__USERNAME,
  PROFILE__NEW_PASSWORD,
} from 'translations/constants';
import { postApiUserAction, updateApiUserAction } from 'store/actions/superadmin-actions';
import { getInputErrorText } from 'utils/helpers';
import { useCallback } from 'react';
import UPMultiselectDropdown from 'components/app-container/multiselect-dropdown';
import { useAppDispatch, useAppSelector } from 'store';
import { getApiUsersAction } from 'store/actions/superadmins-actions';
import UPSwitch from 'components/switch';
import { companiesDropdownItemsSelector } from 'store/selectors/company-selectors';
import { UPDropdownItem } from 'components/dropdown';
import IUser from 'types/superadmin/ISuperAdmin';
import {
  PayrollEditFunctionModal,
  PayrollEditFunctionModalActionButton,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalTitle,
} from 'modules/companies/payroll/styles';
import { AuthUPInput } from 'modules/auth/styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { EditSuperAdminProps } from './types';

const SuperAdminInitialState: IUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  languageId: '',
  roleId: undefined,
  phoneNumber: '',
  active: true,
  hasTwoFactorAuth: false,
  password: '',
};

const EditApiUser = (props: EditSuperAdminProps): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { open, onClose, superAdmin, title, isEdit } = props;

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<IUser>({
    defaultValues: superAdmin || SuperAdminInitialState,
  });

  const companiesDropdownItems = useAppSelector(companiesDropdownItemsSelector);

  const getPasswordErrorText = useCallback(() => {
    if (errors.password?.type === 'minLength') {
      return t(LOGIN__PASSWORD_MIN_LENGTH_ERROR);
    }

    return getInputErrorText(errors, 'password', undefined, 100, false, 8, false, true);
  }, [t, errors]);

  const onSaveSuperAdmin: SubmitHandler<IUser> = () => {
    const values = getValues();
    values.roleId = 6;
    values.languageId = 1;

    if (!values.selectedCompanies || values.selectedCompanies?.length === 0) {
      setError('selectedCompanies', { type: 'required' });
      return;
    }

    if (isEdit) {
      dispatch(updateApiUserAction(values)).then(() => dispatch(getApiUsersAction()));
    } else {
      dispatch(postApiUserAction(values)).then(() => dispatch(getApiUsersAction()));
    }
    onClose();
  };

  const getSelectedValues = (selectedCompanies: number[]): UPDropdownItem[] => {
    if (!selectedCompanies || selectedCompanies.length === 0) return [];
    return companiesDropdownItems
      .filter(item => selectedCompanies.includes(item.value))
      .map(item => ({ value: item.value, label: item.label }));
  };

  return (
    <Backdrop open={open}>
      <PayrollEditFunctionModal open={open} onClose={onClose} aria-labelledby="modal-edit-payroll-function">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>{title}</PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn sx={{ marginRight: '16px' }}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__FIRST_NAME)}
                      label={t(GENERAL__FIRST_NAME)}
                      required
                      error={!!errors.firstName}
                      helperText={getInputErrorText(errors, 'firstName', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__LAST_NAME)}
                      label={t(GENERAL__LAST_NAME)}
                      required
                      error={!!errors.lastName}
                      helperText={getInputErrorText(errors, 'lastName', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__USERNAME)}
                      label={t(GENERAL__USERNAME)}
                      required
                      error={!!errors.email}
                      helperText={getInputErrorText(errors, 'email', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: !superAdmin?.id,
                    minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
                  }}
                  render={({ field }) => (
                    <AuthUPInput
                      value={field.value}
                      label={superAdmin?.id > 0 ? t(PROFILE__NEW_PASSWORD) : t(LOGIN__PASSWORD_LABEL)}
                      placeholder={superAdmin?.id > 0 ? '* * * * *' : t(LOGIN__PASSWORD_LABEL)}
                      type="password"
                      required={!superAdmin?.id}
                      error={!!errors.password}
                      helperText={getPasswordErrorText()}
                      onChange={field.onChange}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
            <PayrollEditFunctionModalColumn>
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <Stack direction="row" spacing={1} alignItems="center" pt="25px">
                    <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__NON_ACTIVE)}</Typography>
                    <UPSwitch checked={field.value} onChange={field.onChange} />
                    <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__ACTIVE)}</Typography>
                  </Stack>
                )}
              />

              <Box sx={{ width: '100%', paddingTop: '10px', paddingBottom: '10px' }}>
                <Controller
                  name="selectedCompanies"
                  control={control}
                  rules={{ required: !superAdmin?.id }}
                  render={({ field }) => (
                    <UPMultiselectDropdown
                      handleChange={e => {
                        if (e) {
                          setValue(
                            'selectedCompanies',
                            e.map(i => i.value),
                          );
                        } else {
                          setValue('selectedCompanies', []);
                        }
                      }}
                      items={companiesDropdownItems}
                      label={t(COMPANY__COMPANY)}
                      defaultValue={getSelectedValues(field.value)}
                      placeholder={t(GENERAL__SELECT)}
                    />
                  )}
                />
              </Box>
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            <PayrollEditFunctionModalActionButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={() => handleSubmit(onSaveSuperAdmin)()}
            />
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

export default EditApiUser;
