import { Box } from '@mui/system';
import UPDayPicker from 'components/day-picker';
import UPDayPickerDisplay from 'components/day-picker-display';
import UPDropdownWithInput from 'components/dropdown-with-input/UPDropdownWithInput';
import { CompanyRole } from 'constants/CompanyRole';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import {
  validateContractErrorMessageRowSelector,
  validateContractErrorMessageSelector,
  validateContractExtraMessageSelector,
} from 'store/selectors/contract-selectors';
import {
  validateEditContractErrorMessageRowSelector,
  validateEditContractErrorMessageSelector,
} from 'store/selectors/contracts-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  CONTRACTS__DATE,
  CONTRACTS__DATES,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_HOUR_ERROR,
  CONTRACTS__END_WORK,
  CONTRACTS__INVALID_HOUR_ERROR,
  CONTRACTS__PRESENT_START_HOUR_ERROR,
  CONTRACTS__START_BREAK,
  CONTRACTS__START_WORK,
} from 'translations/constants';
import HOUR_OPTIONS from './HourDropdownOptions';
import { filterByHour } from './helpers';
import { EndWorkContainer } from './styles';
import { AddEditDayPickerRowProps } from './types';
import {
  RowContainerForContractDate,
  DateDropdownContainer,
  HourDropdownContainer,
  DayPickerHoursSecondError,
  DayPickerHoursError,
  DayPickerEndHourError,
} from './modals/styles';

const AddEditDayPickerRow = (props: AddEditDayPickerRowProps) => {
  const {
    week,
    weekDays,
    onCheckDay,
    hours,
    onChangeHours,
    error,
    row,
    currentDateError,
    currentStartWorkError,
    currentEndWorkError,
    formatError,
    isEdit,
    isSlot,
    weekDate,
    defaultBreakTime,
  } = props;
  const [t] = useTranslation();
  const editContractErrorMesage = useAppSelector(validateEditContractErrorMessageSelector);
  const invalidEditRow = useAppSelector(validateEditContractErrorMessageRowSelector);
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;
  const invalidRowNumber = useAppSelector(validateContractErrorMessageRowSelector);
  const invalidContractError = useAppSelector(validateContractErrorMessageSelector);
  const contractValidationExtraInfo = useAppSelector(validateContractExtraMessageSelector);

  const [translatedError, setTranslatedError] = useState<string>('');

  const addTimespans = (time1: string, time2: string): string => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    let totalMinutes = minutes1 + minutes2;
    let totalHours = hours1 + hours2;

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    totalHours %= 24;

    const formattedHours = String(totalHours).padStart(2, '0');
    const formattedMinutes = String(totalMinutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    if (invalidContractError) {
      setTranslatedError(`${t(invalidContractError)} ${contractValidationExtraInfo ?? ''}`);
    }
    if (isEdit && editContractErrorMesage) {
      setTranslatedError(`${t(editContractErrorMesage)} ${contractValidationExtraInfo ?? ''}`);
    }
  }, [editContractErrorMesage, invalidContractError, contractValidationExtraInfo, isEdit, t]);

  const getEndBreakTime = (): string => {
    if (defaultBreakTime && hours.startBreak) {
      hours.endBreak = addTimespans(hours.startBreak, defaultBreakTime);
    }

    return hours.endBreak;
  };
  if (!isEdit) {
    return (
      <Box>
        <RowContainerForContractDate>
          <DateDropdownContainer>
            <UPDayPicker
              label={t(CONTRACTS__DATES)}
              week={week}
              required
              weekDays={weekDays}
              handleDaySelect={onCheckDay}
              error={error || currentDateError[row].found}
              currentDateError={currentDateError[row].found}
              weekForYear={weekDate}
            />
          </DateDropdownContainer>

          <HourDropdownContainer>
            <UPDropdownWithInput
              withSearchIcon={false}
              value={hours.startWork}
              handleChange={onChangeHours}
              filterOptions={filterByHour}
              placeholder=""
              items={HOUR_OPTIONS}
              label={t(CONTRACTS__START_WORK)}
              required
              type="startWork"
              error={currentStartWorkError}
            />
          </HourDropdownContainer>
          <HourDropdownContainer>
            <UPDropdownWithInput
              value={hours.startBreak}
              handleChange={onChangeHours}
              filterOptions={filterByHour}
              placeholder=""
              items={HOUR_OPTIONS}
              label={t(CONTRACTS__START_BREAK)}
              type="startBreak"
            />
          </HourDropdownContainer>
          <HourDropdownContainer>
            <UPDropdownWithInput
              value={getEndBreakTime()}
              handleChange={onChangeHours}
              filterOptions={filterByHour}
              placeholder=""
              items={HOUR_OPTIONS}
              label={t(CONTRACTS__END_BREAK)}
              type="endBreak"
            />
          </HourDropdownContainer>
          <EndWorkContainer>
            <UPDropdownWithInput
              value={hours.endWork}
              handleChange={onChangeHours}
              filterOptions={filterByHour}
              placeholder=""
              items={HOUR_OPTIONS}
              label={t(CONTRACTS__END_WORK)}
              required
              type="endWork"
              error={currentEndWorkError}
            />
          </EndWorkContainer>
        </RowContainerForContractDate>
        {row === invalidRowNumber && <DayPickerHoursError>{translatedError}</DayPickerHoursError>}
        {currentStartWorkError && <DayPickerHoursError>{t(CONTRACTS__PRESENT_START_HOUR_ERROR)}</DayPickerHoursError>}
        {currentEndWorkError && <DayPickerEndHourError>{t(CONTRACTS__END_HOUR_ERROR)}</DayPickerEndHourError>}
        {row === formatError && <DayPickerHoursError>{t(CONTRACTS__INVALID_HOUR_ERROR)}</DayPickerHoursError>}
      </Box>
    );
  }

  return (
    <Box>
      {weekDays.map((day: any, index: number) => (
        <Box>
          <RowContainerForContractDate key={day.date}>
            <DateDropdownContainer>
              <UPDayPickerDisplay label={t(CONTRACTS__DATE)} day={new Date(day.date)} />
            </DateDropdownContainer>
            <HourDropdownContainer>
              <UPDropdownWithInput
                withSearchIcon={false}
                value={day.startHour}
                handleChange={(value: string) => onChangeHours(value, 'startHour', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__START_WORK)}
                required
                type="startWork"
                error={currentStartWorkError}
                disabled={isEmployee}
              />
            </HourDropdownContainer>
            <HourDropdownContainer>
              <UPDropdownWithInput
                value={day.startBreak}
                handleChange={(value: string) => onChangeHours(value, 'startBreak', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__START_BREAK)}
                type="startBreak"
                disabled={isEmployee}
              />
            </HourDropdownContainer>
            <HourDropdownContainer>
              <UPDropdownWithInput
                value={day.endBreak}
                handleChange={(value: string) => onChangeHours(value, 'endBreak', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__END_BREAK)}
                type="endBreak"
                disabled={isEmployee}
              />
            </HourDropdownContainer>
            <EndWorkContainer>
              <UPDropdownWithInput
                value={day.endHour}
                handleChange={(value: string) => onChangeHours(value, 'endHour', index)}
                filterOptions={filterByHour}
                placeholder=""
                items={HOUR_OPTIONS}
                label={t(CONTRACTS__END_WORK)}
                required
                type="endWork"
                disabled={isEmployee}
                error={currentEndWorkError}
              />
            </EndWorkContainer>
          </RowContainerForContractDate>
          {index === invalidEditRow && <DayPickerHoursSecondError>{translatedError}</DayPickerHoursSecondError>}
          {currentStartWorkError && <DayPickerHoursError>{t(CONTRACTS__PRESENT_START_HOUR_ERROR)}</DayPickerHoursError>}
          {currentEndWorkError && <DayPickerHoursError>{t(CONTRACTS__END_HOUR_ERROR)}</DayPickerHoursError>}
          {index === formatError && <DayPickerHoursError>{t(CONTRACTS__INVALID_HOUR_ERROR)}</DayPickerHoursError>}
        </Box>
      ))}
    </Box>
  );
};

export default AddEditDayPickerRow;
