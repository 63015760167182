import { Alert, Box, FormControlLabel, SelectChangeEvent } from '@mui/material';
import UPButton from 'components/button';
import UPInput from 'components/input';
import UPMultipleSelectWithCheckbox from 'components/multiple-select-with-checkbox/UPMultipleSelectWithCheckbox';
import { AvailabilityIntervalsFormat } from 'constants/AvailabilityIntervalsFormat';
import { EmployerLabel } from 'constants/EmployerLabel';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { getParitairComiteSelectItemsAction } from 'store/actions/paritair-comite-actions';
import {
  addPayrollAction,
  addPayrollErrorAction,
  addPayrollSuccessAction,
  getPayrollAction,
  updatePayrollAction,
  updatePayrollErrorAction,
  updatePayrollSuccessAction,
} from 'store/actions/payroll-actions';
import { paritairComiteSelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import {
  addPayrollErrorSelector,
  addPayrollSuccessSelector,
  payrollSelector,
  updatePayrollErrorSelector,
  updatePayrollSuccessSelector,
} from 'store/selectors/payroll-selectors';
import { getUpDropdownItemsFromEnum } from 'utils/helpers';
import UPSwitch from 'components/switch/UPSwitch';
import ICoefficientPerWorkerclass from 'types/company/payroll/ICoefficientPerWorkerclass';
import UPDropdownWithInput from 'components/dropdown-with-input';
import IEmployeeMotive from 'types/company/payroll/IMotivePerEmployee';
import IContractMotive from 'types/contract/IContractMotive';
import { employeesSelector } from 'store/selectors/employee-selector';
import { getAllEmployeesAction } from 'store/actions/employee-actions';
import { generateEmployeesArray } from 'modules/employees/helpers';
import MotivePerEmployeeGrid from './MotivePerEmployeeGrid';
import { CoefficientsPerWorkerclassGrid } from '.';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { UPDropdown, UPDropdownItem, UPMultipleSelectionDropdown } from '../../../components/dropdown/index';
import {
  COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY,
  COMPANY_PAYROLL__AFTERNOON_INTERVAL,
  COMPANY_PAYROLL__DEFAULT_BREAKTIME,
  COMPANY_PAYROLL__DEFAULT_CONTRACT_MOTIVE,
  COMPANY_PAYROLL__EMPLOYER_LABEL,
  COMPANY_PAYROLL__ENABLE_MOTIVE_EXEMPTION,
  COMPANY_PAYROLL__EVENING_INTERVAL,
  COMPANY_PAYROLL__INVALID_DEFAULT_BREAKTIME_FORMAT,
  COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT,
  COMPANY_PAYROLL__INVALID_MOTIVE,
  COMPANY_PAYROLL__MORNING_INTERVAL,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  COMPANY_PAYROLL__POSSIBLE_CONTRACT_MOTIVES,
  COMPANY_PAYROLL__WORKHOURS_SETTINGS,
  GENERAL__CANCEL_BUTTON,
  GENERAL__ERROR_MESSAGE,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  VALIDATION__IS_REQUIRED,
} from '../../../translations/constants';
import ICompanyPayrollGeneral from '../../../types/company/payroll/ICompanyPayrollGeneral';
import { areAvailabilityIntervalsValid, isDefaultBreakTimeValid } from '../helpers';
import { CloseAlertIcon } from '../styles';
import { ErrorText, PayrollContainer, PayrollInputLabel } from './styles';
import BREAK_INTERVAL_HOUR_OPTIONS from './PayrollDefaultBreakIntervalOptions';

const PayrollGeneralInitialState: ICompanyPayrollGeneral = {
  paritairComites: [],
  coefficientsPerWorkerclass: [],
  contractMotives: [],
  possibleMotives: [],
  defaultContractMotive: '',
  motivePerEmployee: [],
  id: 0,
  companyId: 0,
  employerLabel: '',
  morningInterval: '',
  afternoonInterval: '',
  eveningInterval: '',
  defaultBreakTime: '',
};

const PayrollGeneral = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const paritairComiteOptions = useAppSelector(paritairComiteSelectItemsSelector);
  const payroll = useAppSelector(payrollSelector);
  const addPayrollError = useAppSelector(addPayrollErrorSelector);
  const updatePayrollError = useAppSelector(updatePayrollErrorSelector);
  const addPayrollSuccess = useAppSelector(addPayrollSuccessSelector);
  const updatePayrollSuccess = useAppSelector(updatePayrollSuccessSelector);

  const [coefficientsPerWorkerclass, setCoefficientsPerWorkerclass] = useState<ICoefficientPerWorkerclass[]>([]);
  const [contractMotives, setContractMotives] = useState<IContractMotive[]>([]);

  const [enableEmployeeMotiveExemption, setEnableEmployeeMotiveExemption] = useState<boolean>(false);
  const [motivePerEmployees, setMotivePerEmployees] = useState<IEmployeeMotive[]>([]);

  const contractMotivesItems = useMemo(() => {
    return contractMotives.map(cm => ({ value: cm.code.toString(), label: t(cm.nameConstant) }));
  }, [contractMotives, t]);

  const possibleContractMotivesItems = useMemo(() => {
    return contractMotives
      .filter(cm => cm.isPossible)
      .map(cm => ({ value: cm.code.toString(), label: t(cm.nameConstant) }));
  }, [contractMotives, t]);

  const employerLabelOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsFromEnum(EmployerLabel);
  }, []);

  const employees = generateEmployeesArray(useAppSelector(employeesSelector));
  useEffect(() => {
    if (companyId) {
      dispatch(getAllEmployeesAction({ companyId, active: 1 }));
    }
  }, [dispatch, companyId]);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<ICompanyPayrollGeneral>({
    defaultValues: PayrollGeneralInitialState,
  });

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPayrollAction(toNumber(companyId)));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (payroll) {
      setContractMotives(payroll.contractMotives);
      setEnableEmployeeMotiveExemption(false);
    }
    if (payroll && payroll.id !== 0) {
      reset(payroll);
      setCoefficientsPerWorkerclass(payroll.coefficientsPerWorkerclass);
      setMotivePerEmployees(payroll.motivePerEmployee);
      if (payroll.motivePerEmployee.length > 0) {
        setEnableEmployeeMotiveExemption(true);
      }
    }
  }, [payroll, reset]);

  useEffect(() => {
    return () => {
      dispatch(addPayrollSuccessAction(false));
      dispatch(updatePayrollSuccessAction(false));
      dispatch(addPayrollErrorAction(false));
      dispatch(updatePayrollErrorAction(false));
    };
  }, [dispatch]);

  const onSavePayroll: SubmitHandler<any> = useCallback(() => {
    const values = getValues();
    const morningIntervalEnds = values.morningInterval.split(' - ');
    const afternoonIntervalEnds = values.afternoonInterval.split(' - ');
    const eveningIntervalEnds = values.eveningInterval.split(' - ');
    if (enableEmployeeMotiveExemption) {
      values.motivePerEmployee = motivePerEmployees.map(c => ({
        id: Number.isInteger(c.id) ? c.id : 0,
        employeeId: toNumber(c.employeeId),
        motiveId: toNumber(c.motiveId),
      }));
    } else {
      values.motivePerEmployee = [];
    }
    const areIntervalsValid = areAvailabilityIntervalsValid(
      morningIntervalEnds,
      afternoonIntervalEnds,
      eveningIntervalEnds,
      setError,
      t,
    );

    const isDefaultBreakValid = isDefaultBreakTimeValid(values.defaultBreakTime, setError, t);

    if (!areIntervalsValid || !isDefaultBreakValid) {
      return;
    }

    if (!values.possibleMotives.includes(values.defaultContractMotive)) {
      setError('defaultContractMotive', { type: 'required' });
      return;
    }

    const allMotivesFromExemptions = values.motivePerEmployee.map(c => c.motiveId.toString());
    if (!allMotivesFromExemptions.every(mpe => values.possibleMotives.includes(mpe))) {
      setError('motivePerEmployee', { type: 'invalidMotive' });
      return;
    }

    if (values.paritairComites.length === 0) {
      setError('paritairComites', { type: 'required' });
      return;
    }
    values.companyId = toNumber(companyId);
    if (values.id === 0) {
      values.coefficientsPerWorkerclass = coefficientsPerWorkerclass.map(c => ({
        ...c,
        id: 0,
      }));
      dispatch(addPayrollAction(values));
    } else {
      values.coefficientsPerWorkerclass = coefficientsPerWorkerclass;
      dispatch(updatePayrollAction(values));
    }
    if (!addPayrollError && !updatePayrollError && !enableEmployeeMotiveExemption) {
      setMotivePerEmployees([]);
    }
  }, [
    coefficientsPerWorkerclass,
    companyId,
    dispatch,
    getValues,
    motivePerEmployees,
    setError,
    t,
    enableEmployeeMotiveExemption,
    addPayrollError,
    updatePayrollError,
  ]);

  const onCancel = useCallback(() => {
    reset(payroll);
    navigate(`/company/${companyId}/general`);
  }, [companyId, navigate, payroll, reset]);

  const filterByHour = (options: UPDropdownItem[], state: { inputValue: string }) => {
    return options.filter(option => option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()));
  };

  const onContractMotiveSelect = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setContractMotives(
      contractMotives.map(cm => ({
        ...cm,
        isPossible:
          typeof value === 'string'
            ? value.split(',').includes(cm.code.toString())
            : value.includes(cm.code.toString()),
      })),
    );
  };

  return (
    <Box sx={{ padding: '32px 32px 0px 32px' }}>
      <PayrollContainer>
        <Box sx={{ marginBottom: '32px' }}>
          <Controller
            name="paritairComites"
            control={control}
            rules={{ required: { value: true, message: t(VALIDATION__IS_REQUIRED) } }}
            render={({ field }) => (
              <UPMultipleSelectionDropdown
                items={paritairComiteOptions}
                value={field.value}
                handleChange={field.onChange}
                placeholder={t(GENERAL__SELECT)}
                dropdownLabel={t(COMPANY_PAYROLL__PARITAIR_COMITE)}
                required
                error={!!errors.paritairComites}
                helperText={t(VALIDATION__IS_REQUIRED)}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '32px' }}>
          <CoefficientsPerWorkerclassGrid
            coefficientsPerWorkerclass={coefficientsPerWorkerclass}
            setCoefficientsPerWorkerclass={setCoefficientsPerWorkerclass}
          />
        </Box>

        <Box sx={{ marginBottom: '32px' }}>
          <Controller
            name="employerLabel"
            control={control}
            rules={{ required: { value: true, message: t(VALIDATION__IS_REQUIRED) } }}
            render={({ field }) => (
              <UPDropdown
                items={employerLabelOptions}
                value={field.value}
                onChange={field.onChange}
                placeholder={t(GENERAL__SELECT)}
                label={t(COMPANY_PAYROLL__EMPLOYER_LABEL)}
                error={!!errors.employerLabel}
                helperText={errors.employerLabel?.message}
                required
              />
            )}
          />
        </Box>

        <Box sx={{ marginTop: '16px' }}>
          <Controller
            name="possibleMotives"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UPMultipleSelectWithCheckbox
                items={contractMotivesItems}
                selectedItems={field.value}
                onChange={event => {
                  field.onChange(event.target.value);
                  onContractMotiveSelect(event);
                }}
                placeholder={t(GENERAL__SELECT)}
                label={t(COMPANY_PAYROLL__POSSIBLE_CONTRACT_MOTIVES)}
                required
                error={!!errors.possibleMotives}
                helperText={errors.possibleMotives && t(VALIDATION__IS_REQUIRED)}
              />
            )}
          />
        </Box>

        <Box sx={{ marginTop: '16px' }}>
          <Controller
            name="defaultContractMotive"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UPDropdown
                items={possibleContractMotivesItems}
                value={field.value}
                onChange={field.onChange}
                placeholder={t(GENERAL__SELECT)}
                label={t(COMPANY_PAYROLL__DEFAULT_CONTRACT_MOTIVE)}
                error={!!errors.defaultContractMotive}
                helperText={errors.defaultContractMotive && t(VALIDATION__IS_REQUIRED)}
                required
              />
            )}
          />
        </Box>
        <FormControlLabel
          control={
            <UPSwitch
              checked={enableEmployeeMotiveExemption}
              onChange={event => {
                setEnableEmployeeMotiveExemption(!enableEmployeeMotiveExemption);
                clearErrors('motivePerEmployee');
              }}
            />
          }
          label={<span>{t(COMPANY_PAYROLL__ENABLE_MOTIVE_EXEMPTION)}</span>}
          sx={{
            marginTop: '16px',
            '.MuiTypography-root': {
              fontSize: '14px',
              marginLeft: '10px',
              marginTop: '2px',
            },
            '&.MuiFormControlLabel-root': {
              marginBottom: '8px',
              marginLeft: '0px',
            },
          }}
        />
        {enableEmployeeMotiveExemption && (
          <Box sx={{ marginBottom: '32px' }}>
            <MotivePerEmployeeGrid
              motivePerEmployees={motivePerEmployees}
              setMotivePerEmployees={setMotivePerEmployees}
              contractMotives={possibleContractMotivesItems}
              employees={employees}
              clearErrors={clearErrors}
            />
            <Box sx={{ marginTop: '5px' }}>
              {errors.motivePerEmployee && <ErrorText>{t(COMPANY_PAYROLL__INVALID_MOTIVE)}</ErrorText>}
            </Box>
          </Box>
        )}

        <Box>
          <Box sx={{ marginTop: '32px' }}>
            <PayrollInputLabel>{t(COMPANY_PAYROLL__WORKHOURS_SETTINGS)}</PayrollInputLabel>
            <Box sx={{ marginTop: '16px' }}>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="defaultBreakTime"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^(0[0-6]:[0-5][0-9])?$/,
                      message: t(COMPANY_PAYROLL__INVALID_DEFAULT_BREAKTIME_FORMAT),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPDropdownWithInput
                      value={value}
                      handleChange={onChange}
                      filterOptions={filterByHour}
                      placeholder=""
                      items={BREAK_INTERVAL_HOUR_OPTIONS}
                      label={t(COMPANY_PAYROLL__DEFAULT_BREAKTIME)}
                      type="defaultBreakTime"
                      error={!!errors.defaultBreakTime}
                      helperText={errors.defaultBreakTime?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="morningInterval"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/,
                      message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT),
                    },
                    required: { value: true, message: t(VALIDATION__IS_REQUIRED) },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value || ''}
                      onChange={onChange}
                      placeholder={AvailabilityIntervalsFormat}
                      label={t(COMPANY_PAYROLL__MORNING_INTERVAL)}
                      error={!!errors.morningInterval}
                      helperText={errors.morningInterval?.message}
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="afternoonInterval"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/,
                      message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT),
                    },
                    required: { value: true, message: t(VALIDATION__IS_REQUIRED) },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value || ''}
                      onChange={onChange}
                      placeholder={AvailabilityIntervalsFormat}
                      label={t(COMPANY_PAYROLL__AFTERNOON_INTERVAL)}
                      error={!!errors.afternoonInterval}
                      helperText={errors.afternoonInterval?.message}
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="eveningInterval"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/,
                      message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT),
                    },
                    required: { value: true, message: t(VALIDATION__IS_REQUIRED) },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value || ''}
                      onChange={onChange}
                      placeholder={AvailabilityIntervalsFormat}
                      label={t(COMPANY_PAYROLL__EVENING_INTERVAL)}
                      error={!!errors.eveningInterval}
                      helperText={errors.eveningInterval?.message}
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '32px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Box sx={{ marginRight: '16px' }}>
                  <UPButton
                    text={t(GENERAL__SAVE_BUTTON)}
                    onClick={() => handleSubmit(onSavePayroll, error => console.log(error))()}
                  />
                </Box>
                <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} outlined />
              </Box>
            </Box>
          </Box>
        </Box>
      </PayrollContainer>
      <Box sx={{ marginTop: '32px' }}>
        {(addPayrollSuccess || updatePayrollSuccess) && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(addPayrollSuccessAction(false));
                  dispatch(updatePayrollSuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY)}
          </Alert>
        )}
        {(addPayrollError || updatePayrollError) && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(addPayrollErrorAction(false));
                  dispatch(updatePayrollErrorAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(GENERAL__ERROR_MESSAGE)}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default PayrollGeneral;
