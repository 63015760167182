import { Box, Typography } from '@mui/material';
import UPButton from 'components/button';
import { toNumber } from 'lodash';
import { AvailabilitiesAlertStyled } from 'modules/availabilities/employee-availabilities/green-bar/styles';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { directlyCardToCopyAction, selectedContractsAction } from 'store/actions/contract-actions';
import {
  contractDateToCopySelector,
  getShouldCopyContractsSelector,
  isCopyModeEnabledSelector,
  totalContractCountSelector,
} from 'store/selectors/contracts-selector';
import theme from 'theme';
import { CONTRACTS__FETCH_MORE } from 'translations/constants';
import { GridHeader } from '..';
import { sortedContracts } from '../helpers';
import { CardsDivider, GridColumn, GridContainer } from '../styles';
import { IContractData, IContractsGridViewProps, IDayProps } from '../types';
import ContractCard from './ContractCard/ContractCard';
import PasteHereCard from './PasteHereCard';

const ContractsGridView = (props: IContractsGridViewProps): JSX.Element => {
  const {
    contracts,
    onChangeContracts,
    setOpenConfirmModal,
    onPaste,
    days,
    onChangeWeek,
    onClickEditCard,
    onClickFillSlot,
    onDeleteCheckedContracts,
    companyId,
    year,
    weekNumber,
    filters,
    copyWeekly,
    getMoreContracts,
  } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const copy = useAppSelector(getShouldCopyContractsSelector);
  const isCopyModeEnabled = useAppSelector(isCopyModeEnabledSelector);
  const contractDateToCopy = useAppSelector(contractDateToCopySelector);
  const contractPageDetails = useAppSelector(totalContractCountSelector);
  const [displayFetchButton, setDisplayFetchButton] = useState<boolean>(false);

  const onChangeCardCheck = useCallback(
    contractId => {
      const changedContracts = contracts.map((card: IContractData) => {
        if (card.contractId === contractId) {
          return {
            ...card,
            checked: !card.checked,
          };
        }
        return card;
      });
      const checkedContracts = changedContracts.filter((c: IContractData) => c.checked);

      dispatch(selectedContractsAction({ selectedContracts: checkedContracts }));
      onChangeContracts(changedContracts);
    },
    [contracts, dispatch, onChangeContracts],
  );

  const checkADayContracts = (date: Date, checked: boolean) => {
    const selectedWeeklyIds: string[] = [];
    const updatedContracts = contracts.map((c: IContractData) => {
      const cDate = new Date(c.date);
      if (cDate.getDate() === date.getDate() && cDate.getMonth() === date.getMonth() && !c.employee?.inactive) {
        if (c.weeklyId) {
          selectedWeeklyIds.push(c.weeklyId);
        }
        return {
          ...c,
          checked,
        };
      }
      return c;
    });

    const allUpdatedContracts = updatedContracts.map((c: IContractData) => {
      if (selectedWeeklyIds.includes(c.weeklyId)) {
        return {
          ...c,
          checked,
        };
      }
      return c;
    });

    const checkedContracts = allUpdatedContracts.filter((c: IContractData) => c.checked);

    // if (checkedContracts.length === 0 && copy) {
    //   // dispatch(shouldCopyAction({ copy: false }));
    // }
    dispatch(selectedContractsAction({ selectedContracts: checkedContracts }));
    onChangeContracts(allUpdatedContracts);
  };

  const onCheckDay = (date: Date, isDayChecked: boolean) => {
    days.forEach((day: IDayProps) => {
      if (day.date.getDate() === date.getDate() && day.date.getMonth() === date.getMonth()) {
        checkADayContracts(date, isDayChecked);
      }
    });
  };

  const getContract = useCallback(
    (firstActiveContractId, contract) => {
      return (
        <Fragment key={contract.id}>
          {firstActiveContractId === contract.id && <CardsDivider />}
          {/* <GridCard */}
          <ContractCard
            card={contract}
            onClickConfirm={setOpenConfirmModal}
            isCopyEnabled={isCopyModeEnabled}
            onChangeCardCheck={onChangeCardCheck}
            // onDirectlyCopyWeekly={copyWeekly}
            onEdit={onClickEditCard}
            onFillSlot={onClickFillSlot}
            onDelete={onDeleteCheckedContracts}
            companyId={+companyId}
            year={year}
            week={weekNumber}
            filters={filters}
          />
        </Fragment>
      );
    },
    [
      setOpenConfirmModal,
      isCopyModeEnabled,
      onChangeCardCheck,
      onClickEditCard,
      onClickFillSlot,
      onDeleteCheckedContracts,
      companyId,
      year,
      weekNumber,
      filters,
    ],
  );

  const onPasteHereClick = (newDate: Date) => {
    onPaste(newDate);
  };

  const isPasteHereCardDisplayed = (columnDate: Date) => {
    const currentDateTime = new Date().getTime();
    const dateOfContractToCopyTime = new Date(contractDateToCopy.date).getTime();
    const columnDateTime = columnDate.getTime();
    return columnDateTime > currentDateTime && columnDateTime !== dateOfContractToCopyTime;
  };

  const displayPasteHereCardOnColumn = (contractDate: Date) => {
    if (isPasteHereCardDisplayed(contractDate)) {
      return (
        <PasteHereCard
          date={contractDate}
          onClickPaste={newContractDate => {
            onPasteHereClick(newContractDate);
          }}
        />
      );
    }
    return null;
  };

  const getContractsColumn = (contractDate: Date) => {
    const contractsToDisplayFiltered = contracts.filter(
      (card: IContractData) =>
        new Date(card.date).getDate() === contractDate.getDate() &&
        new Date(card.date).getMonth() === contractDate.getMonth() &&
        new Date(card.date).getFullYear() === contractDate.getFullYear(),
    );
    let firstActiveContractId = 0;
    const currentDate = new Date().getDate();
    const currentHour = new Date().getHours();

    const contractsToDisplay = sortedContracts(contractsToDisplayFiltered);

    if (contractDate.getDate() === currentDate) {
      const firstActiveContract = contracts.find((card: IContractData) => {
        const contractStartHour = toNumber(card.startHour.split(':')[0]);
        if (contractStartHour > currentHour) {
          return true;
        }
        return false;
      });

      if (firstActiveContract) {
        firstActiveContractId = firstActiveContract.id;
      }
    }

    return (
      <GridColumn>
        {isCopyModeEnabled && contractDateToCopy ? (
          <>
            {contractsToDisplay.map((c: IContractData) => getContract(firstActiveContractId, c))}
            {displayPasteHereCardOnColumn(contractDate)}
          </>
        ) : (
          contractsToDisplay.map((c: IContractData) => getContract(firstActiveContractId, c))
        )}
      </GridColumn>
    );
  };

  const generateColumns = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {days.map(d => (
          <Fragment key={Math.random()}>{getContractsColumn(d.date)}</Fragment>
        ))}
      </Box>
    );
  };

  useEffect(() => {
    setDisplayFetchButton(
      toNumber(companyId) > 0 && contractPageDetails?.numberOfResults >= 35 && contracts.length > 0,
    );
  }, [companyId, contractPageDetails, contracts]);

  const getCurrentContractsNumber = () => {
    if (contractPageDetails) {
      return Math.min(
        contractPageDetails.pageIndex * contractPageDetails.pageSize,
        contractPageDetails.numberOfResults,
      );
    }
    return contracts.length;
  };

  return (
    <GridContainer data-gtm-page="contracts-calendar-view">
      <GridHeader onCheckDay={onCheckDay} days={days} onChangeWeek={onChangeWeek} />
      {generateColumns()}
      {displayFetchButton && (
        <AvailabilitiesAlertStyled severity="success" icon={false}>
          <Typography mr={theme.spacing(2)} fontWeight="bold">
            {`${getCurrentContractsNumber()} / ${contractPageDetails.numberOfResults}`}
          </Typography>

          <UPButton text={t(CONTRACTS__FETCH_MORE)} onClick={() => getMoreContracts()} />
        </AvailabilitiesAlertStyled>
      )}
    </GridContainer>
  );
};

export default ContractsGridView;
