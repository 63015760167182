import { IContractDate } from 'modules/contracts/types';
import { IDateForPaste } from 'types/contract';
import IHours from 'types/contract/IHours';
import IWeekDays from 'types/contract/IWeekDays';
import IWeekDaysRow from 'types/contract/IWeekDaysRow';
import { getWeekDaysAbbreviations } from 'utils/helpers';

export const generateDateRows = (dates: IWeekDays[]): IWeekDaysRow[] => {
  const initialHours: IHours = {
    startWork: '',
    startBreak: '',
    endBreak: '',
    endWork: '',
  };

  const dateRows = [
    {
      id: 0,
      days: dates,
      hours: initialHours,
    },
  ];

  return dateRows;
};

export const generateDateRowsFromDatesForPaste = (
  datesForPaste: IDateForPaste[],
  weekDays: IWeekDays[],
): IWeekDaysRow[] => {
  const dateRows = datesForPaste.map((dateForPaste, index) => {
    const hours: IHours = {
      startWork: dateForPaste.startHour,
      startBreak: dateForPaste.startBreak,
      endBreak: dateForPaste.endBreak,
      endWork: dateForPaste.endHour,
    };

    return {
      id: index,
      days: weekDays.map(day => ({
        ...day,
        checked: dateForPaste.days.includes(day.day),
      })),
      hours,
    };
  });

  return dateRows;
};

const sortDateRecords = (dateRecords: Record<string, IContractDate[]>) => {
  Object.keys(dateRecords).forEach(key => {
    dateRecords[key].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  });
};

export const generateDatesForPasting = (dates: IContractDate[]): IDateForPaste[] => {
  const groupedDates: Record<string, IContractDate[]> = dates.reduce((acc, date) => {
    const key = `${date.startHour}-${date.endHour}-${date.startBreak}-${date.endBreak}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(date);
    return acc;
  }, {});

  sortDateRecords(groupedDates);

  const weekDaysAbbreviations = getWeekDaysAbbreviations();

  const dateRows: IDateForPaste[] = Object.keys(groupedDates).map(key => {
    const [startHour, endHour, startBreak, endBreak] = key.split('-');
    const days: string[] = groupedDates[key].reduce((acc, contractDate) => {
      const dateDay = new Date(contractDate.date).getDay();
      const dateAbbreviationIndex = dateDay === 0 ? 6 : dateDay - 1;
      acc.push(weekDaysAbbreviations[dateAbbreviationIndex]);
      return acc;
    }, []);
    return {
      startHour,
      endHour,
      startBreak,
      endBreak,
      days,
    };
  });

  return dateRows;
};

export const addTimespans = (time1: string, time2: string): string => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  let totalMinutes = minutes1 + minutes2;
  let totalHours = hours1 + hours2;

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  totalHours %= 24;

  const formattedHours = String(totalHours).padStart(2, '0');
  const formattedMinutes = String(totalMinutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};
