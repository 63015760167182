import { Checkbox, Box } from '@mui/material';
import { FormControlLabelStyled } from './styles';
import { UPCheckboxProps } from './types';

const UPCheckbox = (props: UPCheckboxProps): JSX.Element => {
  const { label, value, handleChange, disabled, gtmEvent } = props;

  return (
    <Box>
      <FormControlLabelStyled
        label={label}
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            disabled={disabled}
            sx={{
              '&.Mui-disabled': {
                color: '#9EA2A2', // Override color for disabled checkbox square
              },
              '& .MuiSvgIcon-root': {
                fill: disabled ? '#9EA2A2' : '#008C15', // Specific color for the checkbox square itself
              },
            }}
            data-gtm-event={gtmEvent}
          />
        }
      />
    </Box>
  );
};

export default UPCheckbox;
