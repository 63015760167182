import { UPDropdown, UPDropdownItem, UPMultipleSelectionDropdown } from 'components/dropdown';
import UPMultipleSelectAutocomplete from 'components/dropdown-with-search/UPMultipleSelectAutocomplete';
import { CompanyRole } from 'constants/CompanyRole';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  COMPANY__COMPANY,
  COMPANY_PAYROLL__COST_CENTERS,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  MENU__EMPLOYEES,
} from 'translations/constants';
import { FilterItem, FiltersContainer } from '../styles';
import { BaseReportingFiltersProps } from '../types';

const FTECheckFilters = (props: BaseReportingFiltersProps) => {
  const { filters, dropdownData, handleFilterChange } = props;

  const [t] = useTranslation();
  const isAdmin = useAppSelector(profileSelector).role === CompanyRole.ADMIN;

  const { companiesDropdownItems, workerClassesDropdownItems, costCentersDropdownItems, employeesDropdownItems } =
    dropdownData;

  const { selectedCompany, selectedWorkerClasses, selectedCostCenters } = filters;

  return (
    <FiltersContainer>
      {isAdmin && (
        <FilterItem>
          <UPDropdown
            items={companiesDropdownItems}
            value={selectedCompany}
            label={t(COMPANY__COMPANY)}
            onChange={event => handleFilterChange('selectedCompany', event.target.value)}
          />
        </FilterItem>
      )}
      <FilterItem>
        <UPMultipleSelectionDropdown
          items={workerClassesDropdownItems}
          value={selectedWorkerClasses}
          dropdownLabel={t(GENERAL__WORKERCLASS)}
          handleChange={event => handleFilterChange('selectedWorkerClasses', event.target.value)}
        />
      </FilterItem>
      <FilterItem>
        <UPMultipleSelectionDropdown
          items={costCentersDropdownItems}
          value={selectedCostCenters}
          dropdownLabel={t(COMPANY_PAYROLL__COST_CENTERS)}
          handleChange={event => handleFilterChange('selectedCostCenters', event.target.value)}
        />
      </FilterItem>
      <FilterItem sx={{ width: '500px' }}>
        <UPMultipleSelectAutocomplete
          items={employeesDropdownItems}
          handleChange={(_, newValue: UPDropdownItem[]) => {
            handleFilterChange(
              'selectedEmployees',
              newValue.map(item => item.value),
            );
          }}
          label={t(MENU__EMPLOYEES)}
          defaultValue={[]}
          placeholder={t(GENERAL__SELECT)}
        />
      </FilterItem>
    </FiltersContainer>
  );
};

export default FTECheckFilters;
