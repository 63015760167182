/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { UPInputLabel } from 'components/input/styles';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import nlLocale from 'date-fns/locale/nl';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { toNumber } from 'lodash';

import { UPWeekPickerProps } from './types';
import { CustomPickersDay, CustomWeekDisplay, UPDatepickerContainerStyled, UPDatepickerHelperText } from './styles';

const UPWeekPicker = (props: UPWeekPickerProps): JSX.Element => {
  const userLanguage = useAppSelector(profileSelector).languageId;
  const { value, className, onChange, label, required, error, helperText, weekValue, minDate } = props;
  const handleCalendarLanguage = useMemo(() => {
    switch (userLanguage) {
      case '1':
        nlLocale.options.weekStartsOn = 1;
        return nlLocale;
      case '2':
        frLocale.options.weekStartsOn = 1;
        return frLocale;
      default:
        enLocale.options.weekStartsOn = 1;
        return enLocale;
    }
  }, [userLanguage]);

  const getSpecialDayInputFormat = (date: Date): string => {
    const specialDayStart = new Date('2024-12-30');
    const specialDayEnd = new Date('2025-01-01');

    if (date >= specialDayStart && date < specialDayEnd) {
      return `${weekValue} / 2025`;
    }

    return `${weekValue} / ${moment(date).format('Y')}`;
  };
  return (
    <UPDatepickerContainerStyled className={className} error={error}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <LocalizationProvider locale={handleCalendarLanguage} dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          onChange={onChange}
          renderInput={params => (
            <TextField
              {...params}
              onKeyDown={e => {
                e.preventDefault();
              }}
            />
          )}
          renderDay={(date: any, selectedDates, pickersDayProps) => {
            const isSunday = date.getDay() === 1;
            const weekNo = moment(date).format('W');
            const isSelectedWeek = toNumber(moment(date).format('W')) === weekValue;
            return (
              <div key={date.toDateString()}>
                {isSunday && <CustomWeekDisplay isSelected={isSelectedWeek}>W {weekNo}</CustomWeekDisplay>}
                <CustomPickersDay {...pickersDayProps} disableMargin />
              </div>
            );
          }}
          inputFormat={
            // TODO: Remove this condition when the week picker is fixed
            value.getFullYear() === 2024 && weekValue === 53 ? '1 / 2025' : getSpecialDayInputFormat(value)
          }
          showDaysOutsideCurrentMonth
          disableHighlightToday
          components={{
            OpenPickerIcon: ArrowDropDownIcon,
          }}
          mask="__ / ____"
          views={['day']}
          PaperProps={{ className: 'week-picker' }}
          minDate={minDate || undefined}
        />
      </LocalizationProvider>
      {error && <UPDatepickerHelperText>{helperText}</UPDatepickerHelperText>}
    </UPDatepickerContainerStyled>
  );
};

export default UPWeekPicker;
