import { styled, Box } from '@mui/material';

export const UPCardStyled = styled(Box)(() => ({
  width: '100%',
  height: '240px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  cursor: 'pointer',
  position: 'relative',
}));

export const MediaContent = styled(Box)(() => ({
  width: '80px',
  height: '80px',
}));

export const CardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: 'white',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  color: theme.palette.secondary.contrastText,
  gap: '10px',
  padding: '26px',
  boxSizing: `border-box`,
}));

export const CardTitle = styled(Box)(() => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
}));

export const CardSubtitle = styled(Box)(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  minHeight: '20px',
}));

export const CardDetails = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: theme.palette.secondary.dark,
  overflow: 'hidden',
  textAlign: 'center',
}));
