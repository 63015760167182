import { styled, Box } from '@mui/material';
import UPButton from 'components/button/UPButton';

export const SectionContainer = styled(Box)(() => ({
  background: 'white',
  padding: '32px 32px 16px 32px',
  marginTop: '10px',
  borderRadius: '8px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  maxHeight: 'auto',
}));

export const SubSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '32px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingLeft: '0px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '60%',
  },
}));

export const CompanyInformationLeftContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  paddingRight: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingRight: '0px',
  },
}));

export const CompanyInformationRightContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  paddingLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingLeft: '0px',
    paddingTop: '42px',
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [theme.breakpoints.up('lg')]: {
    width: '60%',
  },
}));

export const LeftSideButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '60%',
  [theme.breakpoints.down('lg')]: {
    width: '70%',
  },
}));

export const RightSideButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '40%',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    width: '30%',
  },
}));

export const InputBox = styled(Box)(() => ({
  marginBottom: '24px',
}));

export const CloseAlertIcon = styled(Box)(() => ({
  cursor: 'pointer',
}));

export const LogoErrorText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '12px',
}));

export const ToggleContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  alignContent: 'center',
  paddingRight: '10px',
  [theme.breakpoints.down(850)]: {
    width: '30%',
    flex: '0 1 100%',
  },
}));

export const CompanyAddNewItemButton = styled(UPButton)(() => ({
  margin: '0px',
}));
