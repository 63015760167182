import { Avatar } from '@mui/material';
import React, { Fragment } from 'react';
import theme from 'theme';
import { ReactComponent as EditEmployeeAvatar } from '../../../../../assets/icons/EditEmployeeAvatar.svg';
import { ReactComponent as VendorTruckIcon } from '../../../../../assets/icons/VendorTruckIcon.svg';
import { ReactComponent as WeeklyContractIcon } from '../../../../../assets/icons/WeeklyContractIcon.svg';
import { generateCardFunction } from '../helpers';
import {
  ContractCardDetailsContainer,
  ContractCardDetailsText,
  ContractCardFunction,
  ContractCardName,
} from './styles';
import { ContractCardDetailsProps } from '../types';

const ContractCardDetails: React.FC<ContractCardDetailsProps> = ({ card, isWeekly }) => {
  const isContractSlot = !!card.vendor;

  const getCardKey = () => {
    return isContractSlot ? card.vendor.id : card.employee.id;
  };

  const getCardAvatar = () => {
    if (isContractSlot) {
      return (
        <Avatar sx={{ width: '32px', height: '32px', backgroundColor: theme.palette.primary.main }}>
          <VendorTruckIcon />
        </Avatar>
      );
    }
    if (card.employee.profilePicture) {
      return <Avatar sx={{ width: '32px', height: '32px' }} src={card.employee.profilePicture} />;
    }
    return (
      <Avatar sx={{ width: '32px', height: '32px' }}>
        <EditEmployeeAvatar />
      </Avatar>
    );
  };

  const getCardName = () => {
    return isContractSlot ? card.vendor.name : `${card.employee.firstName} ${card.employee.lastName}`;
  };

  const getCardFunction = () => {
    return isContractSlot ? card.vendor.functionName : card.employee.functionName;
  };

  return (
    <Fragment key={getCardKey()}>
      <ContractCardDetailsContainer>
        {getCardAvatar()}
        <ContractCardDetailsText>
          <ContractCardName active={card.editable}>{getCardName()}</ContractCardName>
          <ContractCardFunction>{generateCardFunction(getCardFunction())}</ContractCardFunction>
        </ContractCardDetailsText>
        {isWeekly && <WeeklyContractIcon />}
      </ContractCardDetailsContainer>
    </Fragment>
  );
};

export default ContractCardDetails;
