import { RootState } from 'store';
import IConfirmContract from 'types/contract/IConfirmContract';

export const saveContractSuccessSelector = (state: RootState): boolean => state.contract.saveContractSuccess;
export const saveContractErrorSelector = (state: RootState): boolean => state.contract.saveContractError;

export const validateContractSuccessSelector = (state: RootState): boolean => state.contract.validateContractSuccess;
export const validateContractErrorMessageSelector = (state: RootState): string =>
  state.contract.validateContractErrorMessage;
export const validateContractExtraMessageSelector = (state: RootState): string =>
  state.contract.validateContractExtraMessage;

export const validateContractErrorMessageRowSelector = (state: RootState): number =>
  state.contract.validateContractErrorMessageRow;
export const wageSelector = (state: RootState): string => state.contract.wage;
export const contractSelector = (state: RootState): IConfirmContract => state.contract.contract;
export const getContractForDateErrorSelector = (state: RootState): boolean => state.contract.getContractForDateError;
