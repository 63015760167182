import { UPToggleProps } from './types';
import { ToggleContainer, ToggleOption, ToggleSeparator } from './styles';

const UPToggle = (props: UPToggleProps): JSX.Element => {
  const { optionOne, optionTwo, onChange, selectedOption } = props;
  return (
    <ToggleContainer>
      <ToggleOption onClick={() => onChange(1)} isSelected={selectedOption === 1}>
        {optionOne}
      </ToggleOption>
      <ToggleSeparator>|</ToggleSeparator>
      <ToggleOption onClick={() => onChange(2)} isSelected={selectedOption === 2}>
        {optionTwo}
      </ToggleOption>
    </ToggleContainer>
  );
};

export default UPToggle;
